const gray = [
  "#f0f0f0",
  "#f5f6fa",
  "#dcdcdc",
  "#bdbdbd",
  "#aaaaaa",
  "#35363A",
  "#323232", //google main
  "#484848",
  "#2d2e30", //google darker
  "#212121", //youtube menu
  "#1e1e1e",
  "#181818",
];

export default gray;
