import { actionTypes } from "../actions/action-types";

const initialState = {
  refunds: [],
  refundIds: {},
  isLoading: false,
  isLoaded: false
};

const favoritesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.FETCH_FAVORITES: {
      const { items = [] } = payload;
      const refundIds = {};
      items.forEach((element) => {
        refundIds[element.id] = true;
      });
      return {
        ...state,
        refunds: items,
        refundIds,
        isLoaded: true
      };
    }
    case actionTypes.FETCH_FAVORITES_IDS: {
      const { ids } = payload;
      return {
        ...state,
        ids
      };
    }
    case actionTypes.TOGGLE_FOVORITE: {
      const { item, isAdd } = payload;
      const { refunds, refundIds } = state;
      let newFavorites = [...refunds];
      if (isAdd) {
        newFavorites.unshift(item);
        refundIds[item.id] = true;
      } else {
        newFavorites = refunds.filter((element) => element.id !== item.id);
        refundIds[item.id] = false;
      }
      return {
        ...state,
        refunds: newFavorites,
        refundIds: refundIds
      };
    }
    case actionTypes.FAVORITES_IS_LOADING: {
      return {
        ...state,
        isLoading: payload
      };
    }
    case actionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default favoritesReducer;
