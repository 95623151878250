import React, { useCallback, useState, useMemo } from "react";
import { FlatList, RefreshControl } from "react-native";
import styled from "styled-components/native";
import { useDispatch } from "react-redux";
import BaseText from "../../components/basic/base-text";
import { DESKTOP_MAX_WIDTH } from "../../components/styles/constants";

const StyledCardsContainer = styled.View`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const defaultRenderItem = ({ item }) => <BaseText>{item.title}</BaseText>;

export default function MessagesGenericList({
  items = [],
  navigation,
  ListEmptyComponent,
  fetchItems,
  renderItem,
  ...rest
}) {
  const [refreshing, setRefreshing] = useState(false);
  const dispatch = useDispatch();

  const onRefresh = useCallback(async () => {
    if (fetchItems) {
      try {
        setRefreshing(true);
        dispatch(fetchItems())
          .then(() => {
            setRefreshing(false);
          })
          .catch(() => setRefreshing(false));
      } catch (error) {
        console.error(error);
      }
    }
  }, [refreshing]);

  // console.log("items list ", items);

  const displayItems = useMemo(() => {
    const res = items.filter((item) => item !== undefined && item !== null);
    return res;
  }, [items]);

  return (
    <StyledCardsContainer>
      <FlatList
        contentContainerStyle={{
          maxWidth: DESKTOP_MAX_WIDTH,
          width: "100%",
          marginRight: "auto",
          marginLeft: "auto"
        }}
        data={displayItems}
        renderItem={renderItem || defaultRenderItem}
        keyExtractor={(item) => {
          if (item) {
            if (item.id) {
              return item.id;
            } else {
              return item;
            }
          } else {
            return item;
          }
        }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        ListEmptyComponent={ListEmptyComponent}
      />
    </StyledCardsContainer>
  );
}
