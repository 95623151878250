import React from "react";
import BaseText from "../../../components/basic/base-text";
import styled, { useTheme } from "styled-components/native";
import { useTranslation } from "react-i18next";
import { MaterialIcons } from "@expo/vector-icons";

const StyledContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledText = styled(BaseText)`
  margin-left: 4px;
`;

export default function PhotoMessage() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledContainer>
      <MaterialIcons
        name="photo-camera"
        size={20}
        color={theme.palette.font.primary}
      />
      <StyledText>{t("Photo")}</StyledText>
    </StyledContainer>
  );
}
