import { actionTypes } from "../actions/action-types";
import { CHATS_API } from "../../constants/apis-urls";
import { fetchUsersById } from "../thunks/auth-thunk";
import { showError } from "../actions/core-actions";
import { restClient } from "./fetch-thunk";

export const fetchUserMessages = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      dispatch({
        type: actionTypes.SET_MESSAGES_LOADING,
        payload: true
      });

      console.log("fetching user chat list ", userId);
      const url = `${CHATS_API}/threads/${userId}`;
      const result = await dispatch(restClient.getAPI(url));

      console.log("fetching user chat list res ", result);

      dispatch({
        type: actionTypes.SET_MESSAGES,
        payload: result
      });
    } catch (e) {
      console.error("failed to fetching user chat list userId ", userId, e);
      dispatch(showError("failed to fetching user chat list " + e.message));
    } finally {
      dispatch({
        type: actionTypes.SET_MESSAGES_LOADING,
        payload: false
      });
    }
  };
};

export const fetchMessagesUsersData = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    const usersById = getState().auth?.users || {};
    const items = getState().messages.items || [];
    try {
      console.log("fetching messages users data ", items);

      if (!items || items.length === 0) {
        console.log("no items passed ", items);
        return;
      }

      const threadIds = [];
      const userIds = [];

      items.forEach((item) => {
        const id = item.fromUserId === userId ? item.toUserId : item.fromUserId;
        if (!usersById[id]) {
          userIds.push(id);
        }
        threadIds.push(item.threadId);
      });
      console.log("fetching messages users data userIds ", userIds);
      console.log("fetching messages users data threadIds ", threadIds);

      if (userIds.length > 0) {
        dispatch(fetchUsersById({ userIds }));
      }

      if (threadIds.length > 0) {
        dispatch(fetchLastMessages({ threadIds }));
        dispatch(fetchUnreadMessagesCount({ threadIds }));
      }

      dispatch({
        type: actionTypes.SET_MESSAGES_LOADED,
        payload: true
      });
    } catch (e) {
      console.error("failed to fetching user chat list userId ", userId, e);
      dispatch(showError(e.message));
    }
  };
};

export const fetchLastMessage = ({ threadId }) => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      console.log("fetching user last message threadId ", threadId);
      const url = `${CHATS_API}/last-message/${threadId}`;

      const result = await dispatch(restClient.getAPI(url));

      console.log("fetching user last message res ", result);

      dispatch({
        type: actionTypes.SET_LAST_MESSAGE,
        payload: result
      });
    } catch (e) {
      console.error("failed to fetching user last message threadId ", threadId, e);
      dispatch(showError(e.message));
    }
  };
};

export const fetchLastMessages = ({ threadIds }) => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      console.log("fetching user last messages threadIds ", threadIds);
      const url = `${CHATS_API}/last-messages`;

      const result = await dispatch(
        restClient.postAPI({
          url,
          body: {
            userId,
            threadIds
          }
        })
      );

      console.log("fetching user last messages res ", result);

      dispatch({
        type: actionTypes.SET_LAST_MESSAGES,
        payload: result
      });
    } catch (e) {
      console.error(
        "failed to fetching user last messages threadIds ",
        threadIds,
        e
      );
      dispatch(showError(e.message));
    }
  };
};

export const fetchUnreadMessagesCount = ({ threadIds }) => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      console.log("fetching unread messages count by threadIds ", threadIds);
      const url = `${CHATS_API}/unread-messages-by-thread`;

      const result = await dispatch(
        restClient.postAPI({
          url,
          body: {
            userId,
            threadIds
          }
        })
      );

      console.log("fetching unread messages count res ", result);

      dispatch({
        type: actionTypes.SET_THREAD_ID_TO_UNREAD_COUNT,
        payload: result
      });
    } catch (e) {
      console.error(
        "failed to fetching unread messages count threadIds ",
        threadIds,
        e
      );
      dispatch(showError(e.message));
    }
  };
};

export const handleUnreadMessages = ({ threadId }) => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    const threadIdToUnreadCount = getState().messages.threadIdToUnreadCount || {};
    const messagesUnreadCount = getState().messages.unreadCount;
    try {
      const unreadCount = threadIdToUnreadCount[threadId] || 0;
      console.log(
        "handleUnreadMessages threadId ",
        threadId,
        " unreadCount ",
        unreadCount
      );
      if (unreadCount === 0) {
        return;
      }
      const url = `${CHATS_API}/read-messages`;

      const result = await dispatch(
        restClient.postAPI({
          url,
          body: {
            userId,
            threadId
          }
        })
      );

      console.log("update read messages res ", result);

      dispatch({
        type: actionTypes.SET_MESSAGES_UNREAD_COUNT_BY_THREAD_ID,
        payload: { threadId, count: 0 }
      });
      dispatch({
        type: actionTypes.SET_MESSAGES_UNREAD_COUNT,
        payload: messagesUnreadCount - unreadCount
      });
    } catch (e) {
      console.error("failed to update read messages threadId ", threadId, e);
      dispatch(showError(e.message));
    }
  };
};

export const updateUnreadMessagesToRead = ({ threadId, ids }) => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      console.log("update unread messages to read by ids ", threadId);

      const url = `${CHATS_API}/read-messages-by-ids`;
      const result = await dispatch(
        restClient.postAPI({
          url,
          body: {
            userId,
            threadId,
            ids
          }
        })
      );
      console.log("update read messages res by ids", result);
    } catch (e) {
      console.error(
        "failed to update read messages threadId by ids ",
        threadId,
        " ids ",
        ids,
        e
      );
      dispatch(showError(e.message));
    }
  };
};

export const fetchMessagesUnreadCount = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      console.log("fetching messages unread count ", userId);
      const url = `${CHATS_API}/${userId}/unread-count`;
      const messagesUnreadCount = getState().messages.unreadCount;

      const result = await dispatch(restClient.getAPI(url));

      console.log("fetching messages unread count res ", result);

      dispatch({
        type: actionTypes.SET_MESSAGES_UNREAD_COUNT,
        payload: result
      });
      if (messagesUnreadCount !== result) {
        dispatch(fetchMessagesUsersData());
      }
    } catch (e) {
      console.error("failed to fetching messages unread count userId ", userId, e);
      dispatch(showError(e.message));
    }
  };
};
