import React from "react";
import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useTheme } from "styled-components/native";
import ReduxLoader from "../components/loaders/redux-loader";
import HomeStackNavigator from "./home-stack-navigator";
import BottomBar from "./bottom-bar";
import FavoritesStackNavigator from "./favorites-stack-navigator";
import AccountStackNavigator from "./account-stack-navigator";
import MessagesStackNavigator from "./messages-stack-navigator";
import SearchStackNavigator from "./search-stack-navigator";
import ActivityStackNavigator from "./activity-stack-navigator";
import AppNotifications from "../components/app-notifications";

const Tab = createBottomTabNavigator();

const config = {
  screens: {
    Home: {
      screens: {
        RefundDetails: {
          screens: {
            RefundDetails: "refund/:id",
            ChatScreen: "refund/chat/:id"
          }
        },
        ResetPassword: {
          path: "/reset-password/:code"
        },
        AddEditRefund: "add-edit"
      }
    },
    Messages: {
      screens: {
        Messages: "messages",
        ChatScreen: "messages/chat/:id",
        RefundDetails: {
          screens: {
            RefundDetails: "messages/refund/:id"
          }
        },
        RequestScreen: {
          screens: {
            RequestScreen: "messages/requests/:id",
            RefundDetails: {
              screens: {
                RefundDetails: "messages/requests/refund/:id",
                ChatScreen: "messages/requests/details/chat/:id"
              }
            },
            ChatScreen: "messages/requests/chat/:id"
          }
        }
      }
    },
    Search: {
      screens: {
        Search: "search"
      }
    },
    Favorites: {
      screens: {
        Favorites: "favorites",
        RefundDetails: {
          screens: {
            RefundDetails: "favorites/refund/:id",
            ChatScreen: "favorites/chat/:id"
          }
        }
      }
    },
    Activity: {
      screens: {
        Activity: "activity",
        RefundDetails: {
          screens: {
            RefundDetails: "activity/refund/:id",
            ChatScreen: "activity/chat/:id"
          }
        },
        AddEditRefund: "activity/add-edit"
      }
    },
    Account: {
      screens: {
        Account: "account",
        ContactUs: "contact-us",
        EditUser: "edit-user"
      }
    }
  }
};

const linking = {
  config
};

export default function AppNavigator() {
  const theme = useTheme();

  const navTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: theme.palette.background.primary
    }
  };
  return (
    <NavigationContainer linking={linking} theme={navTheme}>
      <Tab.Navigator
        tabBarOptions={{
          showLabel: false,
          style: {
            backgroundColor: "transparent"
          }
        }}
        tabBar={(props) => <BottomBar {...props} />}
      >
        <Tab.Screen name="Home" component={HomeStackNavigator} />
        <Tab.Screen
          name="Search"
          component={SearchStackNavigator}
          options={{ unmountOnBlur: true }}
          listeners={({ navigation }) => ({
            blur: () => navigation.setParams({ screen: undefined })
          })}
        />
        <Tab.Screen
          name="Messages"
          component={MessagesStackNavigator}
          options={{ unmountOnBlur: true }}
          listeners={({ navigation }) => ({
            blur: () => navigation.setParams({ screen: undefined })
          })}
        />
        <Tab.Screen
          name="Activity"
          component={ActivityStackNavigator}
          options={{ unmountOnBlur: true }}
        />
        <Tab.Screen name="Favorites" component={FavoritesStackNavigator} />
        <Tab.Screen
          name="Account"
          component={AccountStackNavigator}
          options={{ unmountOnBlur: true }}
        />
      </Tab.Navigator>
      <ReduxLoader />
      <AppNotifications />
    </NavigationContainer>
  );
}
