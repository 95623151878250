import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import RefundFavorites from "../screens/favorites-screen/favorites-screen";
import { useTranslation } from "react-i18next";
import RefundDetailsStackNavigator from "./refund-details-stack-navigator";

const Stack = createStackNavigator();

export default function FavoritesStackNavigator() {
  const { t } = useTranslation();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Favorites"
        component={RefundFavorites}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="RefundDetails"
        component={RefundDetailsStackNavigator}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
}
