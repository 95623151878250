import React from "react";
import styled from "styled-components/native";
import Error from "./error";

const StyledErrors = styled.View`
  margin-bottom: 16px;
`;

export default function Errors({ errors }) {
  if (!errors || errors.length === 0) {
    return null;
  }
  return (
    <StyledErrors>
      {errors.map((error, index) => (
        <Error key={index}>{error}</Error>
      ))}
    </StyledErrors>
  );
}
