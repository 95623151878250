import React from "react";
import Button from "./button";
import { useTheme } from "styled-components/native";

export default function OutlineButton({
  title,
  onPress,
  disabled,
  children,
  buttonStyle = {},
  titleStyle = {},
  height,
  fontSize,
  checked,
  iconSize,
  className,
  ...rest
}) {
  const theme = useTheme();

  return (
    <Button
      type="outline"
      title={title}
      onPress={onPress}
      disabled={disabled}
      buttonStyle={{
        borderColor: theme.palette.button.outlined.border,
        backgroundColor: theme.palette.button.outlined.color,
        borderWidth: 1,
        height,
        ...buttonStyle
      }}
      titleStyle={{
        color: theme.palette.button.outlined.font,
        fontSize,
        ...titleStyle
      }}
      iconSize={iconSize}
      checked={checked}
      className={className}
      {...rest}
    >
      {children}
    </Button>
  );
}
