import React, { useMemo } from "react";
import styled from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";
import { toggleFavorite } from "../../redux/thunks/favorites-thunk";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import useUser from "../../hooks/use-user";

const StyledFavorites = styled(MaterialIcons)`
  color: ${(props) => props.theme.palette.font.primary};
`;

export default function FavoriteButton({ item, ...rest }) {
  const dispatch = useDispatch();
  const isFavorite = useSelector((state) => state.favorites.refundIds[item.id]);
  const navigation = useNavigation();
  const [isLoggedIn] = useUser();

  const onPressFavorite = () => {
    if (isLoggedIn) {
      dispatch(toggleFavorite({ item, isAdd: !isFavorite }));
    } else {
      navigation.navigate("Login");
    }
  };

  return (
    <StyledFavorites
      name={isFavorite ? "favorite" : "favorite-outline"}
      size={20}
      onPress={onPressFavorite}
      {...rest}
    />
  );
}
