import React, { useEffect, useMemo } from "react";
import styled from "styled-components/native";
import { StyledLayout } from "../../../components/styles/screen-layout";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loaders/loader";
import MessagesGenericList from "../messages-generic-list";
import {
  fetchMessagesUsersData,
  fetchUserMessages
} from "../../../redux/thunks/messages-thunk";
import MessageCard from "./message-card";
import NoItemsFound from "../../../components/no-items-found";
import { MessagesTab } from "../../../redux/reducers/messages-reducer";

const StyledView = styled(StyledLayout)`
  padding: 16px 16px 0 16px;
`;

function sortByLastMessageDate(a, b) {
  return new Date(b.createdAt) - new Date(a.createdAt);
}

export default function Messages(props) {
  const { t } = useTranslation();
  const threads = useSelector((state) => state.messages.items) || [];
  const isLoading = useSelector((state) => state.messages.isLoading);
  const isLoaded = useSelector((state) => state.messages.isLoaded);
  const lastMessageByThreadId = useSelector(
    (state) => state.messages.lastMessageByThreadId
  );
  const activeTab = useSelector((state) => state.messages.activeTab) || 0;
  const isLastMessagesLoaded = useSelector(
    (state) => state.messages.isLastMessagesLoaded
  );
  console.log("Messages isLoading ", isLoading);
  console.log("Messages threads ", threads);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      console.log("fetchUserMessages");
      dispatch(fetchUserMessages());
    }
  }, [isLoaded, isLoading]);

  useEffect(() => {
    if (isLoaded && threads?.length > 0) {
      console.log("fetchMessagesUsersData");
      dispatch(fetchMessagesUsersData({ threads }));
    }
  }, [threads]);

  const displayItems = useMemo(() => {
    let messageThreads = [];
    if (!isLastMessagesLoaded) {
      return messageThreads;
    }
    messageThreads = Object.values(lastMessageByThreadId);
    const sorted = messageThreads.sort(sortByLastMessageDate);
    return sorted;
  }, [lastMessageByThreadId, isLastMessagesLoaded]);

  console.log("Messages displayItems ", displayItems);

  if (activeTab !== MessagesTab.Messages) {
    return null;
  }

  if (isLoading || (threads?.length > 0 && !isLastMessagesLoaded)) {
    return <Loader />;
  }

  if (displayItems.length === 0) {
    return <NoItemsFound text={t("You have no messages")} />;
  }

  const renderItem = ({ item, index }) => (
    <MessageCard
      key={item.id}
      item={item}
      isLast={index === displayItems.length - 1}
      {...props}
    />
  );

  return (
    <StyledView>
      <MessagesGenericList items={displayItems} renderItem={renderItem} />
    </StyledView>
  );
}
