import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "styled-components/native";
import { FAB } from "react-native-elements";
import { Question } from "../../../components/basic/icons/question";
import { toggleInfoModal } from "../../../redux/actions/core-actions";
import useUser from "../../../hooks/use-user";
import { isWeb } from "../../../utils/core-utils";

const IS_INFO_BUTTON = false;

export const InfoButton = () => {
  const open = useSelector((state) => state.core?.modals?.info?.open);
  const [isLoggedIn] = useUser();

  const theme = useTheme();
  const dispatch = useDispatch();

  const toggleModal = () => {
    dispatch(toggleInfoModal({ open: !open }));
  };

  return (
    <>
      {!isLoggedIn || IS_INFO_BUTTON ? (
        <FAB
          icon={
            <Question
              color={theme.palette.button.info.font}
              size={26}
              style={{ fontWeight: 600 }}
            />
          }
          color={theme.palette.button.info.color}
          onPress={toggleModal}
          iconPosition={"right"}
          placement={"right"}
          style={{ bottom: isWeb() ? 70 : 80 }}
        />
      ) : null}
    </>
  );
};
