import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Menu } from "react-native-paper";
import MenuButton from "../../../components/basic/menu/menu-button";
import { deleteRefund } from "../../../redux/thunks/refunds-thunk";
import { AntDesign, Feather } from "@expo/vector-icons";
import { useTheme } from "styled-components/native";
import { useDispatch } from "react-redux";
import { useNavigation } from "@react-navigation/native";

export default function ActivityItemMenu({ refundId, className }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigation = useNavigation();

  const [visible, setVisible] = useState(false);
  const hideMenu = () => setVisible(false);
  const showMenu = () => setVisible(true);
  const dispatch = useDispatch();

  const onClickDelete = () => {
    dispatch(
      deleteRefund({
        id: refundId
      })
    );
    hideMenu();
  };

  const onClickEdit = () => {
    navigation.navigate("AddEditRefund", { id: refundId });
    hideMenu();
  };

  // const onClickBoost = () => {
  //   navigation.navigate("BoostRefund", { id: refundId });
  //   hideMenu();
  // };

  return (
    <Menu
      visible={visible}
      onDismiss={hideMenu}
      anchor={<MenuButton onPress={showMenu} className={className} />}
      contentStyle={{ backgroundColor: theme.palette.menu.backgroundColor }}
    >
      <Menu.Item
        onPress={onClickEdit}
        icon={(props) => (
          <Feather name="edit" size={20} color={theme.palette.menu.color} />
        )}
        title={t("Edit")}
        titleStyle={{ color: theme.palette.menu.color }}
      />
      <Divider style={{ backgroundColor: theme.palette.separator }} />
      {/*<Menu.Item onPress={onClickShare} icon="share" title={t("Share")} />*/}
      {/*<Divider />*/}
      {/*<Menu.Item onPress={() => {}} icon="pause" title={t("Pending")} />*/}
      {/*<Divider />*/}
      <Menu.Item
        onPress={onClickDelete}
        icon={(props) => (
          <AntDesign name="delete" size={20} color={theme.palette.menu.color} />
        )}
        title={t("Delete")}
        titleStyle={{ color: theme.palette.menu.color }}
      />

      {/*<Divider style={{ backgroundColor: theme.palette.separator }} />*/}
      {/*<Menu.Item*/}
      {/*  onPress={onClickBoost}*/}
      {/*  icon={(props) => (*/}
      {/*    <AntDesign name="delete" size={20} color={theme.palette.menu.color} />*/}
      {/*  )}*/}
      {/*  title={t("Boost")}*/}
      {/*  titleStyle={{ color: theme.palette.menu.color }}*/}
      {/*/>*/}
    </Menu>
  );
}
