import React from "react";
import styled from "styled-components/native";
import ClearButton from "./clear-button";

const Container = styled.View`
  position: relative;
  border-radius: 8px;
`;

const StyledClearButton = styled(ClearButton)`
  position: absolute;
  right: 0;
  top: 0;
  margin: 4px;
  z-index: 1;
`;

export const generalItemStyle = {
  minWidth: 90,
  minHeight: 90,
  width: 90,
  height: 90,
  borderRadius: 8,
};

export default function GeneralItem({ children, isClearButton, onDelete, ...rest }) {
  return (
    <Container style={generalItemStyle} {...rest}>
      {isClearButton && (
        <StyledClearButton onPress={onDelete} round iconSize={12} size={24} />
      )}
      {children}
    </Container>
  );
}
