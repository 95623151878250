import React from "react";
import DatePicker from "react-native-modern-datepicker";
import useTheme from "../../styles/use-theme";

export const ModernDatePicker = ({ onChange, value, ...rest }) => {
  const theme = useTheme();

  return (
    <DatePicker
      options={{
        backgroundColor: theme.palette.background.secondary,
        textHeaderColor: theme.palette.font.secondary,
        textDefaultColor: theme.palette.font.secondary,
        selectedTextColor: theme.palette.calendar.selectedText,
        mainColor: theme.palette.button.primary.color,
        textSecondaryColor: theme.palette.font.secondary,
        borderColor: theme.palette.separator
      }}
      selected={value}
      mode="calendar"
      minuteInterval={30}
      onDateChange={onChange}
      {...rest}
    />
  );
};
