import React from "react";
import { TouchableOpacity } from "react-native";
import styled from "styled-components/native";
import { useTranslation } from "react-i18next";
import Title from "../../../components/basic/title";
import RequestSentIcon from "../../../components/refund/request-sent-icon";
import RefundType from "../../../components/refund/refund-type";
import BaseText from "../../../components/basic/base-text";
import useCardPriceTitle from "../../../hooks/use-card-price-title";
import ItemImage from "../../../components/refund/item-image";
import useRefundStatus from "../../../hooks/use-refund-status";
import {
  flexStartEnd,
  marginRightLeft
} from "../../../components/styles/global-styles";

const StyledView = styled.View`
  background-color: ${(props) => props.theme.palette.background.primary};
  color: ${(props) => props.theme.palette.font.primary};
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
`;

const StyledTitle = styled(Title)`
  font-weight: 600;
  font-size: 14px;
  ${marginRightLeft("auto")}
`;

const StyledContent = styled.View`
  margin-top: 0;
  width: 100%;
  flex-shrink: 1;
`;

const StyledType = styled.View`
  margin-bottom: 8px;
`;

const StyledTitleContainer = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${flexStartEnd}
  ${marginRightLeft("auto")}
`;

const StyledPrice = styled(BaseText)`
  font-size: 14px;
  color: ${(props) => props.theme.palette.price.red};
  ${marginRightLeft(8)}
`;

const StyledRefundAmount = styled(StyledPrice)`
  color: ${(props) => props.theme.palette.price.green};
  ${marginRightLeft(8)}
`;

const StyledLabel = styled(BaseText)`
  ${marginRightLeft(8)}
`;

const StyledItemImage = styled(ItemImage)`
  min-width: 100px;
  margin-right: 16px;
  width: auto;
`;

export default function BuyingItem({ item, navigation }) {
  const { id, title, images = [], type, otherType, status, expirationDate } = item;
  const { t } = useTranslation();
  const [formattedPrice, formattedRefundAmount] = useCardPriceTitle({ item });
  const calculatedStatus = useRefundStatus({ status, expirationDate });

  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate("RefundDetails", {
          id
        })
      }
    >
      <StyledView>
        <StyledItemImage images={images} status={calculatedStatus} />
        <StyledContent>
          <StyledTitle oneLine>{title}</StyledTitle>
          <StyledTitleContainer>
            <StyledPrice>{formattedRefundAmount}</StyledPrice>
            <StyledLabel>{t("selling for")}</StyledLabel>
            <StyledRefundAmount>{formattedPrice}</StyledRefundAmount>
          </StyledTitleContainer>
          <StyledType>
            <RefundType type={type} otherType={otherType} />
            <RequestSentIcon refundId={id} />
          </StyledType>
          {/* <StyledFooter>
            <StyledSoldButton title={t("Mark as Sold")} onPress={onClickSold} />
          </StyledFooter> */}
        </StyledContent>
      </StyledView>
    </TouchableOpacity>
  );
}
