import * as React from "react";
import styled from "styled-components/native";
import RefundsSearch from "./refunds-search";
import Header from "../../components/header";
import { isIos, isWeb } from "../../utils/core-utils";

const StyledHeader = styled(Header)`
  padding-right: 0;
  padding-left: 0;
  margin-top: ${isWeb() ? 16 : isIos() ? 40 : 40}px;
`;

function HomeHeader({ navigation, ...rest }) {
  return (
    <StyledHeader {...rest}>
      <RefundsSearch onPress={() => navigation.navigate("Search")} />
    </StyledHeader>
  );
}

export default HomeHeader;
