import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { useTheme } from "styled-components/native";
import { getHeaderStyle } from "./nav-style";
import ActivityScreen from "../screens/activity/activity-screen";
import MyRefundsStackNavigator from "./my-refund-requests-stack-navigator";
import { useTranslation } from "react-i18next";
import RefundDetailsStackNavigator from "./refund-details-stack-navigator";
import AddEditRefund from "../screens/add-refund/add-edit-refund";
import { headerOptions } from "./header-options";

const Stack = createStackNavigator();

export default function ActivityStackNavigator() {
  const theme = useTheme();
  const headerStyle = getHeaderStyle(theme);
  const { t } = useTranslation();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Activity"
        component={ActivityScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="MyRefunds"
        component={MyRefundsStackNavigator}
        options={{ headerShown: false, ...headerStyle }}
      />
      <Stack.Screen
        name="RefundDetails"
        component={RefundDetailsStackNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="AddEditRefund"
        component={AddEditRefund}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
}
