import React from "react";
import styled, { useTheme } from "styled-components/native";
import { Badge } from "react-native-elements";
import BaseText from "./base-text";
import IconByDesign from "./icon-by-design";

const StyledIcon = styled(IconByDesign)`
  color: ${(props) =>
    props.active
      ? props.theme.palette.icons.active
      : props.theme.palette.icons.primary};
`;

const StyledContainer = styled.View`
  position: relative;
`;

const StyledText = styled(BaseText)`
  color: ${(props) =>
    props.active
      ? props.theme.palette.icons.active
      : props.theme.palette.icons.primary};
`;

export default function BadgedLabelIcon({
  iconName,
  label,
  active,
  badge,
  designType,
  className,
  styles = {},
  ...rest
}) {
  const theme = useTheme();
  if (!iconName && !label) {
    return null;
  }
  return (
    <StyledContainer className={className} style={styles}>
      {iconName ? (
        <StyledIcon
          name={iconName}
          size={24}
          active={active}
          designType={designType}
          {...rest}
        />
      ) : (
        <StyledText active={active}>{label}</StyledText>
      )}
      {badge > 0 && (
        <Badge
          value={badge}
          status="success"
          containerStyle={{
            position: "absolute",
            top: -4,
            right: -8
          }}
          textStyle={{ fontSize: 8 }}
          badgeStyle={{
            width: 15,
            height: 15,
            minWidth: 15,
            backgroundColor: theme.palette.icons.active
          }}
        />
      )}
    </StyledContainer>
  );
}
