import React from "react";
import { useTheme } from "styled-components/native";
import { CheckIcon, Select as NativeSelect } from "native-base";

export const SelectItem = NativeSelect.Item;

export const NbDropdown = ({
  items,
  onValueChange,
  value = "",
  placeholder,
  maxWidth,
  textInputProps,
  variant = "outline",
  children,
  name,
  minWidth = 100,
  accessibilityLabel,
  getProps = () => {},
  getValue = () => {},
  ...rest
}) => {
  const theme = useTheme();
  return (
    <NativeSelect
      name={name}
      selectedValue={value}
      accessibilityLabel={accessibilityLabel || placeholder}
      placeholder={placeholder}
      onValueChange={onValueChange}
      variant={variant}
      color={theme.palette.font.primary}
      style={{
        paddingLeft: 8,
        paddingRight: 0,
        fontSize: 14,
        flexShrink: 1,
        backgroundColor: theme.palette.input.background
      }}
      textStyle={{ fontSize: 14 }}
      minWidth={minWidth}
      _selectedItem={{
        endIcon: <CheckIcon size={4} />
      }}
      {...rest}
    >
      {items
        ? items.map((item) => {
            return <SelectItem key={item.value} {...item} />;
          })
        : children}
    </NativeSelect>
  );
};
