import { actionTypes } from "../actions/action-types";
import { CHATS_API } from "../../constants/apis-urls";
import { showError } from "../actions/core-actions";
import { getErrorMessage } from "../../utils/errors-utils";
import { restClient } from "./fetch-thunk";
import { useCallback } from "react";
import { fetchLastMessages } from "./messages-thunk";
import { fetchUsersById } from "./auth-thunk";
import { useSelector } from "react-redux";

export const fetchChatMessagesAPI = ({ userId, threadId }) => {
  return async (dispatch, getState) => {
    try {
      console.log("fetching user messages ", threadId);
      const url = `${CHATS_API}/messages/${threadId}`;
      const result = await dispatch(restClient.getAPI(url));
      console.log("fetching user messages res ", result);
      return result;
    } catch (e) {
      console.error("failed to fetching user messages userId ", userId, e);
      throw e;
    }
  };
};

export const fetchChatMessages = ({ threadId }) => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      dispatch({
        type: actionTypes.SET_CHAT_MESSAGES_LOADING,
        payload: true
      });
      console.log("fetching user messages ", threadId);

      const url = `${CHATS_API}/messages/${threadId}`;
      const result = await dispatch(restClient.getAPI(url));
      console.log("fetching user messages res ", result);
      dispatch({
        type: actionTypes.SET_CHAT_MESSAGES,
        payload: { threadId, messages: result }
      });
    } catch (e) {
      console.error("failed to fetching user messages userId ", userId, e);
      throw e;
    } finally {
      dispatch({
        type: actionTypes.SET_CHAT_MESSAGES_LOADING,
        payload: false
      });
    }
  };
};

export const fetchThreadId = ({ threadId }) => {
  return async (dispatch, getState) => {
    try {
      console.log("fetchThreadId threadId ", threadId);
      let url = `${CHATS_API}/thread-id/${threadId}`;
      const result = await dispatch(restClient.getAPI(url));
      console.log("fetchThreadId res ", result);
      dispatch({
        type: actionTypes.SET_THREAD,
        payload: { thread: result || -1, threadId }
      });
      return result;
    } catch (e) {
      console.error("failed fetchThreadId threadId ", threadId, e);
      dispatch(showError("failed fetchThreadId " + e.message));
    }
  };
};

export const saveThreadId = ({ toUserId, threadId, refundId }) => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      console.log(
        "saveThreadId toUserId ",
        toUserId,
        " threadId ",
        threadId,
        " refundId ",
        refundId
      );
      const url = `${CHATS_API}/thread-id`;
      const result = await dispatch(
        restClient.postAPI({
          url,
          body: {
            toUserId,
            threadId,
            fromUserId: userId,
            refundId
          }
        })
      );

      console.log("saveThreadId res ", result);
      return result;
    } catch (e) {
      console.error(
        "failed saveThreadId toUserId ",
        toUserId,
        " threadId ",
        threadId,
        e
      );
      dispatch(showError("failed saveThreadId " + e.message));
    }
  };
};

/*
socketResponse:

createdAt: "2022-03-09T14:48:44.368Z"
fromUserId: "61ef1aeeb6d47ed6b0bd2b15"
id: "6228be4c02a3ce438890d631"
refundId: "62279a6f3c84781f814df5f5"
text: "qqqq"
threadId: "61ef1a92b6d47ed6b0bd2b11_61ef1aeeb6d47ed6b0bd2b15_62279a6f3c84781f814df5f5"
toUserId: "61ef1a92b6d47ed6b0bd2b11"
updatedAt: "2022-03-09T14:48:44.368Z"
*/
export const receiveSocketMessages = (socketResponse) => {
  return async (dispatch, getState) => {
    try {
      console.log("receiveSocketMessages socketResponse ", socketResponse);
      const currentChatId = getState().chats.currentChatId;
      const usersById = getState().auth?.users || {};
      if (socketResponse) {
        const { threadId, fromUserId } = socketResponse;
        if (threadId !== currentChatId) {
          console.log("receiveSocketMessages increase ", socketResponse);

          dispatch({
            type: actionTypes.INCREASE_MESSAGES_UNREAD_COUNT,
            payload: 1
          });
          dispatch({
            type: actionTypes.INCREASE_MESSAGES_UNREAD_COUNT_BY_THREAD_ID,
            payload: { threadId, num: 1 }
          });

          if (fromUserId && !usersById[fromUserId]) {
            dispatch(fetchUsersById({ userIds: [fromUserId] }));
          }
          dispatch({
            type: actionTypes.SET_LAST_MESSAGE,
            payload: socketResponse
          });
        }
      }
    } catch (e) {
      console.error(
        "failed receiveSocketMessages socketResponse ",
        socketResponse,
        e
      );
      dispatch(showError("failed receiveSocketMessages " + e.message));
    }
  };
};

export const addToThreadMessages = (socketResponse) => {
  return async (dispatch, getState) => {
    try {
      console.log("addToThreadMessages socketResponse ", socketResponse);
    } catch (e) {
      console.error(
        "failed to addToThreadMessages socketResponse ",
        socketResponse,
        e
      );
      dispatch(showError("failed addToThreadMessages " + e.message));
    }
  };
};
