import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { webLightboxStyles } from "./web-lightbox-styles";
import Lightbox from "react-image-lightbox";

export function WebZoomImage({ images, open, onClose }) {
  const [photoIndex, setPhotoIndex] = useState(0);

  const isMulti = images?.length > 1;
  return (
    <View>
      <style
        dangerouslySetInnerHTML={{
          __html: webLightboxStyles
        }}
      />
      {open && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={isMulti ? images[(photoIndex + 1) % images.length] : null}
          prevSrc={
            isMulti ? images[(photoIndex + images.length - 1) % images.length] : null
          }
          onCloseRequest={onClose}
          onMovePrevRequest={
            isMulti
              ? () => setPhotoIndex((photoIndex + images.length - 1) % images.length)
              : null
          }
          onMoveNextRequest={
            isMulti ? () => setPhotoIndex((photoIndex + 1) % images.length) : null
          }
        />
      )}
    </View>
  );
}
