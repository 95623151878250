import React from "react";
import { TouchableOpacity } from "react-native";
import styled from "styled-components/native";
import { useTranslation } from "react-i18next";
import Title from "../../../components/basic/title";
import RequestSentIcon from "../../../components/refund/request-sent-icon";
import RefundType from "../../../components/refund/refund-type";
import BaseText from "../../../components/basic/base-text";
import useCardPriceTitle from "../../../hooks/use-card-price-title";
import OutlineButton from "../../../components/basic/buttons/outline-button";
import ActivityItemMenu from "./activity-item-menu";
import { useDispatch, useSelector } from "react-redux";
import { updateRefundStatus } from "../../../redux/thunks/refunds-thunk";
import {
  REFUND_AVAILABLE,
  REFUND_SOLD
} from "../../../components/refund/refund-status";
import ItemImage from "../../../components/refund/item-image";
import { marginRightLeft } from "../../../components/styles/global-styles";

const StyledView = styled.View`
  background-color: ${(props) => props.theme.palette.background.primary};
  color: ${(props) => props.theme.palette.font.primary};
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
`;

const StyledTitle = styled(Title)`
  font-weight: 600;
  font-size: 14px;
  ${marginRightLeft("auto")}
`;

const StyledContent = styled.View`
  margin-top: 0;
  width: 100%;
  flex-shrink: 1;
`;

const StyledType = styled.View`
  margin-bottom: 8px;
  ${marginRightLeft("auto")}
`;

const StyledTitleContainer = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${marginRightLeft("auto")}
`;

const StyledPrice = styled(BaseText)`
  font-size: 14px;
  color: ${(props) => props.theme.palette.price.red};
`;

const StyledRefundAmount = styled(StyledPrice)`
  color: ${(props) => props.theme.palette.price.green};
`;

const StyledLabel = styled(BaseText)`
  margin-left: 8px;
  margin-right: 8px;
`;

const StyledFooter = styled.View`
  margin-top: auto;
  flex-direction: row;
  flex-wrap: wrap;
  ${marginRightLeft("auto")}
`;

const StyledSoldButton = styled(OutlineButton)`
  width: auto;
  margin-bottom: 8px;
  ${marginRightLeft(16)}
`;

const StyledMenu = styled.View`
  display: flex;
  justify-content: flex-start;
  ${marginRightLeft(8)}
`;

const StyledItemImage = styled(ItemImage)`
  min-width: 100px;
  margin-right: 16px;
  width: auto;
`;

export default function ActivityItem({ item, navigation }) {
  const { id, title, images = [], type, otherType, status, expirationDate } = item;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.core?.appLoader);
  const [formattedPrice, formattedRefundAmount] = useCardPriceTitle({ item });

  const onClickSold = () => {
    dispatch(
      updateRefundStatus({
        id,
        status: status === REFUND_SOLD ? REFUND_AVAILABLE : REFUND_SOLD
      })
    );
  };

  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate("RefundDetails", {
          id
        })
      }
    >
      <StyledView>
        <StyledItemImage images={images} status={status} />
        <StyledContent>
          <StyledTitle oneLine>{title}</StyledTitle>
          <StyledTitleContainer>
            <StyledPrice>{formattedRefundAmount}</StyledPrice>
            <StyledLabel>{t("selling for")}</StyledLabel>
            <StyledRefundAmount>{formattedPrice}</StyledRefundAmount>
          </StyledTitleContainer>
          <StyledType>
            <RefundType type={type} otherType={otherType} />
            <RequestSentIcon refundId={id} />
          </StyledType>
          <StyledFooter>
            <StyledSoldButton
              title={
                status === REFUND_SOLD ? t("Mark as Available") : t("Mark as Sold")
              }
              onPress={onClickSold}
              disabled={isLoading}
            />
          </StyledFooter>
        </StyledContent>
        <StyledMenu>
          <ActivityItemMenu refundId={id} />
        </StyledMenu>
      </StyledView>
    </TouchableOpacity>
  );
}
