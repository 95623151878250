import React from "react";
import styled from "styled-components/native";
import BaseText from "../base-text";

const StyledText = styled(BaseText)`
  color: ${(props) =>
    props.disabled
      ? props.theme.palette.link.disabled
      : props.theme.palette.link.color};
`;

const StyledTouchableOpacity = styled.TouchableOpacity`
  color: ${(props) =>
    props.disabled
      ? props.theme.palette.link.disabled
      : props.theme.palette.link.color};
  justify-content: center;
  align-items: center;
`;

export default function LinkButton({
  text,
  children,
  onPress,
  disabled,
  fontSize,
  ...rest
}) {
  return (
    <StyledTouchableOpacity
      onPress={onPress}
      disabled={disabled}
      style={{ justifyContent: "center", alignItems: "center" }}
      {...rest}
    >
      {text && (
        <StyledText disabled={disabled} style={{ fontSize: fontSize || 14 }}>
          {text}
        </StyledText>
      )}
      {children ? children : null}
    </StyledTouchableOpacity>
  );
}
