import { actionTypes } from "../actions/action-types";

const initialState = {
  categories: [],
  categoryById: {}
};

const categoriesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.FETCH_CATEGORIES: {
      const { categories } = payload;
      const categoryById = {};
      let other;
      let displayCategories = [];
      if (categories) {
        categories.forEach((element) => {
          categoryById[element.id] = element.name;
          if (element.name === "Other") {
            other = element;
          } else {
            displayCategories.push(element);
          }
        });
      }
      let categoriesSorted = displayCategories.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      displayCategories.push(other);

      return {
        ...state,
        categories: categoriesSorted,
        categoryById
      };
    }
    default:
      return state;
  }
};

export default categoriesReducer;
