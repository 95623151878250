import React, { useState } from "react";
import { isMobile } from "../../../utils/core-utils";
import MobileDatePicker from "./mobile-date-picker";
import WebDatePicker from "./web-date-picker";

export default function CustomDatePicker({ onChange, value }) {
  if (isMobile()) {
    return <MobileDatePicker onChange={onChange} value={value} />;
  } else {
    return <WebDatePicker onChange={onChange} value={value} />;
  }
}
