import React from "react";
import styled from "styled-components/native";
import { baseFlexRow, marginRightLeft } from "../../components/styles/global-styles";
import BaseText from "../../components/basic/base-text";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

const StyledBaseRow = styled.View`
  align-items: center;
  ${baseFlexRow()}
`;

const StyledSaveText = styled(BaseText)`
  color: ${(props) => props.theme.palette.price.green};
`;

const StyledSaveTextFirst = styled(StyledSaveText)`
  ${marginRightLeft(4)}
`;

export function SavePercentage({ percentage }) {
  const { t } = useTranslation();

  if (!percentage) {
    return null;
  }

  return (
    <StyledBaseRow>
      <StyledSaveTextFirst>{t("savePercentage")}</StyledSaveTextFirst>
      <StyledSaveText>{`${percentage}%`}</StyledSaveText>
    </StyledBaseRow>
  );
}
