import React from "react";
import RefundsList from "../refunds-list/refunds-list";
import TestComponents from "./test-components";
import usePushNotifications from "../../hooks/use-push-notifications";
import { useCurrentLocation } from "../../hooks/use-current-location";

export default function Home(props) {
  usePushNotifications();
  // useCurrentLocation();
  return (
    <>
      <RefundsList {...props} />
      {/*<TestComponents />*/}
    </>
  );
}
