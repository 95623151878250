import React, { useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import styled from "styled-components/native";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import useTheme from "../../components/styles/use-theme";
import { Button } from "react-native-elements";
import Loader from "../../components/loaders/loader";
import * as authActions from "../../redux/thunks/auth-thunk";
import Errors from "../../components/errors";
import actionTypes from "../../redux/actions/action-types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import LinkButton from "../../components/basic/buttons/link-button";
import BaseText from "../../components/basic/base-text";
import InputField from "../../components/basic/input-field";
import IconButton from "../../components/basic/buttons/icon-button";
import { createNewUserSettings } from "../../redux/thunks/settings-thunk";
import { registerFormSchema } from "../../utils/validations";
import { StyledAccountPage, StyledButtonWithLabel } from "./account-styles";
import { marginRightLeft } from "../../components/styles/global-styles";
import AppName from "../../components/basic/icons/app-name";
import { sendNewUserNotification } from "../../redux/thunks/core-thunk";

const StyledImage = styled.Image`
  width: 50px;
  height: 50px;
`;

const StyledImageContainer = styled.View`
  display: flex;
  align-items: center;
  margin: 20px auto;
`;

const StyledLoginText = styled(BaseText)`
  ${marginRightLeft(8)}
`;

const StyledForm = styled.View`
  margin: 20px;
  background-color: ${(props) => props.theme.palette.background.primary};
  padding: 20px;
  border-radius: 10px;
`;

const StyledInputRow = styled(InputField)``;

export default function Register(props) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const errors = useSelector((state) => state.auth?.errors);
  const user = useSelector((state) => state.auth?.user);
  const { t } = useTranslation();

  const openLogin = () => {
    props.navigation.navigate("Login");
  };

  const openHome = () => {
    props.navigation.navigate("Home");
  };

  return (
    <StyledAccountPage
      style={{
        flex: 1
      }}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: ""
        }}
        validationSchema={registerFormSchema}
        onSubmit={(values) => {
          setIsLoading(true);
          if (errors) {
            dispatch({
              type: actionTypes.AUTH_API_FAIL,
              payload: { errors: null }
            });
          }
          dispatch(authActions.registerUser(values))
            .then(async (response) => {
              if (response.success) {
                await AsyncStorage.setItem("token", response.token);
                dispatch(createNewUserSettings({ userId: response.result.id }));
                props.navigation.navigate("Home");
                dispatch(sendNewUserNotification({ user: response.result }));
              } else {
                console.error("failed to register", response);
                dispatch({
                  type: actionTypes.AUTH_API_FAIL,
                  payload: { errors: response.errors }
                });
              }
            })
            .catch((e) => {
              console.error("failed to register", e);
              dispatch({
                type: actionTypes.AUTH_API_FAIL,
                payload: { errors: [e.message] }
              });
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
      >
        {(props) => (
          <View
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <StyledForm>
              <IconButton onPress={openHome}>
                <StyledImageContainer>
                  <AppName color={theme.palette.icons.active} size={50} />
                </StyledImageContainer>
              </IconButton>
              <Errors errors={errors} />
              <View style={styles.formGroup}>
                <StyledInputRow
                  title={t("First Name")}
                  name="firstName"
                  multiline={false}
                  props={props}
                />
              </View>
              <View style={styles.formGroup}>
                <StyledInputRow
                  title={t("Last Name")}
                  name="lastName"
                  multiline={false}
                  props={props}
                />
              </View>
              <View style={styles.formGroup}>
                <StyledInputRow
                  title={t("Email")}
                  name="email"
                  multiline={false}
                  props={props}
                  keyboardType="email-address"
                />
              </View>
              <View style={styles.formGroup}>
                <StyledInputRow
                  title={t("Password")}
                  name="password"
                  multiline={false}
                  props={props}
                  secureTextEntry={true}
                />
              </View>

              <View style={styles.buttonContainer}>
                <Button
                  buttonStyle={{
                    backgroundColor: theme.palette.button.primary.color,
                    color: theme.palette.button.primary.text
                  }}
                  title={t("Register")}
                  onPress={props.handleSubmit}
                />
              </View>

              <StyledButtonWithLabel>
                <StyledLoginText>{t("Already have an account")}</StyledLoginText>
                <LinkButton
                  text={t("Login")}
                  onPress={openLogin}
                  disabled={isLoading}
                />
              </StyledButtonWithLabel>
            </StyledForm>
            {isLoading && <Loader />}
          </View>
        )}
      </Formik>
    </StyledAccountPage>
  );
}

const styles = StyleSheet.create({
  formGroup: {
    width: "100%"
  },
  label: {
    marginVertical: 10
  },
  buttonContainer: {
    marginTop: 20
  },
  button: {
    borderRadius: 100
  },
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
});
