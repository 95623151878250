import { useIsMounted } from "./use-is-mounted";
import { useRoute } from "@react-navigation/native";
import { useSelector } from "react-redux";

export function useIsActive() {
  const isMounted = useIsMounted();
  const activeTab = useSelector((state) => state.core.activeTab);
  const route = useRoute();
  const isActive = route.name === activeTab;
  return isMounted && isActive;
}
