import { useMemo } from "react";
import { EXPIRED } from "../components/refund/refund-status";

export default function useRefundStatus({ status, expirationDate }) {
  const calculatedStatus = useMemo(() => {
    const dueDate = expirationDate ? new Date(expirationDate) : null;
    const isExpired = dueDate ? new Date() >= dueDate : false;
    const calculatedStatus = isExpired ? EXPIRED : status;
    return calculatedStatus;
  }, [expirationDate]);

  if (status) {
    return status;
  }

  return calculatedStatus;
}
