import React, { useState } from "react";
import { Modal } from "../../../components/basic/modals/modal";
import styled, { useTheme } from "styled-components/native";
import { useTranslation } from "react-i18next";
import {
  baseFlexRow,
  marginLeftRight,
  marginRightLeftStyle
} from "../../../components/styles/global-styles";
import BaseText from "../../../components/basic/base-text";
import { InfoCircle } from "../../../components/basic/icons/info-circle";
import { TertiaryButton } from "../../../components/basic/buttons/tertiary-button";
import { useDispatch } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import { deleteAccount } from "../../../redux/thunks/core-thunk";
import {
  setAppLoaded,
  showError,
  showSuccess,
  toggleLoader
} from "../../../redux/actions/core-actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { actionTypes } from "../../../redux/actions/action-types";

const StyledText = styled(BaseText)`
  color: ${(props) => props.theme.palette.white};
  font-weight: 600;
  line-height: 24px;
  font-size: 14px;
  ${marginLeftRight(8)}
`;

const StyledRow = styled.View`
  margin-bottom: 4px;
  ${baseFlexRow()}

  align-items: flex-start;
`;

const StyledDeleteButton = styled(TertiaryButton)`
  ${marginLeftRight(12)}
`;

export const DeleteAccountModal = ({ onClose, open }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [disabled, setDisabled] = useState(false);

  const onDelete = async () => {
    try {
      onClose();
      setDisabled(true);
      dispatch(toggleLoader({ loading: true }));
      console.log("delete account");
      await dispatch(deleteAccount());
      dispatch({
        type: actionTypes.CLEAR_USER
      });
      await AsyncStorage.removeItem("token");
      dispatch({
        type: actionTypes.LOGOUT
      });
      setDisabled(false);
      dispatch(
        showSuccess({
          message: "Account deleted successfully"
        })
      );
      navigation.navigate("Home");
    } catch (e) {
      dispatch(showError(e.message));
    } finally {
      dispatch(toggleLoader({ loading: false }));
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("Are you sure you want to delete your account?")}
    >
      <StyledRow>
        <InfoCircle color={theme.palette.white} size={24} />
        <StyledText>{t("This action cannot be undone")}</StyledText>
      </StyledRow>

      <StyledRow style={{ marginTop: 24, alignSelf: "flex-end", display: "flex" }}>
        <StyledDeleteButton
          title={t("Delete Account")}
          onPress={onDelete}
          style={{ ...marginRightLeftStyle(theme, 12) }}
          disabled={disabled}
        />

        <TertiaryButton title={t("Cancel")} onPress={onClose} disabled={disabled} />
      </StyledRow>
    </Modal>
  );
};
