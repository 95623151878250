import React from "react";
import styled from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";
import BaseText from "../basic/base-text";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components/native";
import IconButton from "../basic/buttons/icon-button";
import GeneralItem, { generalItemStyle } from "./general-item";

const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.addImage.background};
`;

const StyledText = styled(BaseText)`
  color: ${(props) => props.theme.palette.addImage.font};
`;

export default function AddPhotoButton({ onPress, ...rest }) {
  const { t } = useTranslation();
  const theme = useTheme();
  let style = generalItemStyle;
  // if (noImagesSelected) {
  //   style = { ...generalItemStyle, width: "100%", height: 160 };
  // }
  return (
    <IconButton onPress={onPress}>
      <GeneralItem {...rest}>
        <Container {...rest} style={style}>
          <MaterialIcons
            name="photo-filter"
            size={24}
            color={theme.palette.addImage.font}
          />
          <StyledText>{t("Add Image")}</StyledText>
        </Container>
      </GeneralItem>
    </IconButton>
  );
}
