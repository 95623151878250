import { fetchAPI } from "../../utils/fetch-utls";

export const postAPI = (data) => {
  return async (dispatch, getState) => {
    try {
      const { url, body } = data;
      const jwtToken = getState().auth?.token;

      return fetchAPI({ url, body, method: "POST", jwtToken });
    } catch (e) {
      console.error("failed to postAPI ", data, e);
      throw e;
    }
  };
};

export const getAPI = (url) => {
  return async (dispatch, getState) => {
    try {
      const jwtToken = getState().auth?.token;

      return fetchAPI({ url, jwtToken });
    } catch (e) {
      console.error("failed to getAPI ", data, e);
      throw e;
    }
  };
};

export const putAPI = (data) => {
  return async (dispatch, getState) => {
    try {
      const { url, body } = data;

      const jwtToken = getState().auth?.token;

      return fetchAPI({ url, body, method: "PUT", jwtToken });
    } catch (e) {
      console.error("failed to putAPI ", data, e);
      throw e;
    }
  };
};

export const deleteAPI = (data) => {
  return async (dispatch, getState) => {
    try {
      const { url, body } = data;

      const jwtToken = getState().auth?.token;

      return fetchAPI({ url, body, method: "DELETE", jwtToken });
    } catch (e) {
      console.error("failed to deleteAPI ", data, e);
      throw e;
    }
  };
};

export const fetch = (data) => {
  return async (dispatch, getState) => {
    try {
      const { url, body, method } = data;

      const jwtToken = getState().auth?.token;

      return fetchAPI({ url, body, method, jwtToken });
    } catch (e) {
      console.error("failed to fetch ", data, e);
      throw e;
    }
  };
};

export const restClient = {
  getAPI,
  postAPI,
  putAPI,
  fetch,
  deleteAPI
};
