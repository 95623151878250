import { actionTypes } from "../actions/action-types";

export const BuyRequests = "BuyRequests";
export const Notifications = "Notifications";
export const Messages = "Messages";

const tabsOrder = [BuyRequests, Notifications, Messages];

export const MessagesTab = {};

tabsOrder.forEach((item, idx) => {
  MessagesTab[item] = idx;
});

const initialState = {
  items: [],
  isLoading: false,
  activeTab: MessagesTab[BuyRequests],
  isLoaded: false,
  lastMessageByThreadId: {},
  isMessagesDirty: false,
  threadIdToUnreadCount: null,
  unreadCount: null,
  isLastMessagesLoaded: false
};

const messagesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_MESSAGES: {
      return {
        ...state,
        items: payload,
        isLoading: false,
        isLoaded: true
      };
    }
    case actionTypes.UNSHIFT_MESSAGE: {
      return {
        ...state,
        items: [payload, ...state.items]
      };
    }
    case actionTypes.SET_MESSAGES_LOADING: {
      return {
        ...state,
        isLoading: payload
      };
    }
    case actionTypes.SET_MESSAGES_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: payload
      };
    }
    case actionTypes.SET_MESSAGES_LOADED: {
      return {
        ...state,
        isLoaded: payload
      };
    }
    case actionTypes.SET_LAST_MESSAGE: {
      const { threadId } = payload;
      return {
        ...state,
        lastMessageByThreadId: {
          ...state.lastMessageByThreadId,
          [threadId]: payload
        }
      };
    }
    case actionTypes.SET_LAST_MESSAGES: {
      return {
        ...state,
        lastMessageByThreadId: {
          ...state.lastMessageByThreadId,
          ...payload
        },
        isLastMessagesLoaded: true
      };
    }
    case actionTypes.SET_MESSAGES_DIRTY: {
      return {
        ...state,
        isMessagesDirty: payload
      };
    }
    case actionTypes.SET_THREAD_ID_TO_UNREAD_COUNT: {
      return {
        ...state,
        threadIdToUnreadCount: {
          ...state.threadIdToUnreadCount,
          ...payload
        }
      };
    }
    case actionTypes.SET_MESSAGES_UNREAD_COUNT_BY_THREAD_ID: {
      const { threadId, count } = payload;
      const prevMap = state.threadIdToUnreadCount || {};
      return {
        ...state,
        threadIdToUnreadCount: { ...prevMap, [threadId]: count }
      };
    }
    case actionTypes.INCREASE_MESSAGES_UNREAD_COUNT_BY_THREAD_ID: {
      const { threadId, num } = payload;
      const prevMap = state.threadIdToUnreadCount || {};
      const count = prevMap[threadId] || 0;
      const newCount = count + num;
      return {
        ...state,
        threadIdToUnreadCount: { ...prevMap, [threadId]: newCount }
      };
    }
    case actionTypes.SET_MESSAGES_UNREAD_COUNT: {
      return {
        ...state,
        unreadCount: payload
      };
    }
    case actionTypes.INCREASE_MESSAGES_UNREAD_COUNT: {
      const unreadCount = state.unreadCount;
      return {
        ...state,
        unreadCount: unreadCount + payload
      };
    }
    case actionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default messagesReducer;
