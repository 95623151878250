import React, { useState } from "react";
import styled, { useTheme } from "styled-components/native";
import { Checkbox as NativeCheckbox } from "native-base";
import { useTranslation } from "react-i18next";
import BaseText from "../../components/basic/base-text";
import { flexDirection } from "../styles/global-styles";

const StyledRow = styled.View`
  flex-direction: row;
  align-items: center;
  ${flexDirection}
`;

const StyledLabel = styled(BaseText)`
  margin: 0 8px;
  color: ${(props) => props.theme.palette.input.font};
`;

export default function Checkbox({
  name,
  value,
  isChecked,
  onChange,
  label,
  ...rest
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledRow>
      <NativeCheckbox
        name={name}
        value={value}
        isChecked={isChecked}
        size="sm"
        colorScheme={"blue"}
        onChange={onChange}
        {...rest}
      />
      {label && <StyledLabel>{label}</StyledLabel>}
    </StyledRow>
  );
}
