import { actionTypes } from "../actions/action-types";
import { USER_REQUESTS_API } from "../../constants/apis-urls";
import { getUnreadIds } from "../../utils/core-utils";
import { showError } from "../actions/core-actions";
import { getAPI, postAPI } from "./fetch-thunk";

export const fetchUserRefundsBuyRequests = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: actionTypes.SET_BUY_REQUESTS_LOADING,
        payload: true
      });

      const userId = getState().auth?.user?.id;
      console.log("fetching user refunds buy requests ", userId);
      const url = `${USER_REQUESTS_API}/buy-requests/${userId}`;

      const result = await dispatch(getAPI(url));
      console.log("fetching user refunds buy requests res ", result);

      dispatch({
        type: actionTypes.SET_BUY_REQUESTS,
        payload: result
      });
    } catch (e) {
      console.error("failed to fetch user refunds buy requests ", e);
      dispatch(showError("failed to fetch user refunds buy requests " + e.message));
    } finally {
      dispatch({
        type: actionTypes.SET_BUY_REQUESTS_LOADING,
        payload: false
      });
    }
  };
};

export const fetchBuyRequestsUnreadCount = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      console.log("fetching buy request unread count ", userId);
      const url = `${USER_REQUESTS_API}/buy-requests/${userId}/unread-count`;
      const buyRequestsUnreadCount = getState().buyRequests.unreadCount;

      const result = await dispatch(getAPI(url));

      console.log("fetching buy request unread count res ", result);

      dispatch({
        type: actionTypes.SET_REFUND_REQUESTS_UNREAD_COUNT,
        payload: result
      });
      if (buyRequestsUnreadCount !== result) {
        dispatch(fetchUserRefundsBuyRequests());
      }
    } catch (e) {
      console.error(
        "failed to fetching buy request unread count userId ",
        userId,
        e
      );
      dispatch(showError(e.message));
    }
  };
};

export const updateReadBuyRequests = ({ items }) => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    if (!items || items.length === 0) {
      return;
    }
    try {
      const unreadIds = getUnreadIds(items);
      if (!unreadIds || unreadIds?.length === 0) {
        console.log("updateReadBuyRequests no unreadIds ", unreadIds);
        return;
      }
      console.log("updateReadBuyRequests ", userId, " unreadIds ", unreadIds);
      const url = `${USER_REQUESTS_API}/buy-requests/${userId}/update-read-at`;
      const result = await dispatch(
        postAPI({
          url,
          body: {
            ids: unreadIds
          }
        })
      );

      console.log("updateReadBuyRequests res ", result);

      dispatch({
        type: actionTypes.SET_REFUND_REQUESTS_UNREAD_COUNT,
        payload: 0
      });
    } catch (e) {
      console.error("failed to fetching user buy requests userId ", userId, e);
      dispatch(showError(e.message));
    }
  };
};
