import React from "react";
import BaseText from "./basic/base-text";
import { useTranslation } from "react-i18next";
import { StyledLayout } from "./styles/screen-layout";
import styled, { useTheme } from "styled-components/native";
import { getImageData } from "../utils/images-utils";
import ItemImage from "./refund/item-image";

const StyledContainer = styled(StyledLayout)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const StyledBackdrop = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  background-color: ${(props) => props.theme.palette.background.primary};
  width: 100%;
  height: 100%;
`;

const StyledImage = styled(ItemImage)`
  height: auto;
  min-width: 100px;
  min-height: 100px;

  max-height: 100px;
  max-width: 100px;
  border-radius: 7px;
  margin-right: 16px;
`;

const StyledBox = styled.View`
  display: flex;
  flex-direction: row;
  margin: 0 36px;
`;

const StyledTitle = styled(BaseText)`
  font-size: 20px;
  margin-top: 16px;
  margin-bottom: 36px;
  flex-wrap: wrap;
`;

const StyledText = styled(BaseText)`
  flex-wrap: wrap;
`;

export const ItemNotAvailable = ({ text, isTitle }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledBackdrop>
      <StyledContainer>
        {isTitle && (
          <StyledTitle>{text || t("This item no longer available")}</StyledTitle>
        )}

        <StyledBox>
          <StyledImage images={getImageData()} />
          <StyledText>
            {t("There are many other items available, please check our home page")}
          </StyledText>
        </StyledBox>
      </StyledContainer>
    </StyledBackdrop>
  );
};
