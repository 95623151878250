import * as React from "react";
import { useEffect } from "react";
import { BackHandler } from "react-native";
import styled from "styled-components/native";
import Title from "../components/basic/title";
import BackButton from "../components/basic/buttons/back-button";
import { layoutWebWidth } from "../components/styles/screen-layout";
import { useNavigation } from "@react-navigation/native";
import { headerHeight, marginTop } from "./nav-style";

const StyledWrapper = styled.View`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.primary};
  width: 100%;
`;

const StyledContainer = styled.View`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.background.primary};
  width: 100%;
  height: ${headerHeight}px;
  margin-top: ${marginTop}px;

  ${layoutWebWidth};
`;

const StyledTextContainer = styled.View`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

const StyledText = styled(Title)`
  justify-content: center;
  display: flex;
`;

const StyledBackContainer = styled.TouchableOpacity`
  position: absolute;
  left: 0;
  z-index: 1;
`;

function HeaderWithBackButton({ text, onBack, onPressBack }) {
  const navigation = useNavigation();

  const onBackPress = () => {
    console.log("hardwareBackPress");
    if (onBack) {
      onBack();
      goBack();
    }
    if (onPressBack) {
      onPressBack();
    }
    return true;
  };
  useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", onBackPress);
    return () => BackHandler.removeEventListener("hardwareBackPress", onBackPress);
  }, []);

  const goBack = () => {
    navigation.canGoBack() ? navigation.goBack(null) : navigation.navigate("Home");
  };

  const defaultOnPressBack = () => {
    if (onBack) {
      if (onBack()) {
        goBack();
      }
    } else {
      goBack();
    }
  };

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledBackContainer>
          <BackButton
            onPress={onPressBack || defaultOnPressBack}
            style={{ marginLeft: 8 }}
          />
        </StyledBackContainer>
        <StyledTextContainer>
          <StyledText>{text}</StyledText>
        </StyledTextContainer>
      </StyledContainer>
    </StyledWrapper>
  );
}

export default HeaderWithBackButton;
