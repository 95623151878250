import { DEFAULT_LANG } from "../../i18n";
import { DEFAULT_THEME } from "../components/styles/themes";
import { DEFAULT_CURRENCY, LANGUAGE_TO_CURRENCY } from "./currency-utils";

export const DEFAULT_SETTINGS = {
  theme: DEFAULT_THEME,
  language: DEFAULT_LANG,
  currency: DEFAULT_CURRENCY, //LANGUAGE_TO_CURRENCY[DEFAULT_LANG],
  location: undefined
};
