import { actionTypes } from "../actions/action-types";
import { SERVER_URL } from "../../config/config";
import { showError } from "../actions/core-actions";
import { restClient } from "./fetch-thunk";

export const SEARCH_API = `${SERVER_URL}/api/search`;

export const fetchSearches = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      if (!userId) {
        console.log("fetchSearches no userId ", userId);
        return;
      }
      console.log("fetchSearches userId ", userId);
      dispatch({
        type: actionTypes.SET_SEARCHES_LOADING,
        payload: true
      });
      console.log("fetchSearches userId ", userId);
      const url = `${SEARCH_API}/${userId}`;
      const result = await dispatch(restClient.getAPI(url));
      console.log("fetchSearches resultData ", result);
      if (result) {
        dispatch({
          type: actionTypes.SET_SEARCHES,
          payload: result
        });
      }
    } catch (e) {
      console.error("failed to fetch searches, userId ", userId, e);
      dispatch(showError(e.message));
    }
  };
};

export const clearAllSearches = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      console.log("clearAllSearches userId ", userId);
      dispatch({
        type: actionTypes.SET_SEARCHES_LOADING,
        payload: true
      });
      console.log("fetchSearches userId ", userId);
      const url = `${SEARCH_API}/${userId}`;
      const result = await dispatch(restClient.fetch({ url, method: "DELETE" }));
      console.log("clearAllSearches resultData ", result);
      dispatch({
        type: actionTypes.SET_SEARCHES,
        payload: []
      });
    } catch (e) {
      console.error("failed to clear all searches, userId ", userId, e);
      dispatch(showError(e.message));
    }
  };
};
