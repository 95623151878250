import React from "react";
import styled from "styled-components/native";
import { Button as SolidButton } from "react-native-elements";
import { useTheme } from "styled-components/native";
import { AntDesign } from "@expo/vector-icons";
import { isWeb } from "../../../utils/core-utils";

const StyledContainer = styled.View`
  div {
    &:hover {
      background-color: ${(props) =>
        props.theme.palette.button.outlined.backgroundHover};

      div {
        color: ${(props) => props.theme.palette.button.outlined.textHover};
      }
    }
  }
`;

export default function Button({
  title,
  onPress,
  disabled,
  type,
  buttonStyle = {},
  titleStyle = {},
  checked,
  icon,
  iconSize = 24,
  ...rest
}) {
  const theme = useTheme();
  const solidButtonStyle = {
    backgroundColor: theme.palette.button.primary.color,
    borderColor: theme.palette.button.primary.border
  };
  const solidTitleStyle = {
    color: theme.palette.button.primary.font
  };
  const disabledStyle = {
    backgroundColor: theme.palette.button.primary.disabled.color,
    borderColor: theme.palette.button.primary.disabled.color,
    opacity: 0.5
  };
  const disabledTitleStyle = {
    color: theme.palette.button.primary.disabled.font
  };

  const Wrapper = isWeb() ? StyledContainer : React.Fragment;

  return (
    <Wrapper>
      <SolidButton
        type={type}
        buttonStyle={{ ...solidButtonStyle, ...buttonStyle }}
        titleStyle={{ ...solidTitleStyle, ...titleStyle }}
        title={title}
        onPress={onPress}
        disabled={disabled}
        disabledStyle={disabledStyle}
        disabledTitleStyle={disabledTitleStyle}
        pointerEvents={disabled ? "none" : undefined}
        icon={
          checked ? (
            <AntDesign
              name="check"
              size={iconSize}
              color={theme.palette.button.primary.disabled.font}
              style={{ marginLeft: 4 }}
            />
          ) : (
            icon
          )
        }
        iconRight
        {...rest}
      />
    </Wrapper>
  );
}
