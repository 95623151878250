export const ENV_PROD = {
  SERVER_URL: "https://refunderr.com",
  SOCKET_URL: "https://refunderr.com",
  APP_URL: "https://refunderr.com",
  S3_BUCKET: "refunderr-prod",
  REGION: "us-east-2",
  ACCESS_KEY: "AKIAQQ4QB3U73GDHLHVN",
  SECRET_ACCESS_KEY: "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",
  FOLDER_NAME: "refunds",
  S3_URL: "https://refunderr-prod.s3.us-east-2.amazonaws.com"
};
