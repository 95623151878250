import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  StyleSheet
} from "react-native";
import { Video } from "expo-av";
import {
  Day,
  GiftedChat,
  InputToolbar,
  LoadEarlier,
  Time
} from "react-native-gifted-chat";
import styled, { useTheme } from "styled-components/native";
import { StyledLayout } from "../../components/styles/screen-layout";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  fetchChatMessagesAPI,
  fetchThreadId,
  saveThreadId
} from "../../redux/thunks/chats-thunk";
import { setThreadId } from "../../redux/actions/chats-actions";
import { createThreadId } from "../../utils/chat-utils";
import CustomBubble from "./custom-bubble";
import ChatHeader from "./chat-header";
import { actionTypes } from "../../redux/actions/action-types";
import SendButton from "./send-button";
import { getUserFullName, isAndroid, isIos } from "../../utils/core-utils";
import ChatRefundMessage from "./chat-refund-message";
import useAddImage from "../../components/images-picker/use-add-image";
import ChatActions from "./chat-actions";
import Loader from "../../components/loaders/loader";
import { v4 as uuidv4 } from "uuid";
import SlackMessage from "./slack-style/slack-message";
import emojiUtils from "emoji-utils";
import {
  handleUnreadMessages,
  updateUnreadMessagesToRead
} from "../../redux/thunks/messages-thunk";
import { showError } from "../../redux/actions/core-actions";
import { ChatImage } from "./chat-image";
import { useSocket } from "../../context/socket";
import { ItemNotAvailable } from "../../components/item-not-available";
import { ItemNotAvailableModal } from "../../components/modals/item-not-available-modal";

const { width } = Dimensions.get("window");

const StyledContainer = styled.View`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export default function Chat({
  id,
  toUser = {},
  refundId,
  backScreen,
  tab,
  backParams
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const toUserId = toUser?.id;
  const user = useSelector((state) => state.auth?.user);
  const users = useSelector((state) => state.auth?.users);
  const userId = user?.id;
  const dispatch = useDispatch();
  const currentChatId = useSelector((state) => state.chats?.currentChatId);
  const threadId = id
    ? id
    : createThreadId({ fromUserId: userId, toUserId, refundId });
  const messages = useSelector((state) => state.chats?.messagesByThreadId[threadId]);
  const threadIdObject = useSelector((state) => state.chats?.threadsById[threadId]);
  const refund = useSelector((state) => state.refunds.itemsById[refundId]);
  const [loading, setLoading] = useState(false);
  const { socket, sendMessage } = useSocket();
  const isDisabled = !!(!refund || refund.deleted || threadIdObject?.deleted);

  const [open, setOpen] = useState(isDisabled);
  console.log("chat threadIdObject ", threadIdObject);
  console.log("chat refund ", refund);

  const setMessages = (messages) => {
    dispatch({
      type: actionTypes.SET_CHAT_MESSAGES,
      payload: { threadId, messages }
    });
  };

  useEffect(() => {
    if (threadId) {
      dispatch(setThreadId({ userId: toUserId, threadId }));
      dispatch(fetchThreadId({ threadId }));
      dispatch({ type: actionTypes.SET_CURRENT_THREAD_ID, payload: threadId });
      fetchData();
      dispatch(handleUnreadMessages({ threadId }));
    }
  }, []);

  useEffect(() => {
    if (threadIdObject !== undefined) {
      if (threadIdObject === -1) {
        dispatch(saveThreadId({ toUserId, threadId, refundId }));
      }
    }
  }, [threadIdObject]);

  const userById = useMemo(() => {
    return {
      [userId]: {
        _id: user.id,
        ...user,
        name: getUserFullName(user)
      },
      [toUserId]: {
        _id: toUser.id,
        ...toUser,
        name: getUserFullName(toUser)
      }
    };
  }, [userId, toUserId, user, toUser]);

  const refundDisplayMessage = useMemo(
    () => ({
      _id: user.id,
      text: t("Requested refund"),
      createdAt: new Date(),
      user: user,
      type: "refund"
    }),
    [user]
  );

  const mapMessage = useCallback(
    (message) => {
      return {
        _id: message.id,
        text: message.text,
        createdAt: new Date(message.createdAt),
        user: userById[message.fromUserId],
        image: message.image
      };
    },
    [userById]
  );

  async function fetchData() {
    try {
      if (threadId && !messages) {
        const result = await dispatch(fetchChatMessagesAPI({ threadId, userId }));
        if (result && result.length > 0) {
          const displayMessages = result.map((item) => mapMessage(item));
          setMessages(
            refundId ? [...displayMessages, refundDisplayMessage] : displayMessages
          );
        } else {
          setMessages(refundId ? [refundDisplayMessage] : []);
        }
      }
    } catch (e) {
      dispatch(showError("failed to fetch user chat messages " + e.message));
    }
  }

  const convertSocketResponseToMessage = (message) => {
    if (
      !message ||
      !message.id ||
      !(message.text || message.image) ||
      !message.createdAt ||
      !message.fromUserId
    ) {
      return null;
    }
    return {
      _id: message.id,
      text: message.text,
      image: message.image,
      messageType: message.image ? "image" : undefined,
      createdAt: new Date(message.createdAt),
      user: userById[message.fromUserId]
    };
  };

  const receiveSocketMessages = useCallback(
    (socketResponse) => {
      console.log("chat receiveSocketMessages socketResponse ", socketResponse);
      if (messages && socketResponse) {
        const { threadId: id } = socketResponse;
        if (id === threadId) {
          console.log(
            "chat receiveSocketMessages same thread id socketResponse ",
            socketResponse
          );

          const found = messages
            ? messages.filter((item) => item._id === socketResponse.id)
            : null;
          if (found && found.length > 0) {
            return;
          }
          if (toUser !== null && toUser.id === socketResponse.fromUserId) {
            const item = convertSocketResponseToMessage(socketResponse);
            if (item) {
              setMessages(messages ? GiftedChat.append(messages, [item]) : [item]);
              dispatch(
                updateUnreadMessagesToRead({ threadId, ids: [socketResponse.id] })
              );
              dispatch({
                type: actionTypes.SET_LAST_MESSAGE,
                payload: socketResponse
              });
            }
          }
        }
      }
    },
    [messages, setMessages, toUser, threadId]
  );

  useEffect(() => {
    // socket.on("logout-response", (data) => {
    //   if (onLogoutResponse) {
    //     onLogoutResponse(data);
    //   }
    // });

    if (socket) {
      socket.on("add-message-response", (data) => {
        receiveSocketMessages(data);
      });
    }

    // socket.on("chat-list-response", (data) => {
    //   if (onChatListResponse) {
    //     onChatListResponse(data);
    //   }
    // });
  }, [socket, receiveSocketMessages]);

  const onSend = (newMessages = []) => {
    console.log("onSend newMessages ", newMessages);
    setMessages(GiftedChat.append(messages, newMessages));
    let data;
    newMessages.forEach((message) => {
      data = {
        threadId: threadId,
        text: message.text,
        refundId: refundId,
        fromUserId: userId,
        toUserId,
        fromUser: userById[userId],
        refundTitle: refund?.title
      };
      const res = sendMessage(data);
      console.log("send response ", res);
      const currentDate = Date.now();
      const lastMessage = {
        threadId: data.threadId,
        text: data.text,
        refundId: data.refundId,
        id: uuidv4(),
        fromUserId: data.fromUserId,
        toUserId: data.toUserId,
        createdAt: currentDate,
        updatedAt: currentDate
      };
      dispatch({
        type: actionTypes.SET_LAST_MESSAGE,
        payload: lastMessage
      });
    });
  };

  const onSendImage = (image) => {
    console.log("onSendImage image ", image);
    const message = {
      _id: uuidv4(),
      image: image,
      createdAt: new Date(),
      user: userById[userId],
      messageType: "image"
    };
    setMessages(GiftedChat.append(messages, [message]));
    const res = sendMessage({
      image: image,
      fromUserId: userId,
      toUserId,
      threadId: threadId || -1,
      fromUser: userById[userId],
      refundTitle: refund?.title
    });
    console.log("send response ", res);
  };

  const getVimeoId = (url) => {
    const regExp = /^.*(vimeo\.com\/)([0-9]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length >= 9) {
      return match[2];
    }
    return null;
  };

  const renderMessageVideo = (props) => {
    const { currentMessage } = props;
    if (currentMessage.video.includes("vimeo")) {
      if (Platform.OS === "web") {
        return null;
      }
      // return (
      //   <View style={styles.video}>
      //     <WebView
      //       style={{ borderRadius: 13 }}
      //       source={{
      //         uri: `https://player.vimeo.com/video/${getVimeoId(
      //           currentMessage!.video!
      //         )}`,
      //       }}
      //     />
      //   </View>
      // );
    }
    return (
      <Video
        Readonly
        source={{ uri: currentMessage?.video }}
        style={styles.video}
        resizeMode="cover"
      />
    );
  };

  const renderInputToolbar = (props) => {
    return (
      <InputToolbar
        {...props}
        containerStyle={{
          backgroundColor: "transparent",
          color: theme.palette.input.font
        }}
        textInputStyle={{
          backgroundColor: "transparent",
          color: theme.palette.input.font
        }}
      />
    );
  };

  const myLoadEarlier = (props) => (
    <LoadEarlier {...props} label="Custom Load Earlier Label" />
  );

  const handleBack = () => {
    console.log("chat handleBack");
    dispatch({
      type: actionTypes.SET_CURRENT_CHAT,
      payload: null
    });
    dispatch({ type: actionTypes.SET_CURRENT_THREAD_ID, payload: null });
  };

  const renderCustomView = (props) => {
    if (props.currentMessage?.type === "refund") {
      return <ChatRefundMessage refundId={refundId} />;
    }
    return null;
  };

  const renderBubble = (props) => {
    if (props.currentMessage?.type === "refund") {
      const { type, ...rest } = props.currentMessage;
      return (
        <CustomBubble
          {...props}
          currentMessage={{ ...rest }}
          renderCustomView={() => <ChatRefundMessage refundId={refundId} />}
        />
      );
    } else {
      return <CustomBubble {...props} />;
    }
  };

  function renderDay(props) {
    if (props.currentMessage?.type === "refund") {
      return null;
    }
    return <Day {...props} />;
  }

  function renderTime(props) {
    if (props.currentMessage?.type === "refund") {
      return null;
    }
    return <Time {...props} />;
  }

  const sendImage = (args) => {
    console.log("sendImage", args);
  };

  const { pickImage } = useAddImage({
    onAdd: onSendImage,
    setLoading,
    isUpload: true,
    isChat: true
  });

  const renderMessage = (props) => {
    const {
      currentMessage: { text: currText }
    } = props;

    let messageTextStyle;

    // Make "pure emoji" messages much bigger than plain text.
    if (currText && emojiUtils.isPureEmojiString(currText)) {
      messageTextStyle = {
        fontSize: 28,
        // Emoji get clipped if lineHeight isn't increased; make it consistent across platforms.
        lineHeight: Platform.OS === "android" ? 34 : 30
      };
    }

    return <SlackMessage {...props} messageTextStyle={messageTextStyle} />;
  };

  return (
    <StyledLayout style={{ flex: 1, paddingTop: 16 }}>
      <ChatHeader
        user={toUser}
        handleBack={handleBack}
        refund={refund}
        backScreen={backScreen}
        tab={tab}
        backParams={backParams}
      />
      <GiftedChat
        messages={messages || []}
        onSend={onSend}
        renderMessageVideo={renderMessageVideo}
        user={{
          _id: userById[userId]._id
        }}
        renderBubble={renderBubble}
        placeholder={isDisabled ? t("Item no longer available") : t("Text message")}
        description={t("No messages")}
        renderAvatar={null}
        renderInputToolbar={renderInputToolbar}
        renderLoadEarlier={myLoadEarlier}
        renderSend={(props) => <SendButton {...props} />}
        renderActions={(props) => (
          <ChatActions pickImage={pickImage} sendImage={sendImage} {...props} />
        )}
        textInputProps={{}}
        // renderCustomView={renderCustomView}
        renderDay={renderDay}
        renderTime={renderTime}
        // renderMessage={renderMessage}
        renderMessageImage={ChatImage}
        disableComposer={isDisabled}
      />
      {Platform.OS === "android" && <KeyboardAvoidingView behavior="padding" />}
      {loading && <Loader />}
      <ItemNotAvailableModal onClose={() => setOpen(false)} open={open} />

      {/*<Modal visible={!!imageUrls} transparent={true}>*/}
      {/*  <ImageViewer imageUrls={imageUrls} />*/}
      {/*</Modal>*/}
    </StyledLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  video: {
    width: width / 1.5,
    height: 150,
    margin: 13,
    borderRadius: 13
  },
  image: {
    width: 150,
    height: 100,
    borderRadius: 13,
    margin: 3,
    resizeMode: "cover"
  }
});
