import { actionTypes } from "./action-types";

export const refundsScrollToTopToggle = (isScrollTop) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.REFUNDS_LIST_SCROLL_TOP_TOGLLE,
      payload: isScrollTop
    });
  };
};
