export const TYPES = {
  STORE_REFUND: STORE_REFUND,
  GIFT_CARD: GIFT_CARD,
  COUPON: COUPON,
  VOUCHER: VOUCHER,
  TICKET: TICKET,
  OTHER: OTHER
};

export const GIFT_CARD = "gift-card";
export const STORE_REFUND = "store-refund";
export const VOUCHER = "voucher";
export const COUPON = "coupon";
export const OTHER = "other";
export const TICKET = "ticket";

export const REFUND_TYPES = [
  STORE_REFUND,
  GIFT_CARD,
  COUPON,
  VOUCHER,
  TICKET,
  OTHER
];

export const REFUND_TYPE_DISPLAY_NAME = {
  [STORE_REFUND]: "Store Refund",
  [GIFT_CARD]: "Gift Card",
  [COUPON]: "Coupon",
  [VOUCHER]: "Voucher",
  [TICKET]: "Show Ticket",
  [OTHER]: "Other"
};

export const NONE = "none";

export const REFUND_DISPLAY_TYPES = REFUND_TYPES.map((name) => ({
  label: REFUND_TYPE_DISPLAY_NAME[name],
  value: name
}));

const getDataByName = (name) => ({
  name: `${name} Name`,
  website: `${name} Website`,
  location: `${name} Location`
});

const storeData = getDataByName("Store");
const ticketData = getDataByName("Show");

export const storeTitles = {
  [GIFT_CARD]: getDataByName("Giftcard"),
  [STORE_REFUND]: storeData,
  [COUPON]: storeData,
  [VOUCHER]: storeData,
  [TICKET]: ticketData,
  [OTHER]: storeData
};

export const defaultIcon = "cash-refund";

export const iconByType = {
  [STORE_REFUND]: "storefront", //<MaterialIcons
  [GIFT_CARD]: "card-giftcard", //<MaterialIcons
  [OTHER]: "cash-refund", //<MaterialCommunityIcons
  [COUPON]: "cash-refund", //<MaterialCommunityIcons
  [VOUCHER]: "cash-refund", //<MaterialCommunityIcons
  [TICKET]: "ticket" //<MaterialCommunityIcons
};

export const getIconByType = (type) => iconByType[type] || defaultIcon;
