import { getErrorMessage } from "./errors-utils";

export const createUrl = (url, props) => {
  let res = url;
  if (props) {
    res += "?";
    for (const [index, [key, value]] of Object.entries(Object.entries(props))) {
      if (value) {
        if (index !== 0) {
          res += `&`;
        }
        res += `${key}=${encodeURIComponent(value)}`;
      }
    }
  }
  return res;
};

export const fetchAPI = async ({ url, body, method = "GET", jwtToken }) => {
  try {
    console.log("fetch api url ", url, " method ", method, " body ", body);
    const headers = {
      "Content-Type": "application/json"
    };

    if (jwtToken) {
      headers.Authorization = "Bearer " + jwtToken;
    }
    const response = await fetch(url, {
      method: method,
      headers,
      body: body ? JSON.stringify(body) : undefined
    });

    let responseData = await getResponse(response);
    if (responseData && !responseData?.success) {
      console.error("failed fetch api ", responseData);
      const errorMessage = getErrorMessage(responseData);
      throw Error(errorMessage);
    }
    const result = responseData?.result;
    console.log("fetch api result ", result);
    return result;
  } catch (e) {
    console.error("failed fetch api , url", url, e.message);
    throw e;
  }
};

export const getResponse = async (response) => {
  let responseData;
  try {
    responseData = await response.json();
  } catch (e) {
    console.error("failed fetch api to parse json ", e.message);
    try {
      responseData = await response.text();
    } catch (e) {
      console.error("failed to parse text ", e.message, " response ", response);
    }
  }
  console.log("get response ", responseData);
  return responseData;
};
