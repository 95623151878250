import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import RefundDetails from "../screens/refund-details/refund-details";
import ChatScreen from "../screens/chat/chat-screen";

const Stack = createStackNavigator();

export default function RefundDetailsStackNavigator({ route: { params } }) {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="RefundDetails"
        component={RefundDetails}
        options={{ headerShown: false }}
        initialParams={params}
      />
      <Stack.Screen
        name="ChatScreen"
        component={ChatScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
}
