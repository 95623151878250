import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../../components/basic/dropdown";
import { NONE } from "../../utils/refund-types-utils";

export default function SelectCategories({
  onChange,
  value = NONE,
  name,
  type,
  ...rest
}) {
  const categories = useSelector((state) => state.categories?.categories);
  const { t } = useTranslation();

  const displayCategories = useMemo(() => {
    const result = [...categories].map((item) => ({
      label: t(item.name),
      value: item.id
    }));
    result.unshift({
      label: t("Select Category"),
      value: NONE
    });
    return result;
  }, [categories, t]);

  return (
    <Dropdown
      items={displayCategories}
      onValueChange={onChange}
      value={value || NONE}
      textInputProps={{ name }}
      {...rest}
    />
  );
}
