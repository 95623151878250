import * as Notifications from "expo-notifications";
import React, { useState, useEffect, useRef } from "react";
import {
  registerForPushNotificationsAsync,
  sendPushNotification
} from "../push-notifications/push-notifications-utils";
import { useDispatch, useSelector } from "react-redux";
import { addUserToken } from "../redux/thunks/push-token-thunk";
import { useNavigation } from "@react-navigation/native";
import { changeMessagesTab } from "../redux/actions/message-actions";
import { MessagesTab } from "../redux/reducers/messages-reducer";
import { actionTypes } from "../redux/actions/action-types";
import { isDevice } from "../utils/core-utils";
import useUser from "./use-user";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: true
  })
});

export default function usePushNotifications() {
  const user = useSelector((state) => state.auth?.user);
  const expoPushTokens = useSelector((state) => state.auth?.expoPushTokens);
  const isPushTokensLoaded = useSelector((state) => state.auth?.isPushTokensLoaded);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const hasExpoPushToken =
    isPushTokensLoaded && expoPushTokens ? expoPushTokens?.length > 0 : false;
  const [isLoggedIn] = useUser();

  const [notification, setNotification] = useState(null);
  const notificationListener = useRef();
  const responseListener = useRef();

  useEffect(() => {
    if (!isLoggedIn || !isDevice()) {
      return;
    }
    if (hasExpoPushToken) {
      return;
    }
    registerForPushNotificationsAsync().then((token) => {
      dispatch(
        addUserToken({
          userId: user.id,
          expoPushToken: token
        })
      );
    });

    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener(
      (notification) => {
        setNotification(notification);
        console.log("setNotification ", notification);
      }
    );

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener(
      (response) => handleIncomingPushNotification(response)
    );

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, [hasExpoPushToken, navigation, user, isLoggedIn]);

  function handleIncomingPushNotification(response) {
    console.log("responseListener response", response);
    const data = response?.notification?.request?.content?.data;
    if (data) {
      const { type, threadId, toUserId, toUserName, fromUserId, fromUser } = data;
      switch (type) {
        case "chat-message": {
          const params = threadId.split("_");
          const refundId = params.length === 3 ? params[2] : null;
          dispatch(changeMessagesTab(MessagesTab.Messages));
          dispatch({ type: actionTypes.SET_CURRENT_THREAD_ID, payload: threadId });
          dispatch({
            type: actionTypes.SET_CURRENT_CHAT,
            payload: {
              threadId,
              toUser: fromUser,
              refundId
            }
          });
          navigation.navigate("Messages");
          if (fromUser?.id) {
            navigation.navigate("ChatScreen", {
              id: threadId
            });
          }

          return;
        }

        default: {
          dispatch(changeMessagesTab(MessagesTab.Notifications));
          navigation.navigate("Messages");
        }
      }
    }
  }
}
