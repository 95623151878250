import { actionTypes } from "../actions/action-types";

const initialState = {
  activeTab: 1
};

const activityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_ACTIVITY_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: payload
      };
    }
    case actionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default activityReducer;
