import { MaterialCommunityIcons } from "@expo/vector-icons";
import React from "react";
import styled, { useTheme } from "styled-components/native";

const StyledSendButton = styled.TouchableOpacity`
  margin: auto 16px;
`;

const SendButton = (props) => {
  const theme = useTheme();

  const value = props.text.trim();
  if (!value) {
    return null;
  }
  return (
    <StyledSendButton onPress={() => props.onSend({ text: value }, true)}>
      <MaterialCommunityIcons
        name="send-outline"
        size={24}
        color={theme.palette.button.primary.color}
      />
    </StyledSendButton>
  );
};

export default SendButton;
