import { useSelector } from "react-redux";
import { useMemo } from "react";

export default function useMessagesCount() {
  const threadIdToUnreadCount = useSelector(
    (state) => state.messages.threadIdToUnreadCount
  );
  const unreadCount = useSelector((state) => state.messages.unreadCount);

  const totalCount = useMemo(() => {
    let count = 0;

    if (threadIdToUnreadCount) {
      const keys = Object.keys(threadIdToUnreadCount);
      if (keys.length > 0) {
        keys.forEach((key) => {
          count += threadIdToUnreadCount[key];
        });
      }
    } else if (unreadCount !== null) {
      count = unreadCount;
    }
    return count;
  }, [unreadCount, threadIdToUnreadCount]);

  console.log("unreadCount ", unreadCount);
  return unreadCount;
}
