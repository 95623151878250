import React from "react";
import { useTheme } from "styled-components/native";
import { Bubble } from "react-native-gifted-chat";

export default function CustomBubble(props) {
  const theme = useTheme();
  const isImage = !!props.currentMessage?.image;
  return (
    <Bubble
      {...props}
      wrapperStyle={{
        right: {
          backgroundColor: isImage
            ? "transparent"
            : theme.palette.chat.right.backgroundColor
        },
        left: {
          backgroundColor: isImage
            ? "transparent"
            : theme.palette.chat.left.backgroundColor
        }
      }}
      textStyle={{
        right: {
          color: theme.palette.chat.right.color
        },
        left: {
          color: theme.palette.chat.left.color
        }
      }}
      bottomContainerStyle={{
        right: {
          color: theme.palette.chat.right.color
        },
        left: {
          color: theme.palette.chat.left.color
        }
      }}
      tickStyle={{
        right: {
          color: theme.palette.chat.right.color
        },
        left: {
          color: theme.palette.chat.left.color
        }
      }}
    />
  );
}
