import React from "react";
import { Button, Image, View, Platform } from "react-native";
import GeneralItem, { generalItemStyle } from "./general-item";

export default function ImageItem({ url, onDelete, ...rest }) {
  return (
    <GeneralItem onDelete={() => onDelete(url)} isClearButton {...rest}>
      <Image source={{ uri: url }} style={generalItemStyle} />
    </GeneralItem>
  );
}
