import useCardPriceTitle from "./use-card-price-title";
import { useTranslation } from "react-i18next";

export default function useShareMessage({ item = {} }) {
  const { title, storeName } = item;
  const [formattedPrice, formattedRefundAmount] = useCardPriceTitle({ item });
  const { t } = useTranslation();

  const shareMessage = item
    ? `${title}\n${t("Total amount")} ${formattedRefundAmount} ${t(
        "selling for"
      )} ${formattedPrice}\n${storeName}`
    : null;

  return shareMessage;
}
