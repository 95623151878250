import React from "react";
import { View } from "react-native";
import styled, { useTheme } from "styled-components/native";

export default function Separator({ marginTop = 0, marginBottom = 0, ...rest }) {
  const theme = useTheme();
  return (
    <View
      style={{
        borderBottomColor: theme.palette.separator,
        borderBottomWidth: 1,
        width: "100%",
        marginTop,
        marginBottom,
        paddingTop: 0,
        paddingBottom: 0
      }}
      {...rest}
    />
  );
}
