import React from "react";
import RefundsCardsList from "./refunds-cards-list";
import { StyledLayout } from "../../components/styles/screen-layout";
import styled from "styled-components/native";
import HomeHeader from "./home-header";
import { MenuButtons } from "../home/menu-buttons";
import { isIos, isWeb } from "../../utils/core-utils";

const StyledView = styled(StyledLayout)`
  padding: 0 16px;
  margin-top: 0px;
`;

export default function RefundsList(props) {
  return (
    <StyledView>
      <HomeHeader navigation={props.navigation} />
      <RefundsCardsList navigation={props.navigation} />
      <MenuButtons />
    </StyledView>
  );
}
