import React, { useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import styled from "styled-components/native";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import useTheme from "../../components/styles/use-theme";
import { Button } from "react-native-elements";
import Loader from "../../components/loaders/loader";
import * as authActions from "../../redux/thunks/auth-thunk";
import actionTypes from "../../redux/actions/action-types";
import Errors from "../../components/errors";
import { useTranslation } from "react-i18next";
import LinkButton from "../../components/basic/buttons/link-button";
import BaseText from "../../components/basic/base-text";
import InputField from "../../components/basic/input-field";
import IconButton from "../../components/basic/buttons/icon-button";
import AppLogo from "../../components/basic/icons/app-logo";
import { SOMETHING_WRONG } from "../../utils/errors-utils";
import { forgotPasswordSchema } from "../../utils/validations";
import Separator from "../../components/basic/separator";
import { StyledAccountPage, StyledButtonWithLabel } from "./account-styles";
import { marginRightLeft } from "../../components/styles/global-styles";
import AppName from "../../components/basic/icons/app-name";

const StyledImageContainer = styled.View`
  display: flex;
  align-items: center;
  margin: 20px auto;
`;

const StyledRegisterText = styled(BaseText)`
  ${marginRightLeft(8)}
`;

const StyledForm = styled.View`
  margin: 20px;
  background-color: ${(props) => props.theme.palette.background.primary};
  padding: 20px;
  border-radius: 10px;
`;

const StyledInputRow = styled(InputField)``;

export default function ForgotPassword(props) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.auth?.errors);
  const { t } = useTranslation();

  const theme = useTheme();
  if (isLoading) {
    return <Loader />;
  }

  const openHome = () => {
    props.navigation.navigate("Home");
  };

  const openLogin = () => {
    props.navigation.navigate("Login");
  };

  const openResetPassword = () => {
    props.navigation.navigate("ResetPassword", { code: "" });
  };

  return (
    <StyledAccountPage
      style={{
        flex: 1
      }}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <Formik
        initialValues={{
          email: ""
        }}
        validationSchema={forgotPasswordSchema}
        onSubmit={(values) => {
          setIsLoading(true);
          if (errors) {
            dispatch({
              type: actionTypes.AUTH_API_FAIL,
              payload: { errors: null }
            });
          }

          dispatch(authActions.sendForgotPasswordLink(values))
            .then(async (result) => {
              if (result.success) {
                openResetPassword();
              } else {
                dispatch({
                  type: actionTypes.AUTH_API_FAIL,
                  payload: { errors: result.errors }
                });
                console.error(SOMETHING_WRONG, result.errors);
              }
            })
            .catch((e) => {
              console.error(e);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
      >
        {(props) => (
          <View
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <StyledForm>
              <IconButton onPress={openHome}>
                <StyledImageContainer>
                  <AppName color={theme.palette.icons.active} size={50} />
                </StyledImageContainer>
              </IconButton>

              <Errors errors={errors} />

              <View style={styles.formGroup}>
                <StyledInputRow
                  title={t("Email")}
                  name="email"
                  multiline={false}
                  props={props}
                  keyboardType="email-address"
                />
              </View>
              <View style={styles.buttonContainer}>
                <Button
                  buttonStyle={{
                    backgroundColor: theme.palette.button.primary.color,
                    color: theme.palette.button.primary.text
                  }}
                  title={t("Send reset link")}
                  onPress={props.handleSubmit}
                />
              </View>
              <StyledButtonWithLabel>
                <StyledRegisterText>{t("Enter")}</StyledRegisterText>
                <LinkButton text={t("Code")} onPress={openResetPassword} />
              </StyledButtonWithLabel>
              <Separator />
              <StyledButtonWithLabel>
                <StyledRegisterText>{t("Back to")}</StyledRegisterText>
                <LinkButton text={t("Login")} onPress={openLogin} />
              </StyledButtonWithLabel>
            </StyledForm>
          </View>
        )}
      </Formik>
    </StyledAccountPage>
  );
}

const styles = StyleSheet.create({
  formGroup: {
    width: "100%"
  },
  label: {
    marginVertical: 10
  },
  buttonContainer: {
    marginTop: 20
  },
  button: {
    borderRadius: 100
  },
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
});
