import { ENV_DEV } from "./dev";
import { ENV_PROD } from "./prod";
import Constants from "expo-constants";

const IS_PROD = true;

export let ENV = {};
const NODE_ENV = process.env.NODE_ENV;
console.log("NODE_ENV ", NODE_ENV);
console.log("__DEV__ ", __DEV__);
console.log("Constants.manifest.releaseChannel ", Constants.manifest.releaseChannel);

if (IS_PROD) {
  ENV = ENV_PROD;
} else {
  if (__DEV__) {
    ENV = ENV_DEV;
  } else {
    ENV = ENV_PROD;
  }
}

export const SERVER_URL = ENV.SERVER_URL;
