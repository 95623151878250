import React, { useState } from "react";
import { Platform, View } from "react-native";
import moment from "moment";
import styled from "styled-components/native";
import BaseText from "../base-text";
import { AntDesign } from "@expo/vector-icons";
import BaseRow from "../rows/base-row";

const DATE_FORMAT = "DD/MM/YYYY";

const StyledContainer = styled(BaseRow)`
  ${(props) =>
    Platform.OS !== "ios" && Platform.OS !== "android" && "cursor: pointer"};
`;

const StyledIcon = styled(AntDesign)`
  color: ${(props) => props.theme.palette.link.color};
  margin-left: 8px;
`;

const StyledDate = styled(BaseText)`
  color: ${(props) => props.theme.palette.link.color};
`;

export default function DatePickerButton({ date, onPress }) {
  const selectedDate = date
    ? moment(date).format(DATE_FORMAT)
    : DATE_FORMAT.toLowerCase();
  return (
    <StyledContainer>
      <StyledDate onPress={onPress}>{selectedDate}</StyledDate>
      <StyledIcon onPress={onPress} name="calendar" size={24} color="black" />
    </StyledContainer>
  );
}
