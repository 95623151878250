import { SERVER_URL } from "../../config/config";
import { showError } from "../actions/core-actions";
import { restClient } from "./fetch-thunk";
import { actionTypes } from "../actions/action-types";

export const METADATA_API = `${SERVER_URL}/api/user-metadata`;

export const updateMetadata = ({ userId, platform }) => {
  return async (dispatch) => {
    try {
      console.log("updateMetadata ", userId, platform);

      const url = `${METADATA_API}/${userId}`;
      const result = await dispatch(
        restClient.putAPI({
          url,
          body: {
            platform
          }
        })
      );
      if (result) {
        dispatch({
          type: actionTypes.FETCH_USER_METADATA,
          payload: result
        });
      }
      console.log("updateMetadata result ", result);
    } catch (e) {
      console.error("failed to updateMetadata, ", userId, platform, e);
      dispatch(showError("failed to updateMetadata " + e.message));
    }
  };
};

export const getMetadata = ({ userId }) => {
  return async (dispatch) => {
    try {
      console.log("getMetadata ", userId);

      const url = `${METADATA_API}/${userId}`;
      const result = await dispatch(restClient.getAPI(url));
      dispatch({
        type: actionTypes.FETCH_USER_METADATA,
        payload: result
      });
      console.log("getMetadata result ", result);
    } catch (e) {
      console.error("failed to getMetadata, ", userId, e);
      dispatch(showError("failed to getMetadata " + e.message));
    }
  };
};
