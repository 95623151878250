import React from "react";
import { Platform, TouchableOpacity } from "react-native";

export default function IconButton({ children, onPress, disabled, ...rest }) {
  return (
    <TouchableOpacity onPress={onPress} {...rest}>
      {children}
    </TouchableOpacity>
  );
}
