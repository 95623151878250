import React from "react";
import { isWeb } from "../../utils/core-utils";
import { WebLightbox } from "../../components/lightbox/web-lightbox";
import { MessageImage } from "react-native-gifted-chat";

export const ChatImage = (props) => {
  if (isWeb()) {
    return <WebLightbox images={[props.currentMessage.image]} />;
  } else {
    return <MessageImage {...props} />;
  }
};
