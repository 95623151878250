import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components/native";
import { currenciesItems } from "../../utils/currency-utils";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../basic/dropdown";

const StyledDropdown = styled(Dropdown)`
  color: ${(props) => props.theme.palette.font.primary};
  /* font-size: 14px !important; */
  /* 
  svg {
    color: ${(props) => props.theme.palette.font.primary};
  } */
`;
export default function CurrencyDropdown({
  onValueChange,
  value = "",
  name,
  ...rest
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledDropdown
      items={currenciesItems}
      onValueChange={onValueChange}
      value={value}
      textInputProps={{ name }}
      {...rest}
    />

    // <Picker
    //   items={currenciesItems}
    //   onChange={onValueChange}
    //   value={value}
    //   textInputProps={{ name }}
    //   {...rest}
    // />
  );
}
