import React from "react";
import styled from "styled-components/native";
import { useTranslation } from "react-i18next";
import BaseText from "../basic/base-text";
import FavoriteButton from "./favorite-button";
import { flexDirection, spaceBetweenFlexRow } from "../styles/global-styles";

import useCardPriceTitle from "../../hooks/use-card-price-title";

const StyledContent = styled.View``;

const StyledTitleContainer = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${flexDirection}
`;

const StyledPrice = styled(BaseText)`
  font-size: 16px;
  color: ${(props) => props.theme.palette.price.red};
`;

const StyledRefundAmount = styled(StyledPrice)`
  color: ${(props) => props.theme.palette.price.green};
`;

const StyledLabel = styled(BaseText)`
  margin-left: 8px;
  margin-right: 8px;
`;

const StyledPercentage = styled(BaseText)`
  color: ${(props) =>
    props.discountPercentage >= 0
      ? props.theme.palette.price.green
      : props.theme.palette.price.red};
`;

const StyledFavoriteButton = styled(FavoriteButton)``;

const StyledFavoriteContainer = styled.View`
  display: flex;
  flex-direction: row;
  ${spaceBetweenFlexRow}
`;

export default function CardPriceTitle({ item, isFavorite }) {
  const { t } = useTranslation();

  const [
    formattedPrice,
    formattedRefundAmount,
    discountPercentage,
    discountDisplay
  ] = useCardPriceTitle({ item });

  return (
    <StyledContent>
      <StyledTitleContainer oneLine>
        <StyledPrice>{formattedRefundAmount}</StyledPrice>
        <StyledLabel>{t("selling for")}</StyledLabel>
        <StyledRefundAmount>{formattedPrice}</StyledRefundAmount>
      </StyledTitleContainer>
      <StyledFavoriteContainer>
        {discountPercentage && (
          <StyledPercentage discountPercentage={discountPercentage}>
            {discountDisplay}
          </StyledPercentage>
        )}
        {isFavorite && <StyledFavoriteButton item={item} />}
      </StyledFavoriteContainer>
    </StyledContent>
  );
}
