import { actionTypes } from "./action-types";
import { SERVER_URL } from "../../config/config";
import { NOTIFICATION_TYPE } from "../reducers/core-reducer";
import { getAPI } from "../thunks/fetch-thunk";
import { INFO_MODAL } from "../../components/modals/modal-types";

export const FEATURES_API = `${SERVER_URL}/api/features`;

const DEFAULT_ERROR_MESSAGE = "Something went wrong, please try again";

export const fetchFeatures = () => {
  return async (dispatch) => {
    try {
      console.log("fetchFeatures");

      const result = await dispatch(getAPI(FEATURES_API));

      dispatch({
        type: actionTypes.FETCH_FEATURES,
        payload: { features: result }
      });
    } catch (e) {
      console.error("failed to fetch features ", e);
      dispatch(showError(e.message));
    }
  };
};

export const toggleLoader = ({ loading }) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.TOGGLE_APP_LOADER,
      payload: { loading }
    });
  };
};

export const setAppLoaded = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_APP_LOADED,
      payload: true
    });
  };
};

export const showError = (message) => {
  return async (dispatch, getState) => {
    const isDefaultMessage = getState().core?.features?.isDefaultErrorMessage;
    const msg = isDefaultMessage ? DEFAULT_ERROR_MESSAGE : message;
    dispatch(showNotification({ message: msg, type: NOTIFICATION_TYPE.ERROR }));
  };
};

export const showNotification = ({ message, type }) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_NOTIFICATION,
      payload: { message, type }
    });
  };
};

export const showSuccess = ({ message }) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_NOTIFICATION,
      payload: { message, type: NOTIFICATION_TYPE.SUCCESS }
    });
  };
};

export const showWarning = ({ message }) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_NOTIFICATION,
      payload: { message, type: NOTIFICATION_TYPE.WARNING }
    });
  };
};

export const showInfo = ({ message }) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_NOTIFICATION,
      payload: { message, type: NOTIFICATION_TYPE.INFO }
    });
  };
};

export const hideNotification = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.HIDE_NOTIFICATION
    });
  };
};

export const toggleInfoModal = ({ open }) => {
  return async (dispatch) => {
    dispatch(toggleModal({ open, modal: INFO_MODAL }));
  };
};

export const toggleModal = ({ open, modal, data }) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.TOGGLE_MODAL,
      payload: { open, modal, data }
    });
  };
};
