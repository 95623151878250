import * as React from "react";
import { StyleSheet, Text, View, Dimensions, Platform } from "react-native";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  LoadScript,
  Circle
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../../components/location/constants";
import { useEffect } from "react";

export const LATITUDE_DELTA = 0.1;
export const LONGITUDE_DELTA = 0.1;

const ZOOM_VALUE = 13;

export default function GoogleWebMap({ location, height = 350, ...rest }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY
  });

  const [map, setMap] = React.useState(null);

  const center = {
    ...location,
    latitudeDelta: 0.015 * 5,
    longitudeDelta: 0.0121 * 5
  };

  useEffect(() => {
    if (map) {
      map.setZoom(ZOOM_VALUE);
      map.panTo(center);
    }
  }, [map, center]);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      defaultCenter={center}
      mapContainerStyle={{
        // width: 370,
        height: height,
        borderRadius: 8
      }}
      center={center}
      zoom={ZOOM_VALUE}
      onLoad={onLoad}
      onUnmount={onUnmount}
      defaultZoom={ZOOM_VALUE}
    >
      <>
        <Marker position={location} center={location} />
        <Circle center={location} radius={1000} />
      </>
    </GoogleMap>
  ) : (
    <></>
  );
}
