import { actionTypes } from "../actions/action-types";

const initialState = {
  items: [],
  isLoading: false,
  isLoaded: false,
  unreadCount: null
};

const buyRequestsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_BUY_REQUESTS: {
      return {
        ...state,
        items: payload,
        isLoading: false,
        isLoaded: true
      };
    }
    case actionTypes.SET_BUY_REQUESTS_LOADING: {
      return {
        ...state,
        isLoading: payload
      };
    }
    case actionTypes.UPDATE_USER_REQUEST: {
      const { id, status, read } = payload;
      const newItems = state.items.map((item) => {
        if (item.id == id) {
          return {
            ...item,
            status,
            read
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        items: newItems
      };
    }
    case actionTypes.SET_REFUND_REQUESTS_UNREAD_COUNT: {
      return {
        ...state,
        unreadCount: payload
      };
    }
    case actionTypes.LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};

export default buyRequestsReducer;
