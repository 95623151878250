import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../../redux/actions/action-types";
import { Switch } from "native-base";
import { DARK_THEME, LIGHT_THEME } from "../../../components/styles/themes";
import { useColorMode } from "native-base";

export default function ThemeChange(props) {
  const theme = useSelector((state) => state.settings?.theme);
  const dispatch = useDispatch();
  const { toggleColorMode } = useColorMode();

  const onThemeChange = () => {
    const newTheme = theme === DARK_THEME ? LIGHT_THEME : DARK_THEME;
    toggleColorMode();
    dispatch({
      type: actionTypes.THEME_SWITCH,
      payload: { theme: newTheme }
    });
  };

  return (
    <Switch
      colorScheme="blue"
      onToggle={onThemeChange}
      isChecked={theme === DARK_THEME}
    />
  );
}
