import React, { useEffect, useState } from "react";
import { StyleSheet, Text, Platform } from "react-native";
import styled, { useTheme } from "styled-components/native";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18next";
import { actionTypes } from "../../../redux/actions/action-types";
import { Dropdown } from "../../../components/basic/dropdown";

const languages = [
  {
    label: "English",
    value: "en"
  },
  {
    label: "Hebrew",
    value: "he"
  },
  {
    label: "French",
    value: "fr"
  },
  {
    label: "Russian",
    value: "ru"
  }
];

export default function LanguagePicker(props) {
  const language = useSelector((state) => state.settings?.language);
  const dispatch = useDispatch();
  const theme = useTheme();
  console.log("language ", language);
  const onChange = (value) => {
    console.log("onChange ", value);

    i18n.changeLanguage(value, () => {
      dispatch({
        type: actionTypes.LANG_SWITCH,
        payload: { language: value }
      });
    });
  };

  return (
    <Dropdown
      items={languages}
      onValueChange={onChange}
      value={language}
      width={100}
    />
  );
}
