import BackButton from "../components/basic/buttons/back-button";
import DefaultHeader from "./default-header";
import { headerHeight } from "./nav-style";
import React from "react";
import { useTheme } from "styled-components/native";
import { isWeb } from "../utils/core-utils";
import HeaderWithBackButton from "./header-with-back-button";

export const headerOptions = (title) => ({ navigation, route }) => {
  const theme = useTheme();

  return {
    headerLeft: (props) => (
      <BackButton
        onPress={() => navigation.goBack(null)}
        style={{ marginLeft: 16 }}
      />
    ),
    headerTitle: (props) => <DefaultHeader text={title} {...props} />,
    headerStyle: {
      height: headerHeight.primary,
      backgroundColor: theme.palette.background.primary,
      borderBottomColor: "transparent"
    }
  };
};

export const headerResponsiveOptions = (title) => ({ navigation, route }) => {
  const theme = useTheme();

  const options = isWeb()
    ? {
        headerTitle: (props) => <HeaderWithBackButton text={title} {...props} />,
        headerStyle: {
          height: headerHeight.primary,
          backgroundColor: theme.palette.background.primary,
          borderBottomColor: "transparent"
        }
      }
    : {
        headerLeft: (props) => (
          <BackButton
            onPress={() => navigation.goBack(null)}
            style={{ marginLeft: 16 }}
          />
        ),
        headerTitle: (props) => <DefaultHeader text={title} {...props} />,
        headerStyle: {
          height: headerHeight.primary,
          backgroundColor: theme.palette.background.primary,
          borderBottomColor: "transparent"
        }
      };

  return options;
};
