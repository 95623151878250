import React from "react";
import styled from "styled-components/native";
import Title from "../../components/basic/title";
import CardPriceTitle from "../../components/refund/refund-card-price-title";
import { DEFAULT_CARD_PLACEHOLDER } from "../../utils/images-utils";
import { TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { REFUND_DELETED } from "../../components/refund/refund-status";

const StyledContainer = styled.View`
  display: flex;
  padding: 8px;
`;

const StyledCard = styled.View`
  display: flex;
`;

const StyledContent = styled.View`
  margin-left: 8px;
`;

const StyledMessage = styled.View`
  display: flex;
  flex-direction: row;
`;

const StyledImage = styled.Image`
  height: 80px;
  width: 80px;
  border-radius: 7px;
`;

const StyledTitle = styled(Title)`
  font-weight: 600;
  font-size: 14px;
  margin-right: auto;
`;

export default function ChatRefundMessage({ refundId, ...rest }) {
  const navigation = useNavigation();
  const refund = useSelector((state) => state.refunds.itemsById[refundId]);

  if (!refund || refund.deleted === 1 || refund.status === REFUND_DELETED) {
    //TODO remove
    return null;
  }

  const { title, images } = refund;
  const uri = images.length > 0 ? images[0] : DEFAULT_CARD_PLACEHOLDER;

  const viewRefund = () => {
    navigation.navigate("RefundDetails", {
      id: refundId
    });
  };

  return (
    <StyledContainer {...rest}>
      <TouchableOpacity onPress={viewRefund}>
        <StyledCard>
          <StyledMessage>
            <StyledImage
              source={{
                uri
              }}
            />
            <StyledContent>
              <CardPriceTitle item={refund} />
              <StyledTitle oneLine>{title}</StyledTitle>
            </StyledContent>
          </StyledMessage>
        </StyledCard>
      </TouchableOpacity>
    </StyledContainer>
  );
}
