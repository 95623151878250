import { actionTypes } from "./action-types";

export const changeMessagesTab = (index) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_MESSAGES_ACTIVE_TAB,
      payload: index
    });
  };
};

export const setMessagesIsDirty = (isDirty) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_MESSAGES_DIRTY,
      payload: isDirty
    });
  };
};
