import React from "react";
import styled from "styled-components/native";
import BaseText from "./base-text";

const Title = styled(BaseText)`
  color: ${(props) => props.theme.palette.font.tertiary};
  font-size: 16px;
  font-weight: 700;
`;

export default Title;
