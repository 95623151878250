import React from "react";
import { TouchableOpacity, View, ImageBackground } from "react-native";
import styled from "styled-components/native";
import { useTranslation } from "react-i18next";
import Title from "../basic/title";
import { getImageData } from "../../utils/images-utils";
import { REFUND_AVAILABLE } from "./refund-status";

const StyledImage = styled.ImageBackground`
  border-radius: 7px;
  width: 100%;
  height: 100%;
`;

const StyledSoldStatus = styled(Title)`
  margin-top: 0;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  color: ${(props) => props.theme.palette.price.red};
  background-color: ${(props) => props.theme.palette.background.primary};
  margin-top: auto;
  width: 100%;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-color: ${(props) => props.theme.palette.separator};
  border-bottom-width: 1px;
  border-left-color: ${(props) => props.theme.palette.separator};
  border-left-width: 1px;
  border-right-color: ${(props) => props.theme.palette.separator};
  border-right-width: 1px;
`;

export default function ItemImage({ images, status, ...rest }) {
  const { t } = useTranslation();
  const imageData = getImageData(images);
  return (
    <StyledImage source={imageData} imageStyle={{ borderRadius: 8 }} {...rest}>
      {status && status !== REFUND_AVAILABLE && (
        <StyledSoldStatus oneLine>{t(status)}</StyledSoldStatus>
      )}
    </StyledImage>
  );
}
