import { actionTypes } from "./action-types";

export const changeActivityTab = (index) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_ACTIVITY_ACTIVE_TAB,
      payload: index
    });
  };
};
