import React, { useEffect } from "react";
import styled from "styled-components/native";
import { StyledLayout } from "../../../components/styles/screen-layout";
import { useTranslation } from "react-i18next";
import RefundRequestMessage from "../refund-request-message";
import HeaderWithBackButton from "../../../navigation/header-with-back-button";
import { actionTypes } from "../../../redux/actions/action-types";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserNotificationRequest,
  fetchUserNotifications
} from "../../../redux/thunks/notifications-thunk";

const StyledView = styled(StyledLayout)`
  padding: 16px;
`;

export default function RequestScreen({ route, navigation }) {
  console.log("RequestScreen item ", route.params);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = route?.params || {};

  const currentItem = useSelector((state) => state.notifications?.currentItem);
  const item = currentItem?.data;

  useEffect(() => {
    if (!item && id) {
      dispatch(fetchUserNotificationRequest({ id }));
    }
  }, [id, item]);

  if (!item) {
    return null;
  }

  const onBack = () => {
    dispatch({
      type: actionTypes.SET_CURRENT_NOTIFICATION,
      payload: null
    });
    return true;
  };

  return (
    <StyledView>
      <HeaderWithBackButton text={t("Request")} onBack={onBack} />
      <RefundRequestMessage item={item} />
    </StyledView>
  );

  // return <RefundRequestMessage item={item} />;
}
