import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../redux/actions/categories-actions";
import { fetchSettings } from "../redux/thunks/settings-thunk";
import { fetchFavorites } from "../redux/thunks/favorites-thunk";
import {
  fetchFeatures,
  hideNotification,
  setAppLoaded
} from "../redux/actions/core-actions";
import { fetchUser } from "../redux/thunks/auth-thunk";
import { fetchUserRefundRequests } from "../redux/thunks/user-requests-thunk";
import { fetchUserNotificationsUnreadCount } from "../redux/thunks/notifications-thunk";
import { fetchUserExpoPushTokens } from "../redux/thunks/push-token-thunk";
import {
  fetchMessagesUnreadCount,
  fetchMessagesUsersData
} from "../redux/thunks/messages-thunk";
import { fetchUserRefunds } from "../redux/thunks/refunds-thunk";
import useLocationPermissions from "./use-location-permissions";
import { fetchCustomerService } from "../redux/thunks/core-thunk";
import { fetchBuyRequestsUnreadCount } from "../redux/thunks/buy-requests-thunk";
import { useNotificationAlert } from "./use-notification-alert";
import { useInitLanguage } from "./use-init-language";
import { getMetadata } from "../redux/thunks/user-metadata-thunk";

const IS_LOGS = true;

export default function useInitializeData() {
  const userId = useSelector((state) => state.auth?.user?.id);
  const favoritesIsLoaded = useSelector((state) => state.favorites?.isLoaded);
  const settingsIsLoaded = useSelector((state) => state.settings.isLoaded);
  const isUserLoaded = useSelector((state) => state.auth?.isLoaded);
  const dispatch = useDispatch();

  const items = useSelector((state) => state.messages.items) || [];
  const isLoaded = useSelector((state) => state.messages.isLoaded);
  console.log("Main ", userId);
  useNotificationAlert();
  useLocationPermissions();
  useInitLanguage();

  useEffect(() => {
    if (!IS_LOGS) {
      console.log = () => {};
    }
    dispatch(hideNotification());
    dispatch(fetchUser());
    dispatch(fetchFeatures());
  }, []);

  useEffect(() => {
    if (isUserLoaded) {
      dispatch(setAppLoaded());

      if (userId) {
        dispatch(fetchCategories());
        dispatch(fetchCustomerService());
        dispatch(fetchUserRefundRequests());
        dispatch(fetchBuyRequestsUnreadCount());
        dispatch(fetchUserNotificationsUnreadCount());
        dispatch(fetchMessagesUnreadCount());
        dispatch(fetchUserRefunds());
        dispatch(getMetadata({ userId }));
      }

      //  // setInterval(() => {
      //     dispatch(fetchBuyRequestsUnreadCount());
      //     dispatch(fetchUserNotificationsUnreadCount());
      //     dispatch(fetchMessagesUnreadCount());
      // //  }, 60000);
    }
  }, [isUserLoaded, userId]);

  useEffect(() => {
    if (userId) {
      if (!settingsIsLoaded) {
        dispatch(fetchSettings({ userId }));
      }
      if (!favoritesIsLoaded) {
        dispatch(fetchFavorites({ userId }));
      }
      dispatch(fetchUserExpoPushTokens({ userId }));
    }
  }, [userId, isUserLoaded]);

  useEffect(() => {
    if (items && !isLoaded) {
      dispatch(fetchMessagesUsersData());
    }
  }, [items, isLoaded]);
}
