import React from "react";
import { DevSettings, View } from "react-native";
import RNRestart from "react-native-restart"; // Import package from node modules
import styled, { useTheme } from "styled-components/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import LanguagePicker from "./language-picker";
import LinkButton from "../../../components/basic/buttons/link-button";
import ThemeChange from "./theme-change";
import { HE_LANG } from "../../../../i18n";
import BaseText from "../../../components/basic/base-text";
import { spaceBetweenFlexRow } from "../../../components/styles/global-styles";
import { useDispatch, useSelector } from "react-redux";
import {
  AntDesign,
  Fontisto,
  MaterialCommunityIcons,
  MaterialIcons
} from "@expo/vector-icons";
import Separator from "../../../components/basic/separator";
import { updateSettings } from "../../../redux/thunks/settings-thunk";
import Date from "../../../components/basic/date";
import { StyledScrollLayout } from "../../../components/styles/screen-layout";
import SelectCurrency from "./select-currency";
import { isWeb } from "../../../utils/core-utils";
import { toggleModal } from "../../../redux/actions/core-actions";
import { DELETE_ACCOUNT_MODAL } from "../../../components/modals/modal-types";
import { DeleteAccountModal } from "./delete-account-modal";
import { actionTypes } from "../../../redux/actions/action-types";

const StyledView = styled(StyledScrollLayout)`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 48px 16px 16px 16px;
  background-color: ${(props) => props.theme.palette.background.primary};
  margin-top: ${isWeb() ? 0 : 16}px;
`;

const StyledTitle = styled(BaseText)`
  font-size: 16px;
  font-weight: 700;
`;

const StyledRow = styled.View`
  margin-bottom: 12px;
  width: 100%;
  color: ${(props) => props.theme.palette.font.primary};

  ${spaceBetweenFlexRow}
`;

const LogoutRow = styled(StyledRow)`
  margin-top: 48px;
`;

const StyledHeader = styled.View`
  width: 100%;
  margin-bottom: 32px;
  ${spaceBetweenFlexRow}
`;

export default function SettingsScreen(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.user);
  const theme = useTheme();
  const settings = useSelector((state) => state.settings);
  const settingsIsDirty = useSelector((state) => state.settings.isDirty);
  const { lastName, firstName, role, createdAt } = user;
  const open = useSelector((state) => state.core.modals?.deleteAccount?.open);

  const logout = async () => {
    try {
      await AsyncStorage.removeItem("token");
      dispatch({
        type: actionTypes.LOGOUT
      });
      dispatch({
        type: actionTypes.CLEAR_USER
      });
      props.navigation.navigate("Home");
    } catch (error) {
      console.error("failed to remove token ", error);
    }
  };

  const handleSave = () => {
    console.log("save settings ", JSON.stringify(settings));
    if (settingsIsDirty && settings.id) {
      dispatch(
        updateSettings({
          id: settings.id,
          language: settings.language,
          theme: settings.theme,
          currency: settings.currency,
          location: settings.location
        })
      );
    }
  };

  const onToggleDeleteAccount = () => {
    dispatch(toggleModal({ open: !open, modal: DELETE_ACCOUNT_MODAL }));
  };

  return (
    <StyledView
      contentContainerStyle={{
        alignItems: theme.language === HE_LANG ? "flex-end" : "flex-start"
      }}
    >
      <StyledHeader>
        <>
          <StyledRow>
            <StyledTitle>{`${t("Hello")} ${firstName} ${lastName}`}</StyledTitle>
            <LinkButton
              onPress={handleSave}
              text={t("Save")}
              disabled={!settingsIsDirty}
            />
          </StyledRow>
          {createdAt && (
            <View>
              <BaseText>{`User since `}</BaseText>
              <Date>{createdAt}</Date>
            </View>
          )}
        </>
      </StyledHeader>
      <Separator marginBottom={24} />
      <StyledRow>
        <LinkButton
          onPress={() => props.navigation.navigate("Activity")}
          text={t("Your Activities")}
        />
        <LinkButton onPress={() => props.navigation.navigate("Activity")}>
          <MaterialCommunityIcons
            name="credit-card-refund"
            size={24}
            color={theme.palette.link.color}
          />
        </LinkButton>
      </StyledRow>
      {/* <StyledRow>
        <LinkButton
          onPress={() => props.navigation.navigate("UserRequestedRefunds")}
          text={t("Your Refund Requests")}
        />
        <LinkButton onPress={() => props.navigation.navigate("UserRequestedRefunds")}>
          <MaterialCommunityIcons
            name="credit-card-refund"
            size={24}
            color={theme.palette.link.color}
          />
        </LinkButton>
      </StyledRow> */}
      {/* <StyledRow>
        <LinkButton
          onPress={() => props.navigation.navigate("History")}
          text={t("History")}
        />
        <StyledMaterialCommunityIcon name="history" size={24} />
      </StyledRow> */}
      <Separator marginTop={16} marginBottom={16} />
      <StyledHeader>
        <StyledTitle>{t("Account Settings")}</StyledTitle>
      </StyledHeader>
      <StyledRow>
        <LinkButton
          onPress={() => props.navigation.navigate("EditUser")}
          text={t("Edit user")}
        />
        <LinkButton onPress={() => props.navigation.navigate("EditUser")}>
          <AntDesign name="edit" size={24} color={theme.palette.link.color} />
        </LinkButton>
      </StyledRow>
      <StyledRow>
        <BaseText>{t("Dark theme")}</BaseText>
        <ThemeChange />
      </StyledRow>
      <StyledRow>
        <BaseText>{t("Language")}</BaseText>
        <LanguagePicker variant="unstyled" hi />
      </StyledRow>
      {/*<StyledRow>*/}
      {/*  <BaseText>{t("Currency")}</BaseText>*/}
      {/*  <SelectCurrency />*/}
      {/*</StyledRow>*/}
      {/*<StyledRow>*/}
      {/*  <LinkButton*/}
      {/*    onPress={() => props.navigation.navigate("About")}*/}
      {/*    text={t("About")}*/}
      {/*  />*/}
      {/*  <LinkButton onPress={() => props.navigation.navigate("About")}>*/}
      {/*    <AntDesign name="infocirlceo" size={20} color={theme.palette.link.color} />*/}
      {/*  </LinkButton>*/}
      {/*</StyledRow>*/}
      <StyledRow>
        <LinkButton
          onPress={() => props.navigation.navigate("ContactUs")}
          text={t("Contact Us")}
        />
        <LinkButton onPress={() => props.navigation.navigate("ContactUs")}>
          <Fontisto name="email" size={24} color={theme.palette.link.color} />
        </LinkButton>
      </StyledRow>
      <StyledRow style={{ marginBottom: 24 }}>
        <LinkButton onPress={onToggleDeleteAccount} text={t("Delete account")} />
        <LinkButton onPress={onToggleDeleteAccount}>
          <MaterialIcons name="delete" size={24} color={theme.palette.link.color} />
        </LinkButton>
      </StyledRow>
      <LogoutRow>
        <LinkButton onPress={logout} text={t("Logout")} />

        <LinkButton onPress={logout}>
          <AntDesign name="logout" size={20} color={theme.palette.link.color} />
        </LinkButton>
      </LogoutRow>

      {role === "admin" && (
        <StyledRow style={{ marginTop: 16 }}>
          <LinkButton
            onPress={() => props.navigation.navigate("AdminScreen")}
            text={t("Admin")}
          />
          <LinkButton onPress={() => props.navigation.navigate("AdminScreen")}>
            <MaterialIcons
              name="admin-panel-settings"
              size={24}
              color={theme.palette.link.color}
            />
          </LinkButton>
        </StyledRow>
      )}

      <View style={{ height: 60 }} />
      <DeleteAccountModal open={open} onClose={onToggleDeleteAccount} />
    </StyledView>
  );
}
