import { useEffect, useState } from "react";
import { fetchRefundById } from "../redux/thunks/refunds-thunk";
import { useDispatch, useSelector } from "react-redux";

export function useUserRefund({ id }) {
  const userRefundsById = useSelector((state) => state.refunds?.itemsById);
  const dispatch = useDispatch();

  const [refund, setRefund] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const userRefund = userRefundsById ? userRefundsById[id] : null;

  useEffect(() => {
    if (id && !refund) {
      if (!userRefund) {
        dispatch(fetchRefundById({ id }));
      } else {
        setRefund(userRefund);
        setIsLoading(false);
      }
    }
  }, [userRefund, id, refund]);

  return [refund, isLoading];
}
