import * as yup from "yup";
import {
  COUPON,
  GIFT_CARD,
  NONE,
  OTHER,
  STORE_REFUND
} from "../../utils/refund-types-utils";

export const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
const re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
const urlValidate = /((https?):\/\/)(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export const formSchema = yup.object({
  title: yup
    .string()
    .required("Title is required")
    .min(3)
    .max(50),
  description: yup.string(), //.min(10),
  price: yup.number().required("Price is required"),
  refundAmount: yup.number().required("Refund Amount is required"),
  categoryId: yup
    .string()
    .nullable()
    .test("equal", "Category is required", function(v) {
      return v !== NONE;
    })
    .required("Category is required"),
  type: yup
    .string()
    .nullable()
    .test("equal", "Card type is required", function(v) {
      return v !== NONE;
    })
    .required("Card type is required"),
  storeName: yup
    .string()
    .min(2)
    .max(50)
    .required("Store name is required"),
  // .when("type", {
  //   is: STORE_REFUND || GIFT_CARD || COUPON,
  //   then: yup.string().required("Store name is required")
  // }),
  storeWebsite: yup.string().when(["type", "storeLocation"], {
    is: (type, storeLocation) => {
      return !storeLocation;
    },
    then: yup
      .string()
      .url()
      .required(`Please select website or location`)
      .label("Path")
  }),
  otherType: yup.string().when(["type"], {
    is: (type) => {
      return type === OTHER;
    },
    then: yup.string().required("Please add more information about card type")
  }),

  // storeWebsite: yup
  //   .string()
  //   .matches(URL, "Url is invalid")
  //   .when(["type", "storeLocation"], {
  //     is: (type, storeLocation) => {
  //       return type === STORE_REFUND && !storeLocation;
  //     },
  //     then: yup.string().required("Please enter store location or website"),
  //   }),
  // storeLocation: yup.object().when(["type", "storeWebsite"], {
  //   is: (type, storeWebsite) => {
  //     return type === STORE_REFUND && !storeWebsite;
  //   },
  //   then: yup.string().required("Please enter store location or website"),
  // }),
  // images: yup
  //   .array()
  //   .min(1, "At least one image required")
  //   .required("At least one image required")
  //   .nullable(),
  expirationDate: yup.date().when("hasExpirationDate", {
    is: true,
    then: yup
      .date()
      .required(
        "Expiration date not selected, please uncheck if has no expiration date"
      )
  }),
  ticketLocation: yup
    .object()
    .nullable()
    .required("Please add ticket location"),
  sellerName: yup.string().when("currentUserSeller", {
    is: false,
    then: yup
      .string()
      .required(
        "If you not a seller, please select seller name, or select that you are the seller"
      )
  }),
  sellerPhone: yup.string().when(["currentUserSeller", "sellerEmail"], {
    is: (currentUserSeller, sellerEmail) => {
      return !currentUserSeller && !sellerEmail;
    },
    then: yup.string().required("Please select seller phone or email")
  })
  // sellerEmail: yup.string().when(["currentUserSeller", "sellerPhone"], {
  //   is: (currentUserSeller, sellerPhone) => {
  //     return !currentUserSeller && !sellerPhone;
  //   },
  //   then: yup.string().required("Please select seller phone or email"),
  // }),
});
