import { actionTypes } from "../../redux/actions/action-types";
import { SERVER_URL } from "../../config/config";
import { showError } from "../actions/core-actions";
import { restClient } from "./fetch-thunk";

export const PUSH_TOKEN_API = `${SERVER_URL}/api/push-tokens`;

export const addUserToken = ({ userId, expoPushToken }) => {
  return async (dispatch) => {
    if (!expoPushToken) {
      console.error(
        "add user expoPushToken userId ",
        userId,
        " expoPushToken ",
        expoPushToken
      );
      return;
    }
    try {
      console.log(
        "add user expoPushToken userId ",
        userId,
        " expoPushToken ",
        expoPushToken
      );
      const result = dispatch(
        restClient.postAPI({
          url: PUSH_TOKEN_API,
          body: {
            userId,
            expoPushToken
          }
        })
      );

      console.log("add user expoPushToken responseData ", result);
      dispatch({
        type: actionTypes.SET_USER_PUSH_TOKENS,
        payload: [expoPushToken]
      });
      return result;
    } catch (e) {
      console.error(
        "failed to add user expoPushToken userId ",
        userId,
        " expoPushToken ",
        expoPushToken,
        e
      );
      dispatch(showError(e.message));
    }
  };
};

export const fetchUserExpoPushTokens = ({ userId }) => {
  return async (dispatch) => {
    try {
      console.log("get user expoPushToken userId ", userId);
      const url = `${PUSH_TOKEN_API}/${userId}`;
      const result = await dispatch(restClient.getAPI(url));
      console.log("get user expoPushToken responseData ", result);

      dispatch({
        type: actionTypes.SET_USER_PUSH_TOKENS,
        payload: result
      });
      return result;
    } catch (e) {
      console.error("failed to get user expoPushToken userId ", userId, e);
      dispatch(showError("failed to get user expoPushToken " + e.message));
    }
  };
};
