import { useSelector } from "react-redux";

export default function useUser() {
  const user = useSelector((state) => state.auth?.user);
  const isLoaded = useSelector((state) => state.auth?.isLoaded);

  const userId = user?.id;
  const isLoggedIn = isLoaded && !!userId;
  const isAnonymous = isLoaded && !isLoggedIn;
  return [isLoggedIn, user, userId];
}
