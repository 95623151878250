import React, { useEffect, useMemo } from "react";
import styled from "styled-components/native";
import { StyledLayout } from "../../../components/styles/screen-layout";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loaders/loader";
import MessagesGenericList from "../messages-generic-list";
import {
  fetchUserRefundsBuyRequests,
  updateReadBuyRequests
} from "../../../redux/thunks/buy-requests-thunk";
import { fetchUserRefunds } from "../../../redux/thunks/refunds-thunk";
import RefundRequestMessage from "../refund-request-message";
import DefaultMessage from "../default-message";
import NoItemsFound from "../../../components/no-items-found";
import { MessagesTab } from "../../../redux/reducers/messages-reducer";
import useBuyRequestsCount from "../../../hooks/use-buy-requests-count";

const StyledView = styled(StyledLayout)`
  padding: 16px 16px 0 16px;
`;

export default function BuyRequests(props) {
  const { t } = useTranslation();
  const items = useSelector((state) => state.buyRequests.items) || [];
  const isLoaded = useSelector((state) => state.buyRequests.isLoaded);
  const isLoading = useSelector((state) => state.buyRequests.isLoading);
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.messages.activeTab);
  const unreadCount = useBuyRequestsCount();

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      dispatch(fetchUserRefundsBuyRequests());
    }
  }, [isLoaded, isLoading]);

  useEffect(() => {
    if (items?.length > 0) {
      dispatch(fetchUserRefunds());
    }
  }, [items]);

  useEffect(() => {
    if (unreadCount > 0 && items?.length > 0) {
      dispatch(updateReadBuyRequests({ items }));
    }
  }, [unreadCount, items]);

  const displayItems = useMemo(() => {
    const res = [];
    items?.forEach((item) => {
      res.push({ ...item, type: "buy-request" });
    });
    return res;
  }, [items]);

  console.log("buy-request activeTab ", activeTab);
  if (activeTab !== MessagesTab.BuyRequests) {
    console.log("buy-request activeTab ", activeTab);

    return null;
  }
  console.log(
    "buy-request isLoading ",
    isLoading,
    " isLoaded ",
    isLoaded,
    " displayItems.length ",
    displayItems.length
  );

  if (isLoading || !isLoaded) {
    return <Loader />;
  }

  if (displayItems.length === 0) {
    return <NoItemsFound text={t("You have no buy requests")} />;
  }

  const renderItem = ({ item, index }) => {
    switch (item.type) {
      case "buy-request":
        return (
          <RefundRequestMessage
            item={item}
            isLast={index === displayItems.length - 1}
            {...props}
          />
        );
      default:
        return (
          <DefaultMessage
            title={item.refundTitle || item.title}
            isLast={index === displayItems.length - 1}
            {...props}
          />
        );
    }
  };
  console.log("Buy requests ", displayItems);

  return (
    <StyledView>
      <MessagesGenericList items={displayItems} renderItem={renderItem} />
    </StyledView>
  );
}
