import React from "react";
import styled from "styled-components/native";
import BaseText from "../../components/basic/base-text";
import { StyleSheet, Text, View, Dimensions } from "react-native";
import { isWeb } from "../../utils/core-utils";
import NativeMap from "./location/native-map";
import BaseRow from "../../components/basic/rows/base-row";
import SpaceBetweenRow from "../../components/basic/rows/space-between-row";

const StyledContainer = styled.View`
  display: flex;
  width: 100%;
`;

const StyledRow = styled(SpaceBetweenRow)`
  margin-bottom: 8px;
`;

export default function DisplayLocation({ label, data, ...rest }) {
  console.log("DisplayLocation ", data);
  if (!data) {
    return null;
  }
  return (
    <StyledContainer {...rest}>
      <StyledRow>
        <BaseText>{label}</BaseText>
        <BaseText>{data?.description}</BaseText>
      </StyledRow>
      <NativeMap
        location={data?.location}
        style={{
          height: "100%",
          width: "100%",
          flex: 1,
          backgroundColor: "#fff",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 8,
          marginTop: 8
        }}
      />
    </StyledContainer>
  );
}
