import React, { useState, useEffect } from "react";
import Login from "./login";
import SettingsScreen from "./settings/settings-screen";
import AsyncStorage from "@react-native-async-storage/async-storage";
import jwtDecode from "jwt-decode";
import Loader from "../../components/loaders/loader";

export default function Account(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const isLoggedIn = user?.id;

  async function loadToken() {
    const token = await AsyncStorage.getItem("token");
    if (token) {
      const data = jwtDecode(token);
      setUser(data);
    } else {
      setUser(null);
    }
  }

  useEffect(() => {
    loadToken()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoggedIn) {
    return <Login {...props} />;
  }
  return <SettingsScreen {...props} user={user} />;
}
