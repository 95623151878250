import React from "react";
import { isWeb } from "../../../utils/core-utils";
import MobileMap from "./mobile-map";
import GoogleWebMap from "./google-web-map";

export default function NativeMap({ location, ...rest }) {
  if (!location) {
    return null;
  }
  if (isWeb()) {
    return <GoogleWebMap location={location} {...rest} />;
  } else {
    return <MobileMap location={location} {...rest} />;
  }
}
