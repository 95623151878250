import React from "react";
import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";
import { config } from "./aws-config";

export const ReactS3Client = new S3(config);

export const deleteImages = async (images) => {
  if (images && images.length > 0) {
    images.forEach((element) => {
      deleteImage({ fileUrl: element });
    });
  }
};

export const deleteImage = async ({ fileUrl }) => {
  console.log("deleteFile ", fileUrl);
  const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);

  ReactS3Client.deleteFile(fileName)
    .then((data) => {
      console.log("deleteFile ", data);
      return data;
    })
    .catch((err) => console.error("deleteFile error ", err));
};

const fetchImageFromUri = async (uri) => {
  const response = await fetch(uri);
  const blob = await response.blob();
  return blob;
};

export const uploadImage = async ({ file }) => {
  const uuidFile = uuidv4();
  const filename = `${user.id}_${uuidFile}`;
  var type = file.substring(file.lastIndexOf(":") + 1, file.lastIndexOf(";"));
  const blobToFile = new File([file], "name", { type });
  const img = await fetchImageFromUri(file);

  ReactS3Client.uploadFile(img, filename)
    .then((data) => {
      console.log("uploadImage ", data);
      return data;
    })
    .catch((err) => console.error(err));
};
