import React from "react";
import { MaterialIcons } from "@expo/vector-icons";

export function Visibility({ visible = false, color = "black", size = 24 }) {
  return (
    <MaterialIcons
      name={visible ? "visibility" : "visibility-off"}
      size={size}
      color={color}
    />
  );
}
