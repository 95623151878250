import React from "react";
import { AntDesign } from "@expo/vector-icons";
import IconButton from "../basic/buttons/icon-button";
import useTheme from "../styles/use-theme";
import styled from "styled-components/native";

const Container = styled.View`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  border-radius: ${(props) => props.size / 2};
  background-color: ${(props) => props.theme.palette.addImage.background};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function ClearButton({
  onPress,
  size = 30,
  iconSize = 24,
  round,
  color,
  ...rest
}) {
  const theme = useTheme();

  return (
    <IconButton onPress={onPress} {...rest}>
      <Container size={size}>
        <AntDesign
          name="close"
          size={iconSize}
          color={color || theme.palette.font.primary}
        />
      </Container>
    </IconButton>
  );
}
