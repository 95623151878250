import React, { Component, useState } from "react";
import { Dimensions, Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { WebZoomImage } from "./web-zoom-image";

const { width } = Dimensions.get("window");

export function WebLightbox({ images }) {
  const [open, setOpen] = useState(false);

  const onPress = () => setOpen(true);
  const onClose = () => setOpen(false);
  return (
    <>
      <TouchableOpacity onPress={onPress}>
        <Image source={{ uri: images[0] }} style={styles.image} />
      </TouchableOpacity>
      {open && <WebZoomImage images={images} open={open} onClose={onClose} />}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  video: {
    width: width / 1.5,
    height: 150,
    margin: 13,
    borderRadius: 13
  },
  image: {
    width: 150,
    height: 100,
    borderRadius: 13,
    margin: 3,
    resizeMode: "cover"
  }
});
