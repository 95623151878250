import React from "react";
import Title from "../../../components/basic/title";
import styled from "styled-components/native";
import { Modal as NModal } from "native-base";
import { Close } from "../../../components/basic/icons/close";
import useTheme from "../../../components/styles/use-theme";
import IconButton from "../../../components/basic/buttons/icon-button";
import BaseText from "../base-text";

const StyledBody = styled(NModal.Body)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  align-items: center;
  background-color: ${(props) => props.theme.palette.modal.background};
`;

const StyledContent = styled(NModal.Content)`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.modal.background};
  width: 100%;
  padding: 36px 24px;
`;

const StyledHeader = styled(NModal.Header)`
  border-bottom-width: 0;
  padding-top: 4px;
  padding-bottom: 12px;
  text-align: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.modal.background};
`;

const StyledTitle = styled(Title)`
  color: ${(props) => props.theme.palette.white};
  font-size: 18px;
`;

const StyledCloseButton = styled(IconButton)`
  margin: 16px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
`;

export const StyledText = styled(BaseText)`
  color: ${(props) => props.theme.palette.white};
  font-weight: 600;
  line-height: 24px;
  font-size: 14px;
`;

export const Modal = ({
  open,
  children,
  title,
  onClose,
  message,
  size = "md",
  style
}) => {
  const theme = useTheme();

  return (
    <NModal
      isOpen={open}
      onClose={onClose}
      animationPreset={"slide"}
      overlayVisible={true}
      style={{
        shadowColor: "#171717",
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        position: "relative",
        height: "100%",
        width: "100%"
      }}
      size={size}
    >
      <StyledContent>
        <StyledCloseButton onPress={onClose}>
          <Close color={theme.palette.white} />
        </StyledCloseButton>
        {title && (
          <StyledHeader>
            <StyledTitle>{title}</StyledTitle>
          </StyledHeader>
        )}

        <StyledBody style={style}>
          {message ? <StyledText>{message}</StyledText> : children}
        </StyledBody>
      </StyledContent>
    </NModal>
  );
};
