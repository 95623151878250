import React from "react";
import styled from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";
import IconButton from "../../components/basic/buttons/icon-button";
import { useTheme } from "styled-components/native";

const StyledIconButton = styled(IconButton)`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-right: 8px;
`;

export default function ClearButton({ onPress }) {
  const theme = useTheme();
  return (
    <StyledIconButton onPress={onPress}>
      <MaterialIcons
        name="clear"
        size={20}
        color={theme.palette.button.primary.color}
      />
    </StyledIconButton>
  );
}
