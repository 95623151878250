import React from "react";
import { useSelector } from "react-redux";
import Loader from "./loader";

export default function ReduxLoader(props) {
  const isLoading = useSelector((state) => state.core?.appLoader);
  if (isLoading) {
    return <Loader {...props} />;
  } else {
    return null;
  }
}
