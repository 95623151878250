import { actionTypes } from "../actions/action-types";
import { USER_REQUESTS_API } from "../../constants/apis-urls";
import { REQUEST_SENT } from "../../utils/user-requests-utils";
import { getErrorMessage } from "../../utils/errors-utils";
import { showError } from "../actions/core-actions";
import { restClient } from "./fetch-thunk";

export const sendRequestForItem = ({ refundId, sellerId, refundTitle }) => {
  return async (dispatch, getState) => {
    const { id: userId, firstName: name, email } = getState().auth?.user;
    const token = getState().auth?.token;

    const isRequestSent = getState().userRequests?.ids[refundId];
    if (isRequestSent) {
      return;
    }
    if (userId === sellerId) {
      console.log(
        "sendRequestForItem user cannot send request for its item userId ",
        userId,
        " sellerId ",
        sellerId
      );
      return;
    }
    try {
      console.log("sendRequestForItem userId ", userId);
      dispatch({
        type: actionTypes.SET_SEND_REQUEST_LOADING,
        payload: true
      });
      const result = await dispatch(
        restClient.postAPI({
          url: USER_REQUESTS_API,
          body: {
            userId,
            refundId,
            sellerId,
            status: REQUEST_SENT,
            refundTitle,
            name,
            email
          }
        })
      );
      console.log("sendRequestForItem responseData ", result);
      dispatch({
        type: actionTypes.SET_USER_REQUEST,
        payload: { refundId }
      });
    } catch (e) {
      console.error(
        "failed to send user request userId ",
        userId,
        " refundId ",
        refundId,
        e
      );
      dispatch(showError(e.message));
    } finally {
      dispatch({
        type: actionTypes.SET_SEND_REQUEST_LOADING,
        payload: false
      });
    }
  };
};

export const fetchUserRefundRequests = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: actionTypes.SET_SEND_REQUEST_LOADING,
        payload: true
      });
      const userId = getState().auth?.user?.id;
      console.log("fetching user requests for user ", userId);
      const url = `${USER_REQUESTS_API}/${userId}`;
      const result = await dispatch(restClient.getAPI(url));
      console.log("fetching user requests res ", result);
      dispatch({
        type: actionTypes.FETCH_USER_REQUESTS,
        payload: { items: result }
      });
    } catch (e) {
      console.error("failed to fetch user requests ", e);
      dispatch(showError(e.message));
    } finally {
      dispatch({
        type: actionTypes.SET_SEND_REQUEST_LOADING,
        payload: false
      });
    }
  };
};

export const updateUserRequestStatus = ({ id, status, read, refundTitle }) => {
  return async (dispatch, getState) => {
    const { id: userId, firstName: name, email } = getState().auth?.user;

    try {
      console.log(
        "updateUserRequestStatus id ",
        id,
        " userId ",
        userId,
        status,
        read,
        refundTitle
      );
      if (status === undefined && read === undefined) {
        return;
      }
      dispatch({
        type: actionTypes.SET_SEND_REQUEST_LOADING,
        payload: true
      });
      const data = { id, refundTitle, email, name };
      if (status !== undefined) {
        data.status = status;
      }
      if (read !== undefined) {
        data.read = read;
      }
      const result = await dispatch(
        restClient.putAPI({
          url: USER_REQUESTS_API,
          body: data
        })
      );
      console.log("updateUserRequestStatus responseData ", result);
      dispatch({
        type: actionTypes.UPDATE_USER_REQUEST,
        payload: { id, status, read }
      });
      return result;
    } catch (e) {
      console.error(
        "failed to update user request userId ",
        userId,
        " id ",
        status,
        " status ",
        id,
        e
      );
      dispatch(showError("failed to update user request " + e.message));
    } finally {
      dispatch({
        type: actionTypes.SET_SEND_REQUEST_LOADING,
        payload: false
      });
    }
  };
};
