import { actionTypes } from "./action-types";
import { SERVER_URL } from "../../config/config";
import { showError } from "./core-actions";
import { getAPI } from "../thunks/fetch-thunk";

export const CATEGORIES_API = `${SERVER_URL}/api/categories`;

export const fetchCategories = () => {
  return async (dispatch) => {
    try {
      console.log("fetching categories");
      const result = await dispatch(getAPI(CATEGORIES_API));
      dispatch({
        type: actionTypes.FETCH_CATEGORIES,
        payload: { categories: result }
      });
    } catch (e) {
      console.error("failed to fetch categories ", e);
      dispatch(showError(e.message));
    }
  };
};
