import React from "react";
import styled from "styled-components/native";
import BaseText from "./basic/base-text";

const StyledText = styled(BaseText)`
  color: ${(props) => props.theme.palette.error.font};
  font-size: 12px;
  text-transform: lowercase;
`;

export default function Error({ children }) {
  if (!children) {
    return null;
  }
  return <StyledText>{children}</StyledText>;
}
