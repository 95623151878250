import React, { useState } from "react";
import { Button, Platform, View } from "react-native";
import styled, { useTheme } from "styled-components/native";
import DatePickerButton from "./date-picker-button";
import DateTimePicker from "@react-native-community/datetimepicker";
import Modal from "react-native-modal";
import { useSelector } from "react-redux";
import { isAndroid, isIos } from "../../../utils/core-utils";

const DATE_FORMAT = "DD/MM/YYYY";
//const date = moment(new Date()).format("dd/mm/yyyy");

const StyledButtons = styled.View`
  width: 100%;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

const DateContainer = styled.View``;

export default function MobileDatePicker({ onChange, value }) {
  const [show, setShow] = useState(false);
  console.log("MobileDatePicker ", value);

  const [date, setDate] = useState(value || new Date());
  const theme = useTheme();
  const currentTheme = useSelector((state) => state.settings?.theme);

  const handleOnChange = (event, date) => {
    if (!show) {
      return;
    }

    if (isAndroid()) {
      if (event.type === "dismissed") {
        onClose();
      } else if (event.type === "set") {
        onChange(date);
        setDate(date);
        onClose();
      }
    } else {
      setDate(date);
    }
  };

  const onOpen = () => {
    setShow(true);
  };

  const onClose = () => {
    setShow(false);
  };

  const onConfirm = () => {
    onChange(date);
    onClose();
  };
  return (
    <DateContainer>
      <DatePickerButton date={value} onPress={onOpen} />
      <Modal isVisible={show}>
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <View
            style={{
              width: 300,
              height: 300,
              backgroundColor: theme.palette.datePicker.background,
              padding: 16,
              borderRadius: 8
            }}
          >
            <DateTimePicker
              testID="dateTimePicker"
              value={date}
              mode={"date"}
              is24Hour={false}
              display={Platform.OS === "ios" ? "spinner" : "default"}
              onChange={handleOnChange}
              themeVariant={currentTheme}
            />

            {isIos() && (
              <StyledButtons>
                <Button
                  title="Cancel"
                  color={theme.palette.link.color}
                  onPress={onClose}
                />
                <Button
                  title="Confirm"
                  color={theme.palette.link.color}
                  onPress={onConfirm}
                />
              </StyledButtons>
            )}
          </View>
        </View>
      </Modal>
    </DateContainer>
  );
}
