import React, { useEffect, useMemo, useRef, useState } from "react";
import { FlatList } from "react-native";
import styled, { useTheme } from "styled-components/native";
import { layoutWeb, StyledLayout } from "../../components/styles/screen-layout";
import { clearAllSearches, fetchSearches } from "../../redux/thunks/search-thunk";
import { useDispatch, useSelector } from "react-redux";
import ResultRow from "./result-row";
import actionTypes from "../../redux/actions/action-types";
import { fetchRefunds } from "../../redux/thunks/refunds-thunk";
import { isIos, isWeb } from "../../utils/core-utils";
import BackButton from "../../components/basic/buttons/back-button";
import { useTranslation } from "react-i18next";
import { SearchInput } from "../../components/search/search-input";
import { DESKTOP_MAX_WIDTH } from "../../components/styles/constants";

const StyledView = styled.View`
  ${layoutWeb};
  margin-top: ${isWeb() ? 16 : isIos() ? 40 : 40}px;
`;

const StyledSearchContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 8px;
`;

export default function SearchScreen(props) {
  const [value, setValue] = useState("");
  const theme = useTheme();
  const dispatch = useDispatch();
  const searches = useSelector((state) => state.search.items) || [];
  const searchRef = useRef();
  const isLoaded = useSelector((state) => state.search.isLoaded);
  const searchText = useSelector((state) => state.search?.searchText);
  const user = useSelector((state) => state.auth?.user);
  const { t } = useTranslation();
  console.log("SearchScreen");

  useEffect(() => {
    if (user?.id && !isLoaded) {
      dispatch(fetchSearches());
    }
  }, [isLoaded, user]);

  useEffect(() => {
    searchRef?.current?.focus();
  }, [searchRef]);

  const onSearch = (text) => {
    setValue(text);
  };

  useEffect(() => {
    setValue(searchText);
  }, [searchText]);

  const results = useMemo(() => {
    const text = value ? value.trim().toLowerCase() : value;
    let res = searches;
    if (text) {
      res = searches.filter((item) => item.text.toLowerCase().includes(text));
    }
    return res;
  }, [searches, value]);

  const handleSelectedSearch = (value) => {
    const text = value ? value.trim().toLowerCase() : value;
    if (text !== undefined) {
      dispatch({ type: actionTypes.SET_SEARCH_QUERY, payload: text });
      dispatch(fetchRefunds({ searchText: text }));
    }
    props.navigation.navigate("Home");
  };

  const isExistingSearch = (value) => {
    const found = searches.find((item) => item.text.toLowerCase() === value);
    return !!found;
  };

  const onSubmit = () => {
    handleSelectedSearch(value);
    if (value && !isExistingSearch(value)) {
      dispatch({ type: actionTypes.ADD_SEARCH_QUERY, payload: value });
    }
  };

  const onClickRow = (value) => {
    handleSelectedSearch(value);
    if (value && !isExistingSearch(value)) {
      dispatch({ type: actionTypes.ADD_SEARCH_QUERY, payload: value });
    }
  };

  const handleClear = () => {
    setValue("");
  };

  const handleBack = () => {
    if (searchText !== value) {
      setValue("");
      handleSelectedSearch("");
    } else {
      props.navigation.navigate("Home");
    }
  };

  const clearAll = () => {
    dispatch(clearAllSearches());
  };

  return (
    <StyledView
      contentContainerStyle={
        {
          // alignItems: theme.language === HE_LANG ? "flex-end" : "flex-start"
        }
      }
    >
      <StyledSearchContainer>
        <BackButton onPress={handleBack} />
        <SearchInput
          onChange={onSearch}
          value={value}
          onClear={handleClear}
          searchRef={searchRef}
          onSubmit={onSubmit}
          width={300}
          isSearchScreen
        />
      </StyledSearchContainer>
      {results?.length > 0 && (
        <ResultRow
          item={{ text: t("Clear all searches") }}
          theme={theme}
          onPress={clearAll}
          isBorder={false}
          icon={"delete"}
          style={{ marginBottom: 16, marginTop: 8 }}
        />
      )}

      <FlatList
        contentContainerStyle={{
          maxWidth: DESKTOP_MAX_WIDTH,
          width: "100%",
          marginRight: "auto",
          marginLeft: "auto"
        }}
        data={results}
        renderItem={({ item }) => (
          <ResultRow
            item={item}
            theme={theme}
            onPress={() => onClickRow(item.text)}
          />
        )}
        keyExtractor={(item) => (item ? item.id : item)}
      />
    </StyledView>
  );
}
