export const MOCK_IMAGE_URL =
  "https://refundme-images.s3.us-east-2.amazonaws.com/mock-image.png";
export const MOCK_STORE_URL =
  "https://cleantechnica.com/2021/07/24/sandy-munro-experiences-teslas-fsd-beta-v9-im-pretty-happy-with-what-i-see-in-the-way-of-progress-here/";

export const DEFAULT_IMAGE =
  "https://refundme-images.s3.us-east-2.amazonaws.com/default-image.jpeg";

export const DEFAULT_CARD_PLACEHOLDER =
  "https://refundme-images.s3.us-east-2.amazonaws.com/card-placeholder.png";

export const LOGO_IMAGE =
  "https://refundme-images.s3.us-east-2.amazonaws.com/logo-blue.png";

export const getImageData = (images) =>
  images?.length > 0 ? { uri: images[0] } : { uri: DEFAULT_CARD_PLACEHOLDER };

export const getShareImage = (images) =>
  images?.length > 0 ? images[0] : LOGO_IMAGE;

export const MOCK_GALLERY_IMAGES = [DEFAULT_CARD_PLACEHOLDER];

export const ZOOM_MOCK_GALLERY_IMAGES = [{ url: DEFAULT_CARD_PLACEHOLDER }];
