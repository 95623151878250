import React from "react";
import { useTheme } from "styled-components/native";
import { TextInput, TouchableOpacity, View } from "react-native";
import { Close } from "../basic/icons/close";
import { useTranslation } from "react-i18next";

export const SearchInput = ({
  onChange,
  placeholder,
  value,
  onSubmit,
  onClear,
  searchRef,
  height = 40,
  width = "100%",
  isSearchScreen,
  onFocus,
  disabled,
  onPress
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleFocus = (e) => {
    if (onFocus) {
      onFocus();
    }
  };

  const handleClear = (e) => {
    if (onClear) {
      onClear();
    }
  };
  const onPressIt = (e) => {
    if (onPress) {
      onPress();
    }
  };

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
        width: "100%",
        flex: isSearchScreen ? 1 : undefined
      }}
    >
      <TouchableOpacity
        onPress={onPressIt}
        style={{
          width: "100%",
          position: "relative"
        }}
      >
        <View
          pointerEvents={disabled ? "none" : undefined}
          style={{
            width: "100%",
            position: "relative"
          }}
        >
          <TextInput
            onChangeText={onChange}
            placeholder={placeholder || t("Search...")}
            value={value}
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderRadius: 4,
              borderColor: theme.palette.input.border,
              backgroundColor: theme.palette.input.background,
              color: theme.palette.input.font,
              height,
              padding: 10,
              paddingRight: 32,
              width: "100%"
            }}
            onFocus={handleFocus}
            onSubmitEditing={onSubmit}
            returnKeyType="go"
            ref={searchRef}
            editable={!disabled}
            selectTextOnFocus={!disabled}
            placeholderTextColor={theme.palette.input.font}
          />
        </View>
      </TouchableOpacity>

      {value ? (
        <TouchableOpacity
          style={{
            position: "absolute",
            right: 8
          }}
          onPress={handleClear}
        >
          <Close color={theme.palette.button.primary.color} size={16} />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};
