import React from "react";
import { Modal, StyledText } from "../basic/modals/modal";
import { FontAwesome } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";
import styled from "styled-components/native";
import { baseFlexRow, marginLeftRight } from "../styles/global-styles";
import ShareButton from "../share/share-button";
import { useDispatch, useSelector } from "react-redux";
import useTheme from "../styles/use-theme";
import { toggleModal } from "../../redux/actions/core-actions";
import useShareMessage from "../../hooks/use-share-message";
import { ADDED_REFUND } from "./modal-types";

const StyledRow = styled.View`
  margin-bottom: 4px;
  width: 100%;
  ${baseFlexRow()}

  align-items: flex-start;
`;

const StyledContainer = styled.View`
  width: 100%;
  display: flex;
`;

const StyledTypography = styled(StyledText)`
  margin-top: 2px;
  ${marginLeftRight(8)}
`;

export function AddedRefundModal() {
  const { t } = useTranslation();
  const addedRefund = useSelector((state) => state.core?.modals[ADDED_REFUND]) || {};
  const { open, data } = addedRefund;
  const dispatch = useDispatch();
  const theme = useTheme();
  const { refund, title } = data || {};
  const shareMessage = useShareMessage({ item: refund });
  const onClose = () => {
    dispatch(toggleModal({ modal: ADDED_REFUND, open: false }));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title || t("Refund Created Successfully")}
    >
      <StyledRow style={{ marginBottom: 16 }}>
        <FontAwesome
          name="money"
          size={24}
          color={theme.palette.white}
          style={{ marginTop: 2 }}
        />
        <StyledTypography>{t("Share your refund to boost sales")}</StyledTypography>
      </StyledRow>
      <StyledRow>
        <ShareButton
          title={t("Share")}
          id={refund?.id}
          message={shareMessage}
          size={42}
          buttonStyle={{ width: "100%" }}
        />
      </StyledRow>
    </Modal>
  );
}
