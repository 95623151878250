import React, { useState } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  View
} from "react-native";
import styled from "styled-components/native";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { StyledLayout } from "../../components/styles/screen-layout";
import Errors from "../../components/errors";
import { useTranslation } from "react-i18next";
import InputField from "../../components/basic/input-field";
import { SOMETHING_WRONG } from "../../utils/errors-utils";
import { editUserFormSchema } from "../../utils/validations";
import { showSuccess } from "../../redux/actions/core-actions";
import { updateUser } from "../../redux/thunks/auth-thunk";
import LinkButton from "../../components/basic/buttons/link-button";
import BaseText from "../../components/basic/base-text";
import { spaceBetweenFlexRow } from "../../components/styles/global-styles";
import Separator from "../../components/basic/separator";
import Loader from "../../components/loaders/loader";

const StyledPage = styled(KeyboardAvoidingView)`
  background-color: ${(props) => props.theme.palette.background.primary};
`;

const StyledForm = styled.View`
  background-color: ${(props) => props.theme.palette.background.primary};
  padding: 20px;
  border-radius: 10px;
`;

const StyledInputRow = styled(InputField)``;

const StyledTitle = styled(BaseText)`
  font-size: 16px;
  font-weight: 700;
`;

const StyledRow = styled.View`
  margin-top: 36px;
  align-items: flex-end;
  width: 100%;
  color: ${(props) => props.theme.palette.font.primary};

  ${spaceBetweenFlexRow}
`;

export const EditUser = ({ navigation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const userId = user?.id;

  return (
    <StyledLayout>
      <ScrollView>
        <StyledPage
          style={{
            flex: 1
          }}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
          <Formik
            initialValues={{
              firstName: user?.firstName || "",
              lastName: user?.lastName || "",
              email: user?.email || ""
            }}
            validationSchema={editUserFormSchema}
            onSubmit={(values, { resetForm }) => {
              setIsLoading(true);
              if (errors) {
                setErrors(null);
              }
              dispatch(updateUser({ userId, data: values }))
                .then(async (result) => {
                  dispatch(
                    showSuccess({
                      message: "Updated successfully"
                    })
                  );
                  resetForm({
                    values: {
                      firstName: result?.firstName || "",
                      lastName: result?.lastName || "",
                      email: result?.email || ""
                    }
                  });
                })
                .catch((e) => {
                  console.error(e);
                  setErrors([e.message || SOMETHING_WRONG]);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            {(props) => (
              <View
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <StyledForm>
                  <StyledRow>
                    <StyledTitle>{t("Edit user")}</StyledTitle>
                  </StyledRow>

                  <Errors errors={errors} />
                  <Separator marginBottom={24} marginTop={12} />

                  <View style={styles.formGroup}>
                    <StyledInputRow
                      title={t("First Name")}
                      name="firstName"
                      multiline={false}
                      props={props}
                      disabled={isLoading}
                    />
                  </View>
                  <View style={styles.formGroup}>
                    <StyledInputRow
                      title={t("Last Name")}
                      name="lastName"
                      multiline={false}
                      props={props}
                      disabled={isLoading}
                    />
                  </View>
                  <View style={styles.formGroup}>
                    <StyledInputRow
                      title={t("Email")}
                      name="email"
                      multiline={false}
                      props={props}
                      keyboardType="email-address"
                      disabled={isLoading}
                    />
                  </View>

                  <StyledRow>
                    <LinkButton
                      onPress={props.handleSubmit}
                      text={t("Save")}
                      disabled={errors?.length > 0 || !props.dirty || isLoading}
                      style={{ marginLeft: "auto" }}
                    />
                  </StyledRow>
                </StyledForm>
              </View>
            )}
          </Formik>
        </StyledPage>
      </ScrollView>
      {isLoading && <Loader />}
    </StyledLayout>
  );
};

const styles = StyleSheet.create({
  formGroup: {
    width: "100%"
  },
  label: {
    marginVertical: 10
  },
  buttonContainer: {
    marginTop: 20
  },
  button: {
    borderRadius: 100
  },
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
});
