import { actionTypes } from "../actions/action-types";

const initialState = {
  isLoading: true,
  threadIdByUser: {},
  messagesByThreadId: {},
  currentChatId: null,
  threadsById: {},
  currentChat: null
};

const chatsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_CHAT_MESSAGES: {
      const { threadId, messages } = payload;
      return {
        ...state,
        messagesByThreadId: {
          ...state.messagesByThreadId,
          [threadId]: messages || []
        }
      };
    }
    case actionTypes.ADD_CHAT_MESSAGES: {
      const { threadId, messages } = payload;
      const oldMessages = state.messagesByThreadId[threadId] || [];
      return {
        ...state,
        messagesByThreadId: {
          ...state.messagesByThreadId,
          [threadId]: { ...oldMessages, ...messages }
        }
      };
    }
    case actionTypes.SET_CHAT_MESSAGES_LOADING: {
      return {
        ...state,
        isLoading: payload
      };
    }

    case actionTypes.SET_THREAD_ID: {
      const { userId, threadId } = payload;
      return {
        ...state,
        threadIdByUser: {
          ...state.threadIdByUser,
          [userId]: threadId
        }
      };
    }
    case actionTypes.SET_CURRENT_THREAD_ID: {
      return {
        ...state,
        currentChatId: payload
      };
    }
    case actionTypes.SET_THREAD: {
      const { thread, threadId } = payload;
      return {
        ...state,
        threadsById: {
          ...state.threadsById,
          [threadId]: thread
        }
      };
    }
    case actionTypes.SET_CURRENT_CHAT: {
      return {
        ...state,
        currentChat: payload,
        currentChatId: payload?.threadId || null
      };
    }
    case actionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default chatsReducer;
