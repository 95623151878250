export const breakpoints = {
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1280
};

export function createBreakpoints(breakpoints = {}) {
  const values = {
    375: 375,
    768: 768,
    1000: 1000,
    1200: 1200,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1280,
    ...breakpoints
  };

  return {
    values,
    above(key) {
      return `@media screen and (min-device-width: ${values[key]}px)`;
    },
    between(start, end) {
      return `@media screen and (min-device-width: ${
        values[start]
      }px) and (max-device-width: ${values[end] - 1}px)`;
    }
  };
}
