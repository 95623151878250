import { actionTypes } from "../actions/action-types";

const initialState = {
  user: {},
  token: null,
  errors: null,
  users: {},
  isLoaded: false,
  expoPushTokens: [],
  isPushTokensLoaded: false,
  userMetadata: {}
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_USER_TOKEN: {
      const { user, token } = payload;
      return {
        ...state,
        user,
        token,
        isLoaded: true
      };
    }
    case actionTypes.SET_USER_LOADED: {
      return {
        ...state,
        isLoaded: payload
      };
    }
    case actionTypes.GET_USER_SUCCESS: {
      const { user } = payload;
      return {
        ...state,
        users: {
          ...state.users,
          [user.id]: user
        }
      };
    }
    case actionTypes.SET_USERS_DATA: {
      const { users = [] } = payload;
      const usersMap = {};
      users.forEach((element) => {
        usersMap[element.id] = element;
      });
      return {
        ...state,
        users: {
          ...state.users,
          ...usersMap
        }
      };
    }
    case actionTypes.AUTH_API_FAIL: {
      const { errors } = payload;
      return {
        ...state,
        errors
      };
    }
    case actionTypes.UPDATE_USER: {
      const { user, token } = payload;
      return {
        ...state,
        user: {
          ...state.user,
          ...user
        },
        token
      };
    }
    case actionTypes.SET_USER_PUSH_TOKENS: {
      return {
        ...state,
        expoPushTokens: payload,
        isPushTokensLoaded: true
      };
    }
    case actionTypes.CLEAR_USER: {
      return {
        ...state,
        user: {},
        token: null
      };
    }
    case actionTypes.FETCH_USER_METADATA: {
      return {
        ...state,
        userMetadata: payload
      };
    }

    default:
      return state;
  }
};

export default authReducer;
