import React, { useState } from "react";
import styled from "styled-components/native";
import AddImage from "./add-image";
import ImageItem from "./image-item";
import { StyleSheet, Text, View, Platform } from "react-native";
import BaseText from "../basic/base-text";
import { useTranslation } from "react-i18next";
import Loader from "../loaders/loader";
import { deleteImage } from "../../services/upload-image-service";
import { showError } from "../../redux/actions/core-actions";
import { useDispatch } from "react-redux";
import {
  flexDirection,
  marginRightLeft,
  textDirection
} from "../styles/global-styles";

const Container = styled.View`
  position: relative;
`;

const ImagesContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  ${flexDirection}
`;

const StyledItem = styled(ImageItem)`
  margin-bottom: 16px;
  ${marginRightLeft(16)}
`;

const MAX_IMAGES_NUMBER = 5;

const StyledTitle = styled(BaseText)`
  margin-bottom: 8px;
  ${textDirection}
`;

export default function ImagesInputContainer({
  items = [],
  onDelete,
  onAdd,
  ...rest
}) {
  const [images, setImages] = useState(items);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleAdd = (image) => {
    setImages([image, ...images]);
    if (onAdd) {
      onAdd(image);
    }
  };
  const handleDelete = async (image) => {
    //delete from aws
    try {
      setLoading(true);
      var res = await deleteImage({ fileUrl: image });
      console.log("deleteImage ", res);
      const remainingImages = images.filter((item) => item !== image);
      setImages(remainingImages);
      if (onDelete) {
        onDelete(image);
      }
      setLoading(false);
    } catch (e) {
      console.error("failed to delete image ", e);
      dispatch(showError("Failed to delete image"));
      setLoading(false);
    }
  };

  return (
    <Container {...rest}>
      <StyledTitle>
        {t("uploadImagesTitle", {
          selected: `${images.length}/${MAX_IMAGES_NUMBER}`,
          maxNumber: MAX_IMAGES_NUMBER
        })}
      </StyledTitle>
      <ImagesContainer>
        {images.map((item, i) => (
          <StyledItem url={item} onDelete={handleDelete} key={i} />
        ))}
        <AddImage
          onAdd={handleAdd}
          noImagesSelected={images.length === 0}
          setLoading={setLoading}
        />
      </ImagesContainer>
      {loading && <Loader />}
    </Container>
  );
}
