import { DEFAULT_THEME } from "../../components/styles/themes";
import { actionTypes } from "../actions/action-types";
import { DEFAULT_SETTINGS } from "../../utils/settings-utils";

const initialState = {
  userId: undefined,
  theme: DEFAULT_SETTINGS.theme,
  language: DEFAULT_SETTINGS.language,
  currency: DEFAULT_SETTINGS.currency,
  location: undefined,
  isDirty: false
};

const settingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.FETCH_SETTINGS: {
      return {
        ...state,
        ...payload,
        isLoaded: true
      };
    }
    case actionTypes.THEME_SWITCH: {
      const { theme } = payload;
      return {
        ...state,
        theme,
        isDirty: true
      };
    }
    case actionTypes.SET_CURRENCY: {
      const { currency } = payload;
      return {
        ...state,
        currency,
        isDirty: true
      };
    }
    case actionTypes.SET_SETTINGS_LOCATION: {
      const { location } = payload;
      return {
        ...state,
        location,
        isDirty: true
      };
    }
    case actionTypes.LANG_SWITCH: {
      const { language } = payload;
      return {
        ...state,
        language,
        isDirty: true
      };
    }
    case actionTypes.SET_IS_DIRTY: {
      return {
        ...state,
        isDirty: payload
      };
    }
    case actionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default settingsReducer;
