import React from "react";
import { Modal } from "native-base";
import styled from "styled-components/native";
import { ModernDatePicker } from "./modern-date-picker";
import useTheme from "../../styles/use-theme";

const StyledBody = styled(Modal.Body)`
  margin-top: 24px;
  width: 320px;
`;

const StyledContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.secondary};
`;

export default function WebCalendar({ onChange, value, isVisible, onClose }) {
  const theme = useTheme();
  return (
    <Modal isOpen={isVisible} onClose={onClose}>
      <StyledContent>
        <Modal.CloseButton
          colorScheme={theme.palette.button.primary.color}
          variant="link"
        />
        <StyledBody>
          <ModernDatePicker onChange={onChange} value={value} />
        </StyledBody>
      </StyledContent>
    </Modal>
  );
}
