export const actionTypes = {
  FETCH_ARTICLES: "FETCH_ARTICLES",
  TOGGLE_FOVORITE: "TOGGLE_FOVORITE",
  FETCH_REFUNDS: "FETCH_REFUNDS",
  TOGGLE_LOADER: "TOGGLE_LOADER",
  ADD_REFUND: "ADD_REFUND",
  SET_USER_TOKEN: "SET_USER_TOKEN",
  LANG_SWITCH: "LANG_SWITCH",
  THEME_SWITCH: "THEME_SWITCH",
  FETCH_CATEGORIES: "FETCH_CATEGORIES",
  UPDATE_SETTINGS: "UPDATE_SETTINGS",
  FETCH_USER_REFUNDS: "FETCH_USER_REFUNDS",
  AUTH_API_FAIL: "AUTH_API_FAIL",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  FETCH_FAVORITES: "FETCH_FAVORITES",
  SET_CURRENCY: "SET_CURRENCY",
  SET_SETTINGS_LOCATION: "SET_SETTINGS_LOCATION",
  FETCH_FEATURES: "FETCH_FEATURES",
  TOGGLE_APP_LOADER: "TOGGLE_APP_LOADER",
  TOGGLE_REFUNDS_LOADING: "TOGGLE_REFUNDS_LOADING",
  SET_SEARCH_QUERY: "SET_SEARCH_QUERY",
  SET_FILTER: "SET_FILTER",
  FAVORITES_IS_LOADING: "FAVORITES_IS_LOADING",
  FETCH_FAVORITES_IDS: "FETCH_FAVORITES_IDS",
  SET_FAVORITE_REFUNDS: "SET_FAVORITE_REFUNDS",
  FETCH_SETTINGS: "FETCH_SETTINGS",
  SET_IS_DIRTY: "SET_IS_DIRTY",
  SET_SEARCHES: "SET_SEARCHES",
  SET_SEARCHES_LOADING: "SET_SEARCHES_LOADING",
  SET_USER_REQUEST: "SET_USER_REQUEST",
  SET_SEND_REQUEST_LOADING: "SET_SEND_REQUEST_LOADING",
  FETCH_USER_REQUESTS: "FETCH_USER_REQUESTS",
  SET_MESSAGES: "SET_MESSAGES",
  SET_MESSAGES_LOADING: "SET_MESSAGES_LOADING",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  SET_NOTIFICATIONS_LOADING: "SET_NOTIFICATIONS_LOADING",
  SET_BUY_REQUESTS_LOADING: "SET_BUY_REQUESTS_LOADING",
  SET_BUY_REQUESTS: "SET_BUY_REQUESTS",
  SET_APP_LOADED: "SET_APP_LOADED",
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER: "UPDATE_USER",
  SET_MESSAGES_ACTIVE_TAB: "SET_MESSAGES_ACTIVE_TAB",
  SET_USER_PUSH_TOKENS: "SET_USER_PUSH_TOKENS",
  TOGGLE_FETCH_REFUND_BY_ID_LOADING: "TOGGLE_FETCH_REFUND_BY_ID_LOADING",
  FETCH_REFUND_BY_ID: "FETCH_REFUND_BY_ID",
  REFUNDS_LIST_SCROLL_TOP_TOGLLE: "REFUNDS_LIST_SCROLL_TOP_TOGLLE",
  DRAWER_TOGGLE: "DRAWER_TOGGLE",
  SET_CHAT: "SET_CHAT",
  SET_THREAD_ID: "SET_THREAD_ID",
  SET_CHAT_MESSAGES_LOADING: "SET_CHAT_MESSAGES_LOADING",
  SET_CHAT_MESSAGES: "SET_CHAT_MESSAGES",
  SHOW_ERROR: "SHOW_ERROR",
  SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
  HIDE_NOTIFICATION: "HIDE_NOTIFICATION",
  SET_MESSAGES_LOADED: "SET_MESSAGES_LOADED",
  SET_LAST_MESSAGE: "SET_LAST_MESSAGE",
  SET_LAST_MESSAGES: "SET_LAST_MESSAGES",
  SET_USERS_DATA: "SET_USERS_DATA",
  SET_CURRENT_THREAD_ID: "SET_CURRENT_THREAD_ID",
  SET_ACTIVITY_ACTIVE_TAB: "SET_ACTIVITY_ACTIVE_TAB",
  TOGGLE_USER_REFUNDS_LOADING: "TOGGLE_USER_REFUNDS_LOADING",
  TOGGLE_USER_REQUESTED_REFUNDS_LOADING: "TOGGLE_USER_REQUESTED_REFUNDS_LOADING",
  FETCH_USER_REQUESTED_REFUNDS: "FETCH_USER_REQUESTED_REFUNDS",
  TOGGLE_IS_PROCESSING: "TOGGLE_IS_PROCESSING",
  UPDATE_REFUND_STATUS: "UPDATE_REFUND_STATUS",
  DELETE_REFUND: "DELETE_REFUND",
  UPDATE_REFUND: "UPDATE_REFUND",
  SET_MESSAGES_DIRTY: "SET_MESSAGES_DIRTY",
  SET_THREAD: "SET_THREAD",
  SET_REFUNDS_SORT: "SET_REFUNDS_SORT",
  SET_CUSTOMER_SERVICE: "SET_CUSTOMER_SERVICE",
  ADD_SEARCH_QUERY: "ADD_SEARCH_QUERY",
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
  SET_THREAD_ID_TO_UNREAD_COUNT: "SET_THREAD_ID_TO_UNREAD_COUNT",
  SET_MESSAGES_UNREAD_COUNT_BY_THREAD_ID: "SET_MESSAGES_UNREAD_COUNT_BY_THREAD_ID",
  SET_NOTIFICATIONS_UNREAD_COUNT: "SET_NOTIFICATIONS_UNREAD_COUNT",
  SET_MESSAGES_UNREAD_COUNT: "SET_MESSAGES_UNREAD_COUNT",
  SET_REFUND_REQUESTS_UNREAD_COUNT: "SET_REFUND_REQUESTS_UNREAD_COUNT",
  SET_USER_LOADED: "SET_USER_LOADED",
  SET_LAST_MESSAGES_LOADED: "SET_LAST_MESSAGES_LOADED",
  SET_CURRENT_NOTIFICATION: "SET_CURRENT_NOTIFICATION",
  SET_CURRENT_CHAT: "SET_CURRENT_CHAT",
  ADD_CHAT_MESSAGES: "ADD_CHAT_MESSAGES",
  INCREASE_MESSAGES_UNREAD_COUNT: "INCREASE_MESSAGES_UNREAD_COUNT",
  INCREASE_MESSAGES_UNREAD_COUNT_BY_THREAD_ID:
    "INCREASE_MESSAGES_UNREAD_COUNT_BY_THREAD_ID",
  UNSHIFT_MESSAGE: "UNSHIFT_MESSAGE",
  FETCH_REFUNDS_BY_IDS: "FETCH_REFUNDS_BY_IDS",
  TOGGLE_MODAL: "TOGGLE_MODAL",
  CLEAR_USER: "CLEAR_USER",
  LOGOUT: "LOGOUT",
  FETCH_USER_METADATA: "FETCH_USER_METADATA"
};

export default actionTypes;
