import React from "react";
import About from "../screens/about/about";
import { createStackNavigator } from "@react-navigation/stack";
import { useTheme } from "styled-components/native";
import { getHeaderStyle } from "./nav-style";
import { headerOptions } from "./header-options";
import { useTranslation } from "react-i18next";

const Stack = createStackNavigator();

export default function AboutStackNavigator() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="About"
        component={About}
        options={headerOptions(t("About"))}
      />
    </Stack.Navigator>
  );
}
