import React, { memo } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId
} from "react-google-places-autocomplete";
import { GOOGLE_API_KEY } from "../constants";
import { DEFAULT_LANG, HE_LANG } from "../../../../i18n";
import { useTheme } from "styled-components/native";
// import {
//   geocodeByAddress,
//   geocodeByPlaceId,
//   getLatLng
// } from "react-places-autocomplete";

const customStyles = (theme) => ({
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: theme.palette.input.background,
    // border: "none",
    fontFamily: theme.typography.mainFont,
    fontSize: "14px",
    color: theme.palette.input.font
  }),
  container: (base, state) => ({
    ...base,
    backgroundColor: theme.palette.input.background,
    borderColor: theme.palette.input.border,
    color: theme.palette.input.font,
    zIndex: 9999
  }),
  singleValue: (base, state) => ({
    ...base,
    backgroundColor: theme.palette.input.background,
    color: theme.palette.input.font
  }),
  input: (base, state) => ({
    ...base,
    backgroundColor: theme.palette.input.background,
    color: theme.palette.input.font,
    textAlign: theme.language === HE_LANG ? "right" : "left"
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: theme.palette.input.background,
    borderColor: theme.palette.input.border,
    color: theme.palette.input.font,
    borderTop: `1px solid ${theme.palette.input.border}`,
    textAlign: theme.language === HE_LANG ? "right" : "left"
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none"
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: "none"
  }),
  control: (base) => ({
    ...base,
    fontFamily: theme.typography.mainFont,
    fontSize: "14px",
    backgroundColor: theme.palette.input.background,
    color: theme.palette.input.font,
    border: `1px solid ${theme.palette.input.border}`,
    textAlign: theme.language === HE_LANG ? "right" : "left"
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: theme.typography.mainFont,
    fontSize: "14px",
    color: theme.palette.input.placeholder,
    fontWeight: 700,
    backgroundColor: theme.palette.input.background
  })
});

const WebLocationSearch = ({
  language,
  value,
  onPress,
  selectProps = {},
  placeholder,
  style = {}
}) => {
  const onChange = async (props = {}, triggeredAction) => {
    console.log(
      "WebLocationSearch onChange ",
      props,
      " triggeredAction ",
      triggeredAction
    );
    if (triggeredAction?.action === "clear") {
      onPress(null);
    } else if (props) {
      const { label, value } = props;
      console.log("WebLocationSearch onChange ", value);

      const latLang = await geocodeByPlaceId(value.place_id);
      const result = JSON.parse(JSON.stringify(latLang));
      const val = result?.length > 0 ? result[0]?.geometry?.location : {};
      const location = {
        lat: val.lat,
        lng: val.lng
      };
      if (onPress) {
        onPress({ ...value, location });
      }
    }
  };

  const clearValue = (data) => {
    console.log("clearValue ", data);
  };

  const theme = useTheme();
  return (
    <GooglePlacesAutocomplete
      apiKey={GOOGLE_API_KEY}
      apiOptions={{ language: language || DEFAULT_LANG }}
      fetchDetails={true}
      GooglePlacesDetailsQuery={{ fields: "geometry/location" }}
      selectProps={{
        defaultInputValue: value?.description,
        isClearable: true,
        onChange,
        fetchDetails: true,
        clearValue,
        GooglePlacesDetailsQuery: { fields: "geometry/location" },
        styles: {
          ...customStyles(theme),
          container: (base) => ({
            ...base,
            ...style
          }),
          dir: "auto"
        },
        placeholder,
        dir: "auto",
        ...selectProps
      }}
    />
  );
};

export default memo(WebLocationSearch);
