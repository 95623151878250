import React, { useMemo, useState } from "react";
import styled from "styled-components/native";
import BaseText from "./base-text";
import moment from "moment";

const StyledDate = styled(BaseText)`
  font-size: 12px;
`;

const DAY_FORMAT = "MMMM D, YYYY";
const TIME_DAY_FORMAT = "MMMM D, YYYY HH:MM";

export default function Date({ children, isTime, ...rest }) {
  if (!children) {
    return null;
  }
  const date = useMemo(() => {
    return moment(children).format(isTime ? TIME_DAY_FORMAT : DAY_FORMAT);
  }, [children]);
  return <StyledDate {...rest}>{date}</StyledDate>;
}
