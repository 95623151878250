import React from "react";
import styled from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import BaseText from "../basic/base-text";
import { useTranslation } from "react-i18next";
import { baseFlexRow, marginRightLeft } from "../styles/global-styles";

const StyledContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${baseFlexRow}
`;

const StyledIcon = styled(MaterialIcons)`
  color: ${(props) => props.theme.palette.font.primary};
  ${marginRightLeft(8)}
`;

const StyledText = styled(BaseText)`
  font-size: 12px;
`;

export default function RequestSentIcon({ refundId, ...rest }) {
  const { t } = useTranslation();

  const isRequestSent = useSelector((state) => state?.userRequests?.ids[refundId]);
  if (!isRequestSent) {
    return null;
  }

  return (
    <StyledContainer {...rest}>
      <StyledIcon name="outgoing-mail" size={20} />
      <StyledText>{t("Request sent")}</StyledText>
    </StyledContainer>
  );
}
