import { getUserFullName } from "./core-utils";

export const createThreadId = ({ fromUserId, toUserId, refundId }) => {
  if (fromUserId && toUserId) {
    let id = [fromUserId, toUserId].sort().join("_");
    if (refundId) {
      id += `_${refundId}`;
    }
    return id;
  } else {
    return null;
  }
};

export const MAX_TITLE_LENGTH = 25;

export const getChatRefundName = (name) => {
  if (!name) {
    return null;
  }
  let res = name;
  if (name.length > MAX_TITLE_LENGTH) {
    res = `${name.substring(0, MAX_TITLE_LENGTH)}...`;
  }
  return res;
};

export const getChatRefundTitle = ({ user, refundTitle }) => {
  const refundName = getChatRefundName(refundTitle);
  const res = `${refundName} - ${user?.firstName} ${user?.lastName}`;
  return res;
};

export const getChatUserTitle = (user) => {
  return getUserFullName(user);
};
