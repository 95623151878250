import * as React from "react";
import { isWeb } from "../../utils/core-utils";
import WebLocationSearch from "./web/web-location-search";
import MobileLocationSearch from "./mobile/mobile-location-search";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isRightToLeft } from "../../../i18n";

export default function LocationSearch({
  value,
  selectProps = {},
  onChange,
  placeholder,
  style = {}
}) {
  const language = useSelector((state) => state.settings?.language);
  const { t } = useTranslation();
  const rightToLeft = isRightToLeft(language);

  const placeholderValue = placeholder || t("Search...");

  const onPress = (data) => {
    console.log("LocationSearch data ", data ? JSON.stringify(data) : data);
    if (onChange) {
      onChange(data);
    }
  };
  if (isWeb()) {
    return (
      <WebLocationSearch
        language={language}
        onPress={onPress}
        selectProps={selectProps}
        placeholder={placeholderValue}
        style={{ ...style }}
        value={value}
      />
    );
  } else {
    return (
      <MobileLocationSearch
        language={language}
        onPress={onPress}
        selectProps={selectProps}
        placeholder={placeholderValue}
        style={{ ...style }}
        value={value}
      />
    );
  }
}
