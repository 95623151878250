import React from "react";
import * as yup from "yup";

export const emailValidation = {
  email: yup
    .string()
    .email()
    .required("Please enter the required field")
  // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
};

export const emailPasswordValidation = {
  email: yup
    .string()
    .email()
    .required("Please enter the required field"),
  // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  password: yup
    .string()
    .required("Please provide password.")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .min(8, "Password is too short - should be 8 chars minimum.")
};

export const emailPasswordSchema = yup.object({
  ...emailPasswordValidation
});

export const forgotPasswordSchema = yup.object({
  email: emailPasswordValidation.email
});

export const registerFormSchema = yup.object({
  ...emailPasswordValidation,
  firstName: yup
    .string()
    .required("Please provide first name")
    .min(2),
  lastName: yup.string()
});

export const resetPasswordSchema = yup.object({
  code: yup
    .number("Code is a number")
    .typeError("Invalid code")
    .required("Please provide valid code"),
  password: emailPasswordValidation.password
});

export const contactUsSchema = yup.object({
  email: emailPasswordValidation.email,
  name: yup.string().required("Please provide name"),
  subject: yup.string().required("Please provide subject"),
  message: yup
    .string()
    .min(10)
    .max(1000)
    .required("Please provide message min 10 chars max 1000 chars")
});

export const editUserFormSchema = yup.object({
  email: emailPasswordValidation.email,
  firstName: yup
    .string()
    .required("Please provide first name")
    .min(2),
  lastName: yup.string()
});
