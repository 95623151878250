import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { currencyFormat } from "../utils/currency-utils";
import { useSelector } from "react-redux";

export default function useCardPriceTitle({ item = {} }) {
  const { t } = useTranslation();
  const currency = useSelector((state) => state.settings?.currency);
  const { price, refundAmount, priceCurrencyCode, refundAmountCurrencyCode } = item;
  const priceCurrency = priceCurrencyCode || currency;
  const refundAmountCurrency = refundAmountCurrencyCode || currency;

  const formattedPrice = useMemo(() => {
    const res = currencyFormat(price, priceCurrency);
    return res;
  }, [price, priceCurrency]);

  const formattedRefundAmount = useMemo(() => {
    const res = currencyFormat(refundAmount, refundAmountCurrency);
    return res;
  }, [refundAmount, refundAmountCurrency]);

  const discountPercentage = useMemo(() => {
    return refundAmount
      ? Number(100 - (price / refundAmount) * 100).toFixed(2)
      : null;
  }, [refundAmount, price]);

  const discountDisplay =
    discountPercentage >= 0
      ? `${discountPercentage}% ${t("off")}`
      : `${Math.abs(discountPercentage)}% ${t("over")}`;

  return [
    formattedPrice,
    formattedRefundAmount,
    discountPercentage,
    discountDisplay
  ];
}
