import React, { useEffect } from "react";
import { SocketContext } from "./socket-context";
import useChatServer from "../../hooks/use-chat-server";
import { useDispatch, useSelector } from "react-redux";
import { receiveSocketMessages } from "../../redux/thunks/chats-thunk";
import { getUserFullName } from "../../utils/core-utils";

export const SocketProvider = ({ children }) => {
  const userId = useSelector((state) => state.auth?.user?.id);
  const users = useSelector((state) => state.auth?.users);

  const [socket, sendMessage, connected] = useChatServer({
    userId
  });
  const dispatch = useDispatch();
  //
  // function getUserObject(id) {
  //   const user = users[id];
  //   if (!user) {
  //     console.error("user not found");
  //     return null;
  //   }
  //   const result = {
  //     _id: user.id,
  //     ...user,
  //     name: getUserFullName(user)
  //   };
  //   return result;
  // }

  // const convertSocketResponseToMessage = (message) => {
  //   if (
  //     !message ||
  //     !message.id ||
  //     !message.text ||
  //     !message.createdAt ||
  //     !message.fromUserId
  //   ) {
  //     return null;
  //   }
  //   const user = getUserObject(message.fromUserId);
  //   if (!user) {
  //     return null;
  //   }
  //   return {
  //     _id: message.id,
  //     text: message.text,
  //     createdAt: new Date(message.createdAt),
  //     user
  //   };
  // };

  // const receiveSocketMessagesChat = useCallback(
  //   (socketResponse) => {
  //     console.log("chat receiveSocketMessages socketResponse ", socketResponse);
  //     if (!socketResponse) {
  //       return;
  //     }
  //     const { threadId } = socketResponse || {};
  //     if (!threadId) {
  //       return;
  //     }
  //
  //     if (messages && socketResponse) {
  //       if (id === threadId) {
  //         console.log(
  //           "chat receiveSocketMessages same thread id socketResponse ",
  //           socketResponse
  //         );
  //
  //         const found = messages
  //           ? messages.filter((item) => item._id === socketResponse.id)
  //           : null;
  //         if (found && found.length > 0) {
  //           return;
  //         }
  //         if (toUser !== null && toUser.id === socketResponse.fromUserId) {
  //           const item = convertSocketResponseToMessage(socketResponse);
  //           if (item) {
  //             setMessages(messages ? GiftedChat.append(messages, [item]) : [item]);
  //             dispatch(
  //               updateUnreadMessagesToRead({ threadId, ids: [socketResponse.id] })
  //             );
  //           }
  //         }
  //       }
  //     }
  //   },
  //   [messages, setMessages, toUser, threadId]
  // );

  useEffect(() => {
    // socket.on("logout-response", (data) => {
    //   if (onLogoutResponse) {
    //     onLogoutResponse(data);
    //   }
    // });

    if (socket) {
      socket.on("add-message-response", (data) => {
        console.log("socket provider receiveSocketMessages");
        dispatch(receiveSocketMessages(data));
      });
    }

    // socket.on("chat-list-response", (data) => {
    //   if (onChatListResponse) {
    //     onChatListResponse(data);
    //   }
    // });
  }, [socket, receiveSocketMessages]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        sendMessage,
        connected
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
