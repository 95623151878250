import React, { useState, useEffect } from "react";
import { Platform } from "react-native";
import * as ImagePicker from "expo-image-picker";
import { useSelector, useDispatch } from "react-redux";
import { handleUploadWeb, handleUploadMobile } from "./upload-files";
import { isWeb } from "../../utils/core-utils";
import { useTranslation } from "react-i18next";
import { showError } from "../../redux/actions/core-actions";

export default function useAddImage({ onAdd, setLoading, isUpload, isChat }) {
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.user);
  const { t } = useTranslation();

  //const [loading, url] = useUploadImage({ image });

  // console.log("uploaded url ", url);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        try {
          console.log("start requestMediaLibraryPermissionsAsync");
          const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
          console.log("finish requestMediaLibraryPermissionsAsync ", status);
          if (status !== "granted") {
            alert(t("Sorry, we need camera roll permissions to make this work!"));
          }
        } catch (e) {
          console.error("failed requestMediaLibraryPermissionsAsync", e);
        }
      }
    })();
  }, []);

  // useEffect(() => {
  //   if (url && onAdd) {
  //     onAdd(url);
  //     //setImage(null);
  //   }
  // }, [url]);

  // useEffect(() => {
  //   setLoading(loading);
  // }, [loading]);

  const handleUploadImage = async (image) => {
    console.log("AddImage ", image);
    // handleUpload({ image, setLoading, user }).then((url) => {
    //   console.log("uploaded url ", url);

    //   onAdd(url);
    // });
    // if (isChat) {
    //   const url =
    //     "https://refundme-images.s3.us-east-2.amazonaws.com/610058a7d0b7e11ca4b44965_adad7496-cfd6-4f7a-9863-aa62a5b3011d.jpeg";
    //   onAdd(url);
    //   return;
    // }

    if (image) {
      try {
        if (isWeb()) {
          const url = await handleUploadWeb({ image, setLoading, user });
          console.log("uploaded url ", url);

          onAdd(url);
        } else {
          const url = await handleUploadMobile({ image, setLoading, user });
          console.log("uploaded url ", url);

          onAdd(url);
        }
      } catch (e) {
        console.error("failed to upload image ", e);
        dispatch(showError("Image upload failed"));
      }
    }
  };

  const pickImage = async () => {
    try {
      //setLoading(true);
      const params = {
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1
      };
      let result = await ImagePicker.launchImageLibraryAsync(params);
      //setLoading(false);

      console.log("ImagePicker result ", result);

      if (!result.cancelled) {
        if (isUpload) {
          handleUploadImage(result);
        } else {
          onAdd(result);
        }
      }
    } catch (e) {
    } finally {
      //setLoading(false);
    }
  };
  return { pickImage };
}
