import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Menu, Divider, Provider } from "react-native-paper";
import MenuButton from "../../components/basic/menu/menu-button";
import { deleteRefund } from "../../redux/thunks/refunds-thunk";

import { useDispatch } from "react-redux";

export default function RefundItemMenu({ refundId, className, style = {} }) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const hideMenu = () => setVisible(false);
  const showMenu = () => setVisible(true);
  const dispatch = useDispatch();

  const onClickDelete = () => {
    dispatch(
      deleteRefund({
        id: refundId
      })
    );
  };

  const onClickEdit = () => {};

  const onClickShare = () => {};
  return (
    <Menu
      visible={visible}
      style={{ height: 20, borderRadius: 0, padding: 0 }}
      onDismiss={hideMenu}
      anchor={<MenuButton onPress={showMenu} className={className} />}
    >
      <Menu.Item
        style={{ borderRadius: 0 }}
        onPress={onClickEdit}
        icon="application-edit-outline"
        title={t("Edit")}
      />
      <Divider />
      {/*<Menu.Item onPress={onClickShare} icon="share" title={t("Share")} />*/}
      {/*<Divider />*/}
      {/*<Menu.Item onPress={() => {}} icon="pause" title={t("Pending")} />*/}
      {/*<Divider />*/}
      <Menu.Item onPress={onClickDelete} icon="delete" title={t("Delete")} />
    </Menu>
  );
}
