import React from "react";
import Main from "./src/main/main";
import { Provider } from "react-redux";
import store from "./src/redux/store";
import { ENV } from "./src/config/config";

console.log("ENV ", ENV);
export default function App() {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
}
