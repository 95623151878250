import React from "react";
import BaseText from "./basic/base-text";
import { useTranslation } from "react-i18next";
import { StyledLayout } from "./styles/screen-layout";
import styled from "styled-components/native";
import { SimpleLineIcons } from "@expo/vector-icons";
import { useTheme } from "styled-components/native";

const StyledContainer = styled(StyledLayout)`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const StyledText = styled(BaseText)`
  margin-left: 8px;
  font-weight: 600;
`;

const StyledMessage = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default function NoItemsFound({ text }) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <StyledContainer>
      <StyledMessage>
        <SimpleLineIcons
          name="social-dropbox"
          size={29}
          color={theme.palette.font.primary}
        />
        <StyledText>{text || t("No items found")}</StyledText>
      </StyledMessage>
    </StyledContainer>
  );
}
