import React, { useMemo } from "react";
import { StyledLayout, marginTop } from "../../components/styles/screen-layout";
import Notifications from "./notifications/notifications";
import Messages from "./messages/messages";
import { SceneMap } from "react-native-tab-view";
import Tabs from "../../components/tabs";
import BuyRequests from "./buy-requests";
import useMessagesCount from "../../hooks/use-messages-count";
import useNotificationsCount from "../../hooks/use-notifications-count";
import { useDispatch, useSelector } from "react-redux";
import { changeMessagesTab } from "../../redux/actions/message-actions";
import useBuyRequestsCount from "../../hooks/use-buy-requests-count";
import { useTranslation } from "react-i18next";
import styled from "styled-components/native";

const StyledView = styled(StyledLayout)`
  margin-top: ${marginTop}px;
`;

export default function MessagesScreen(props) {
  const { t } = useTranslation();
  const messagesCount = useMessagesCount();
  const notificationsCount = useNotificationsCount();
  const buyRequestsCount = useBuyRequestsCount();
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.messages.activeTab) || 0;
  console.log("activeTab ", activeTab);
  console.log("messagesCount ", messagesCount);

  const data = useMemo(() => {
    const items = [
      {
        key: "buy-requests",
        title: t("Requests"),
        comp: BuyRequests,
        badge: buyRequestsCount,
        tabId: 0
      },
      {
        key: "notifications",
        title: t("Notifications"),
        comp: Notifications,
        badge: notificationsCount,
        tabId: 1
      },
      {
        key: "messages",
        title: t("Messages"),
        comp: Messages,
        badge: messagesCount,
        tabId: 2
      }
    ];
    const keyValues = {};
    const routes = [];
    items.forEach((item) => {
      const { comp: TabComponent, key, title, badge, tabId } = item;
      routes.push({ key, title, badge, tabId });
      keyValues[key] = () => <TabComponent {...props} />;
    });

    const renderScene = SceneMap(keyValues);
    return {
      renderScene,
      routes
    };
  }, [buyRequestsCount, messagesCount, notificationsCount]);

  const onTabChange = (index) => {
    dispatch(changeMessagesTab(index));
  };

  return (
    <StyledView>
      <Tabs
        routes={data.routes}
        renderScene={data.renderScene}
        onTabChange={onTabChange}
        activeTab={activeTab}
      />
    </StyledView>
  );
}
