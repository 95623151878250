import { css } from "styled-components/native";
import { Platform, StyleSheet } from "react-native";
import { HE_LANG } from "../../../i18n";
import { isWeb } from "../../utils/core-utils";

export const globalStyles = StyleSheet.create({
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
});

export const backdrop = (theme) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  alignItems: "center",
  justifyContent: "center",
  opacity: 0.5,
  backgroundColor: theme.palette.background.primary
});

export const flexJustifyRightLeftItem = () => css`
  ${textDirection}
`;

export const flexDirection = () => css`
  flex-direction: ${(props) =>
    props.theme.language === HE_LANG ? "row-reverse" : "row"};
`;

export const textDirection = () => css`
  text-align: ${(props) => (props.theme.language === HE_LANG ? "right" : "left")};
`;

export const baseFlexRow = () => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${flexDirection()}
`;

export const spaceBetweenFlexRow = () => css`
  justify-content: space-between;
  ${baseFlexRow()}
`;

export const marginRightLeft = (num) => {
  if (!num) {
    return "";
  } else if (num === "auto") {
    return css`
      ${(props) =>
        props.theme.language === HE_LANG
          ? `margin-left: auto;`
          : `margin-right: auto;`}
    `;
  } else {
    return css`
      ${(props) =>
        props.theme.language === HE_LANG
          ? `margin-left:${num}px;`
          : `margin-right:${num}px;`}
    `;
  }
};

export const marginLeftRight = (num) => {
  if (!num) {
    return "";
  } else if (num === "auto") {
    return css`
      ${(props) =>
        props.theme.language === HE_LANG
          ? `margin-right: auto;`
          : `margin-left: auto;`}
    `;
  } else {
    return css`
      ${(props) =>
        props.theme.language === HE_LANG
          ? `margin-right:${num}px;`
          : `margin-left:${num}px;`}
    `;
  }
};

export const marginLeftRightStyle = (theme, num) => {
  if (!num) {
    return {};
  } else {
    return theme.language === HE_LANG ? { marginRight: num } : { marginLeft: num };
  }
};

export const marginRightLeftStyle = (theme, num) => {
  if (!num) {
    return {};
  } else {
    return theme.language === HE_LANG ? { marginLeft: num } : { marginRight: num };
  }
};

export const flexStartEnd = () => css`
  justify-content: ${(props) =>
    props.theme.language === HE_LANG ? "flex-end" : "flex-start"};
`;

export const textAlignRightLeft = () => css`
  text-align: ${(props) => (props.theme.language === HE_LANG ? "right" : "left")};
`;

export const rightToLeftProps = (props) => {
  if (props.theme.language === HE_LANG) {
    return {
      unicodeBidi: "bidi-override",
      direction: "RTL"
    };
  } else {
    return {};
  }
};

export const searchMarginTop = isWeb() ? 0 : Platform.OS === "ios" ? 20 : 0;
