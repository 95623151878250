import React, { useState } from "react";
import DatePickerButton from "./date-picker-button";
import WebCalendar from "./web-calendar";

export default function WebDatePicker({ onChange, value }) {
  const [show, setShow] = useState(false);

  const handleOnChange = (date) => {
    console.log("WebDatePicker handleOnChange ", date);
    onChange(date);
    setShow(false);
  };

  const onOpen = () => {
    setShow(true);
  };

  const onClose = () => {
    setShow(false);
  };

  return (
    <>
      <DatePickerButton date={value} onPress={onOpen} />
      <WebCalendar
        onChange={handleOnChange}
        value={value}
        isVisible={show}
        onClose={onClose}
      />
    </>
  );
}
