import React, { useMemo } from "react";
import { marginTop, StyledLayout } from "../../components/styles/screen-layout";
import { SceneMap } from "react-native-tab-view";
import Tabs from "../../components/tabs";
import { useDispatch, useSelector } from "react-redux";
import { changeActivityTab } from "../../redux/actions/activity-actions";
import { useTranslation } from "react-i18next";
import MyRefunds from "./selling/my-refunds";
import UserRequestedRefunds from "./buying/user-requested-refunds";
import styled from "styled-components/native";

const StyledView = styled(StyledLayout)`
  margin-top: ${marginTop}px;
`;

export default function ActivityScreen(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.activity.activeTab) || 0;
  console.log("ActivityScreen activeTab ", activeTab);

  const items = [
    {
      key: "buying",
      title: t("Buying"),
      comp: UserRequestedRefunds
    },
    {
      key: "selling",
      title: t("Selling"),
      comp: MyRefunds
    }
  ];

  const data = useMemo(() => {
    const keyValues = {};
    const routes = [];
    items.forEach((item) => {
      const { comp: TabComponent, key, title, badge } = item;
      routes.push({ key, title, badge });
      keyValues[key] = () => <TabComponent {...props} />;
    });

    const renderScene = SceneMap(keyValues);
    return {
      renderScene,
      routes
    };
  }, []);

  const onTabChange = (index) => {
    dispatch(changeActivityTab(index));
  };

  return (
    <StyledView>
      <Tabs
        routes={data.routes}
        renderScene={data.renderScene}
        onTabChange={onTabChange}
        activeTab={activeTab}
      />
    </StyledView>
  );
}
