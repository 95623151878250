import useMessagesCount from "./use-messages-count";
import useNotificationsCount from "./use-notifications-count";
import useBuyRequestsCount from "./use-buy-requests-count";

export default function useTotalMessagesCount() {
  const messagesCount = useMessagesCount();
  const notificationsCount = useNotificationsCount();
  const buyRequestsCount = useBuyRequestsCount();

  return messagesCount + notificationsCount + buyRequestsCount;
}
