import { useDispatch, useSelector } from "react-redux";
import { isWeb } from "../utils/core-utils";
import { actionTypes } from "../redux/actions/action-types";
import { DEFAULT_LANG, initLanguage, LANGUAGES } from "../../i18n";
import { useEffect } from "react";
import useUser from "./use-user";

export function useInitLanguage() {
  const language = useSelector((state) => state.settings?.language);
  const dispatch = useDispatch();
  const [isLoggedIn] = useUser();
  const isLoaded = useSelector((state) => state.auth?.isLoaded);

  const getBrowserLanguage = () => {
    let value = DEFAULT_LANG;
    if (!isWeb()) {
      return;
    }
    try {
      const userLang = navigator.language || navigator.userLanguage;
      console.log("userLang ", userLang);
      if (userLang) {
        const parseLng = userLang.split("-")[0];
        if (LANGUAGES.includes(parseLng)) {
          value = parseLng;
        }
      }
      if (value !== language) {
        console.log("change language ", value);
        dispatch({
          type: actionTypes.LANG_SWITCH,
          payload: { language: value }
        });
      }
    } catch (e) {
      console.log("failed to get browser language ", e);
    }
  };

  useEffect(() => {
    if (language) {
      initLanguage(language);
    }
  }, [language]);

  useEffect(() => {
    if (isLoaded && !isLoggedIn) {
      getBrowserLanguage(language);
    }
  }, [isLoaded, isLoggedIn, language]);
}
