import { actionTypes } from "../actions/action-types";

const initialState = {
  features: {},
  appLoader: false,
  isAppLoaded: false,
  isDrawerOpen: true,
  customerService: null,
  activeTab: "Home",
  notification: null,
  modals: {}
};

export const NOTIFICATION_TYPE = {
  ERROR: "ERROR",
  WARNING: "WARNING",
  INFO: "INFO",
  SUCCESS: "SUCCESS"
};

const coreReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.FETCH_FEATURES: {
      const { features } = payload;
      console.log("features ", features);
      return {
        ...state,
        features
      };
    }
    case actionTypes.TOGGLE_APP_LOADER: {
      const { loading } = payload;
      return {
        ...state,
        appLoader: loading
      };
    }
    case actionTypes.SET_APP_LOADED: {
      return {
        ...state,
        isAppLoaded: payload
      };
    }
    case actionTypes.DRAWER_TOGGLE: {
      return {
        ...state,
        isDrawerOpen: payload
      };
    }
    case actionTypes.SHOW_ERROR: {
      const { message } = payload;
      return {
        ...state,
        notification: {
          type: NOTIFICATION_TYPE.ERROR,
          message: message
        }
      };
    }
    case actionTypes.SHOW_NOTIFICATION: {
      const { message, type } = payload;
      return {
        ...state,
        notification: {
          type,
          message
        }
      };
    }
    case actionTypes.HIDE_NOTIFICATION: {
      return {
        ...state,
        notification: null
      };
    }
    case actionTypes.SET_CUSTOMER_SERVICE: {
      return {
        ...state,
        customerService: payload
      };
    }
    case actionTypes.SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: payload
      };
    }
    case actionTypes.TOGGLE_MODAL: {
      const { modal, data, open } = payload;
      return {
        ...state,
        modals: {
          ...state.modals,
          [modal]: {
            data,
            open
          }
        }
      };
    }
    case actionTypes.LOGOUT: {
      return { ...initialState, features: state.features };
    }
    default:
      return state;
  }
};

export default coreReducer;
