import { actionTypes } from "../actions/action-types";

const initialState = {
  items: [],
  isLoading: false,
  searchText: ""
};

const searchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_SEARCHES: {
      return {
        ...state,
        items: payload,
        isLoading: false,
        isLoaded: true
      };
    }
    case actionTypes.SET_SEARCHES_LOADING: {
      return {
        ...state,
        isLoading: payload
      };
    }
    case actionTypes.ADD_SEARCH_QUERY: {
      const found = state.items.find((item) => item.text === payload);
      const index = found ? state.items.indexOf(found) : -1;

      if (found && index > 0) {
        state.items.splice(index, 1);
        return {
          ...state,
          items: [found, ...state.items]
        };
      } else {
        return {
          ...state,
          items: [{ text: payload, id: payload }, ...state.items]
        };
      }
    }
    case actionTypes.SET_SEARCH_QUERY: {
      return {
        ...state,
        searchText: payload
      };
    }
    case actionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default searchReducer;
