import React, { useState } from "react";
import { StyleSheet, Text, View, Platform } from "react-native";
import styled from "styled-components/native";
import { StyledLayout } from "../../components/styles/screen-layout";
import BaseText from "../../components/basic/base-text";
import Title from "../../components/basic/title";
import { useTranslation } from "react-i18next";

const StyledView = styled.View`
  margin: 0 auto;
  max-width: 80%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

const StyledTitle = styled(Title)`
  font-size: 16px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;

export default function About() {
  const { t } = useTranslation();

  return (
    <StyledLayout>
      <StyledView>
        <BaseText>{t("about_paragraph1")}</BaseText>
      </StyledView>
    </StyledLayout>
  );
}
