import React from "react";
import styled from "styled-components/native";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import {
  COUPON,
  getIconByType,
  GIFT_CARD,
  OTHER,
  STORE_REFUND,
  TICKET,
  VOUCHER
} from "../../utils/refund-types-utils";
import { marginRightLeft } from "../styles/global-styles";

const StyledIcon = styled(MaterialIcons)`
  color: ${(props) => props.color || props.theme.palette.font.primary};
`;

const StyledCommunityIcon = styled(MaterialCommunityIcons)`
  color: ${(props) => props.color || props.theme.palette.font.primary};
`;

export default function RefundTypeIcon({ type, size = 20, color, style }) {
  switch (type) {
    case OTHER:
    case COUPON:
    case VOUCHER:
    case TICKET: {
      return (
        <StyledCommunityIcon
          name={getIconByType(type)}
          size={size}
          color={color}
          style={style}
        />
      );
    }
    case STORE_REFUND:
    case GIFT_CARD: {
      return (
        <StyledIcon
          name={getIconByType(type)}
          size={size}
          color={color}
          style={style}
        />
      );
    }
    default: {
      return (
        <StyledIcon
          name={getIconByType(type)}
          size={size}
          color={color}
          style={style}
        />
      );
    }
  }
}
