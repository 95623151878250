import React from "react";
import BadgedLabelIcon from "../../components/basic/badged-label-icon";
import AppLogo from "../../components/basic/icons/app-logo";
import styled, { useTheme } from "styled-components/native";

const getIconName = (route) => {
  const res = {};
  switch (route) {
    case "Home":
      res.name = "home";
      break;
    case "Search":
      res.name = "search1";
      break;
    case "Messages":
      res.name = "message1";
      break;
    case "About":
      res.name = "infocirlceo";
      break;
    case "Favorites":
      res.name = "hearto";
      break;
    case "Account":
      res.name = "user";
      break;
    case "Activity":
      res.name = "activity";
      res.designType = "Feather";
      break;
    default:
      break;
  }
  return res;
};

export default function TabsIcon({ routeName, active, count, ...rest }) {
  let iconData = getIconName(routeName);
  const theme = useTheme();
  if (!iconData?.name) {
    return null;
  }
  const { name, designType } = iconData;
  if (name === "home") {
    return (
      <AppLogo
        color={active ? theme.palette.icons.active : theme.palette.icons.primary}
      />
    );
  }
  return (
    <BadgedLabelIcon
      iconName={name}
      active={active}
      badge={count}
      designType={designType}
      {...rest}
    />
  );
}
