import React from "react";
import styled from "styled-components/native";
import { useTranslation } from "react-i18next";
import Title from "../../components/basic/title";
import { ALERT_STATUSES, SOLD } from "../../components/refund/refund-status";

const StyledStatus = styled(Title)`
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: ${(props) =>
    ALERT_STATUSES.includes(props.status)
      ? props.theme.palette.price.red
      : props.theme.palette.price.green};
`;

export default function RefundStatus({ status }) {
  const { t } = useTranslation();

  if (status === SOLD) {
    return <StyledStatus status={status}>{t(status)}</StyledStatus>;
  }

  if (!status) {
    return null;
  }

  return <StyledStatus status={status}>{t(status)}</StyledStatus>;
}
