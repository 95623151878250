import React, { useState } from "react";
import { Text } from "react-native";
import AppLoading from "expo-app-loading";
import AppNavigator from "../navigation/app-navigator";
import ThemeProvider from "../components/styles/theme-provider";
import { extendTheme, NativeBaseProvider } from "native-base";
import { useSelector } from "react-redux";
import themes from "../components/styles/themes";
import useInitializeData from "../hooks/use-initialize-data";
// import { loadFonts } from "../utils/core-utils";
import "react-native-gesture-handler";
import { Provider } from "react-native-paper";
import { useBreakpoints } from "../hooks/use-breakpoints";
import { SocketProvider } from "../context/socket";
import { useFonts, NunitoSans_400Regular } from "@expo-google-fonts/nunito-sans";

export default function Main() {
  // const [isFontLoaded, setIsFontLoaded] = useState(false);
  const theme = useSelector((state) => state.settings?.theme);
  const language = useSelector((state) => state.settings?.language);
  const isUserLoaded = useSelector((state) => state.auth?.isLoaded);
  const isDrawerOpen = useSelector((state) => state.core.isDrawerOpen);
  const nativeBaseTheme = extendTheme({
    config: {
      useSystemColorMode: false,
      initialColorMode: theme
    }
  });
  console.log("theme ", theme);

  useInitializeData();

  const media = useBreakpoints();

  let [fontsLoaded] = useFonts({
    NunitoSans_400Regular
  });

  if (!fontsLoaded || !isUserLoaded) {
    return <AppLoading />;
  }
  // Text.defaultProps = Text.defaultProps || {};
  // Text.defaultProps.style = { fontFamily: NunitoSans_400Regular };

  return (
    <Provider>
      <ThemeProvider theme={{ ...themes[theme], language, media }}>
        <NativeBaseProvider theme={nativeBaseTheme}>
          <SocketProvider>
            <AppNavigator />
          </SocketProvider>
        </NativeBaseProvider>
      </ThemeProvider>
    </Provider>
  );
}
