import React, { useState } from "react";
import styled from "styled-components/native";
import { useSelector } from "react-redux";
import useTotalMessagesCount from "../../hooks/use-total-messages-count";
import useUser from "../../hooks/use-user";
import BottomButton from "./bottom-button";
import { layoutWebWidth } from "../../components/styles/screen-layout";

const StyledWrapper = styled.View`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.primary};
  width: 100%;
  border-top-color: ${(props) => props.theme.palette.separator};
  border-width: 1px;
`;

const StyledContainer = styled.View`
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  ${layoutWebWidth}
`;

function BottomBar({ state, descriptors, navigation }) {
  const isAppLoaded = useSelector((state) => state.core.isAppLoaded);
  const totalMessagesCount = useTotalMessagesCount();
  const [routeName, setRouteName] = useState();
  const [isLoggedIn] = useUser();

  const onClick = (name) => {
    setRouteName(name);
  };
  return (
    <StyledWrapper>
      <StyledContainer>
        {state.routes.map((route, index) => (
          <BottomButton
            route={route}
            index={index}
            state={state}
            descriptors={descriptors}
            navigation={navigation}
            onClick={onClick}
            routeName={routeName}
            totalMessagesCount={totalMessagesCount}
            isLoggedIn={isLoggedIn}
            isAppLoaded={isAppLoaded}
            key={route.name}
          />
        ))}
      </StyledContainer>
    </StyledWrapper>
  );
}

export default BottomBar;
