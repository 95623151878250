import React, { useState } from "react";
import { StyleSheet, Text, View, Platform } from "react-native";
import styled from "styled-components/native";
import { globalStyles } from "../../components/styles/global-styles";
import { useTranslation } from "react-i18next";
import { StyledLayout } from "../../components/styles/screen-layout";
import BaseText from "../../components/basic/base-text";

const StyledView = styled.View`
  height: 100px;
  width: 100%;
`;

export default function History() {
  return (
    <StyledLayout style={globalStyles.centered}>
      <BaseText>History</BaseText>
    </StyledLayout>
  );
}
