import React from "react";
import { View, ActivityIndicator } from "react-native";
import { backdrop } from "../styles/global-styles";
import { useTheme } from "styled-components/native";

export default function Loader() {
  const theme = useTheme();
  return (
    <View style={backdrop(theme)}>
      <ActivityIndicator size="large" style={{ backgroundColor: "transparent" }} />
    </View>
  );
}
