import React from "react";
import styled from "styled-components/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import IconButton from "../buttons/icon-button";
import { useTheme } from "styled-components/native";

const StyledIconButton = styled(IconButton)`
  height: 20px;
  width: 20px;
  background-color: ${(props) => props.theme.palette.background.primary};
`;

export default function MenuButton({ onPress }) {
  const theme = useTheme();
  return (
    <StyledIconButton onPress={onPress}>
      <MaterialCommunityIcons
        name="dots-vertical"
        size={24}
        color={theme.palette.button.primary.color}
      />
    </StyledIconButton>
  );
}
