import React, { useState } from "react";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import { GOOGLE_API_KEY } from "../constants";
import { DEFAULT_LANG, HE_LANG } from "../../../../i18n";
import { useTheme } from "styled-components/native";
import ClearButton from "../clear-button";

const MobileLocationSearch = ({
  value,
  onPress,
  language,
  placeholder,
  style = {},
  currentLocation,
  ...rest
}) => {
  // const [selectedValue, setSelectedValue] = useState(value);
  console.log("MobileLocationSearch value ", value);
  console.log("MobileLocationSearch value?.description ", value?.description);

  const [inputText, setInputText] = useState(null);
  console.log("MobileLocationSearch inputText ", inputText);

  const theme = useTheme();
  //
  // useEffect(() => {
  //   if (!value?.description) {
  //     setInputText(value?.description);
  //   }
  // }, [value]);

  const onChange = async (data, details = null) => {
    console.log("MobileLocationSearch onChange ", data, details);

    const result = details?.geometry.location;
    const location = {
      lat: result.lat,
      lng: result.lng
    };
    setInputText(data.description);

    if (onPress) {
      onPress({ ...data, location });
      // setSelectedValue(data);
    }
  };

  const onClear = async () => {
    console.log("MobileLocationSearch onClear ");
    setInputText("");

    if (onPress) {
      onPress(null);
    }
  };

  return (
    <GooglePlacesAutocomplete
      placeholder={placeholder}
      GooglePlacesDetailsQuery={{ fields: "geometry/location" }}
      currentLocation={currentLocation}
      currentLocationLabel={currentLocation ? "Current location" : ""}
      fetchDetails={true}
      onPress={onChange}
      query={{
        key: GOOGLE_API_KEY,
        language: language || DEFAULT_LANG
      }}
      textInputProps={{
        clearButtonMode: "never",
        placeholderTextColor: theme.palette.input.font,
        defaultValue: value?.description || "",
        value: inputText,
        onChangeText: (text) => {
          console.log("MobileLocationSearch onChangeText text ", text);

          if (inputText === null && text === "") {
          } else {
            setInputText(text);
          }
        }
      }}
      keepResultsAfterBlur={true}
      keyboardShouldPersistTaps="handled"
      renderRightButton={() => <ClearButton onPress={onClear} />}
      styles={{
        textInputContainer: {
          borderWidth: 1,
          borderColor: theme.palette.input.border,
          borderRadius: 4,
          backgroundColor: theme.palette.input.backgroundColor,
          color: theme.palette.input.font,
          padding: 0,
          margin: 0,
          width: "100%"
        },
        textInput: {
          color: theme.palette.input.font,
          padding: 0,
          margin: 0,
          height: 30,
          backgroundColor: theme.palette.input.backgroundColor
        },

        listView: {
          color: theme.palette.input.font,
          backgroundColor: theme.palette.input.backgroundColor
        },
        row: {
          color: theme.palette.input.font,
          backgroundColor: theme.palette.input.backgroundColor,
          display: "flex",
          justifyContent: theme.language === HE_LANG ? "flex-end" : "flex-start"
        },

        predefinedPlacesDescription: {
          color: theme.palette.input.font,
          backgroundColor: theme.palette.input.backgroundColor
        },
        description: {
          color: theme.palette.input.font,
          backgroundColor: theme.palette.input.backgroundColor
        },
        powered: {
          color: theme.palette.input.font,
          backgroundColor: theme.palette.input.backgroundColor
        },
        poweredContainer: {
          color: theme.palette.input.font,
          backgroundColor: theme.palette.input.backgroundColor
        },
        ...style
      }}
      {...rest}
    />
  );
};

export default MobileLocationSearch;
