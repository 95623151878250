import React, { useEffect } from "react";
import { StyledLayout } from "../../../components/styles/screen-layout";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components/native";
import MessagesGenericList from "../messages-generic-list";
import Loader from "../../../components/loaders/loader";
import {
  fetchUserNotifications,
  updateReadNotifications
} from "../../../redux/thunks/notifications-thunk";
import NotificationCard from "./notification-card";
import NoItemsFound from "../../../components/no-items-found";
import useNotificationsCount from "../../../hooks/use-notifications-count";
import { MessagesTab } from "../../../redux/reducers/messages-reducer";

const StyledView = styled(StyledLayout)`
  padding: 16px 16px 0 16px;
`;

export default function Notifications(props) {
  const { t } = useTranslation();
  const items = useSelector((state) => state.notifications.items) || [];
  const isLoading = useSelector((state) => state.notifications.isLoading);
  const isLoaded = useSelector((state) => state.notifications.isLoaded);
  const unreadCount = useNotificationsCount();
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.messages.activeTab) || 0;
  const isCurrentTab = activeTab === MessagesTab.Notifications;

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      dispatch(fetchUserNotifications());
    }
  }, [isLoaded, isLoading]);

  useEffect(() => {
    if (isCurrentTab && unreadCount > 0 && items?.length > 0) {
      dispatch(updateReadNotifications({ items }));
    }
  }, [unreadCount, items, isCurrentTab]);

  if (!isCurrentTab) {
    return null;
  }

  console.log("Notifications items ", items);

  if (!isLoading && items?.length === 0) {
    return <NoItemsFound text={t("You have no notifications")} />;
  }

  const renderItem = ({ item, index }) => (
    <NotificationCard item={item} isLast={index === items.length - 1} />
  );

  return (
    <StyledView>
      <MessagesGenericList items={items} renderItem={renderItem} />
      {isLoading && <Loader />}
    </StyledView>
  );
}
