import { useRef, useEffect, useState } from "react";
import { io } from "socket.io-client";
const events = require("events");
import "../services/user-agent";
import { ENV } from "../config/config";

const SOCKET_URL = ENV.SOCKET_URL;

export default function useChatServer({ userId }) {
  const socketRef = useRef(null);
  const [connected, setConnected] = useState(false);
  const socket = socketRef?.current;

  function getChatList(userId) {
    if (!socket) {
      return;
    }
    socket.emit("chat-list", {
      userId: userId
    });
  }

  function sendMessage(message) {
    if (!socket) {
      return;
    }
    socket.emit("add-message", message);
  }

  // function receiveMessage() {
  //   if (!socket) {
  //     return;
  //   }
  //   socket.on("add-message-response", (data) => {
  //     if (onAddMessageResponse) {
  //       onAddMessageResponse(data);
  //     }
  //   });
  // }

  function logout(userId) {
    if (!socket) {
      return;
    }
    socket.emit("logout", userId);
  }

  async function connectSocket() {
    try {
      console.log("start establishSocketConnection");
      // const eventEmitter = new events.EventEmitter();
      // emmiterRef.current = eventEmitter;
      const socket = io(SOCKET_URL, {
        // path: "socket",
        query: {
          userId
        },
        // jsonp: false,
        transports: ["websocket"],
        reconnectionAttempts: 15
      });

      if (socket) {
        socket.on("disconnect", () => {
          setConnected(false);
          console.log("client disconnect!");
        });

        socket.on("connect", () => {
          setConnected(true);
          console.log("client connected!");
        });

        socketRef.current = socket;
      }

      console.log("finish establishSocketConnection");
    } catch (error) {
      alert(`Something went wrong; Can't connect to socket server`, error.message);
      console.error(
        `Something went wrong; Can't connect to socket server`,
        error.message
      );
    }
  }

  useEffect(() => {
    if (userId && !connected) {
      connectSocket();
    }
  }, [userId, connected]);

  return [
    socket,
    // eventEmitter,
    sendMessage,
    // getChatList,
    // logout,
    connected
  ];
}
