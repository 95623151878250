import React, { useState } from "react";
import { TextInput, Keyboard } from "react-native";
import styled, { css, useTheme } from "styled-components/native";
import BaseText from "./base-text";
import Error from "../error";
import CurrencyDropdown from "../currency/currency-dropdown";
import { useTranslation } from "react-i18next";
import { isRightToLeft } from "../../../i18n";
import { flexJustifyRightLeftItem } from "../styles/global-styles";
import { Visibility } from "./icons/visibility";
import IconButton from "./buttons/icon-button";

const StyledGroup = styled.View`
  width: 100%;
  display: flex;
  margin-bottom: 16px;
`;

const StyledLabel = styled(BaseText)`
  color: ${(props) => props.theme.palette.input.font};
  ${flexJustifyRightLeftItem}
`;

const StyledError = styled(Error)`
  margin-top: 8px;
`;

export const borderStyle = () => css`
  ${(props) => {
    if (props.variant === "outline") {
      return css`
        border-color: ${(props) => props.theme.palette.input.border};
        border-width: 1px;
        border-radius: 4px;
      `;
    } else if (props.variant === "underline") {
      return css`
        border-bottom-color: ${(props) => props.theme.palette.input.border};
        border-bottom-width: 0.5px;
      `;
    }
  }}
`;

const StyledInput = styled(TextInput)`
  padding: 8px;
  padding-right: ${(props) => (props.secure ? "30px" : "8px")};
  width: ${(props) => (props.isPrice ? "100%" : "100%")};
  margin-right: ${(props) => (props.isPrice ? "20px" : "0")};
  color: ${(props) => props.theme.palette.input.font};
  flex-shrink: 1;
  ${borderStyle}
`;

const StyledContainer = styled.View`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.isPrice ? "row" : "column")};
  justify-content: space-between;
  position: relative;
`;

const StyledSelectCurrency = styled(CurrencyDropdown)`
  margin-left: 16px;
  flex-grow: 1;
  width: 120px;
`;

const StyledVisibility = styled(Visibility)``;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const InputField = ({
  props,
  title,
  name,
  multiline,
  keyboardType,
  isPrice,
  currencyName,
  numberOfLines,
  variant = "outline",
  minHeight,
  onBlur,
  secureTextEntry,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const rightToLeft = isRightToLeft(theme.language);
  const [visible, setVisible] = useState(false);

  const onChange = (value) => {
    if (keyboardType === "numeric") {
      if (isNaN(value)) {
        return;
      }
    }
    props.handleChange(name)(value);
  };

  const handleOnBlur = (e) => {
    if (keyboardType === "numeric") {
      Keyboard.dismiss();
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <StyledGroup>
      <StyledLabel>{title}</StyledLabel>
      <StyledContainer isPrice={isPrice}>
        <StyledInput
          multiline={multiline}
          onChangeText={onChange}
          onBlur={handleOnBlur}
          value={props.values[name] + ""}
          keyboardType={keyboardType}
          isPrice={isPrice}
          numberOfLines={numberOfLines}
          variant={variant}
          minHeight={minHeight}
          blurOnSubmit={false}
          rightToLeft={rightToLeft}
          secure={secureTextEntry}
          secureTextEntry={secureTextEntry && !visible}
          editable={!disabled}
          selectTextOnFocus={!disabled}
          {...rest}
        />
        {secureTextEntry && (
          <StyledIconButton onPress={() => setVisible(!visible)}>
            <StyledVisibility visible={visible} color={theme.palette.separator} />
          </StyledIconButton>
        )}

        {isPrice && (
          <StyledSelectCurrency
            name={currencyName}
            value={props.values[currencyName]}
            onValueChange={(value) => {
              props.setFieldValue(currencyName, value);
              props.setFieldValue("priceCurrencyCode", value);
            }}
            variant="underlined"
            maxWidth={120}
          />
        )}
      </StyledContainer>
      <StyledError>{props.touched[name] && t(props.errors[name])}</StyledError>
    </StyledGroup>
  );
};

export default InputField;
