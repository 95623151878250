import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleInfoModal } from "../../../redux/actions/core-actions";
import { View } from "react-native";
import Title from "../../../components/basic/title";
import BaseText from "../../../components/basic/base-text";
import styled from "styled-components/native";
import { Modal } from "native-base";
import { Close } from "../../../components/basic/icons/close";
import useTheme from "../../../components/styles/use-theme";
import IconButton from "../../../components/basic/buttons/icon-button";
import AppLogo from "../../../components/basic/icons/app-logo";
import RefundTypeIcon from "../../../components/refund/refund-type-icon";
import { AntDesign, FontAwesome } from "@expo/vector-icons";

import {
  GIFT_CARD,
  STORE_REFUND,
  TICKET,
  VOUCHER
} from "../../../utils/refund-types-utils";
import {
  baseFlexRow,
  marginLeftRight
} from "../../../components/styles/global-styles";

const StyledBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px 16px 16px;
`;

const StyledContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.modal.background};
  width: 350px;
`;

const StyledHeader = styled(Modal.Header)`
  border-bottom-width: 0;
  padding-top: 32px;
  padding-bottom: 8px;
`;

const StyledText = styled(BaseText)`
  color: ${(props) => props.theme.palette.white};
  font-weight: 600;
  line-height: 24px;
  font-size: 14px;
  width: 88%;
  ${marginLeftRight(8)}
`;

const StyledRow = styled.View`
  margin-bottom: 4px;
  ${baseFlexRow()}

  align-items: flex-start;
`;

const StyledFooterText = styled(StyledText)`
  ${marginLeftRight(4)}
`;

const StyledTitle = styled(Title)`
  color: ${(props) => props.theme.palette.white};
`;

const StyledCloseButton = styled(IconButton)`
  margin: 16px;
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledFooter = styled.View`
  margin-bottom: 8px;
  ${baseFlexRow}
`;

const StyledArrows = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

export const InfoModal = ({ t }) => {
  const open = useSelector((state) => state.core?.modals?.info?.open);
  const dispatch = useDispatch();
  const theme = useTheme();

  const onToggle = () => {
    dispatch(toggleInfoModal({ open: !open }));
  };
  return (
    <Modal
      isOpen={open}
      onClose={onToggle}
      animationPreset={"slide"}
      overlayVisible={true}
      style={{
        shadowColor: "#171717",
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        position: "relative",
        padding: 0,
        height: "100%"
      }}
      size={"md"}
    >
      <StyledContent>
        <StyledCloseButton onPress={onToggle}>
          <Close color={theme.palette.white} />
        </StyledCloseButton>
        <StyledHeader>
          <StyledTitle>{t("What can I do here?")}</StyledTitle>
        </StyledHeader>
        <StyledBody>
          <View style={{ marginBottom: 12 }}>
            <StyledRow>
              <FontAwesome
                name="money"
                size={24}
                color={theme.palette.white}
                style={{ marginTop: 2 }}
              />
              <StyledText>
                {t(
                  "Buy refunds, giftcards, coupons & show tickets with big discount"
                )}
              </StyledText>
            </StyledRow>
            <StyledRow>
              <RefundTypeIcon
                type={GIFT_CARD}
                color={theme.palette.white}
                size={24}
              />
              <StyledText>{t("Got Giftcard you don't need?")}</StyledText>
            </StyledRow>
            <StyledRow>
              <RefundTypeIcon
                type={STORE_REFUND}
                color={theme.palette.white}
                size={24}
              />
              <StyledText>
                {t("Got refund from the store, and you want to cash it?")}
              </StyledText>
            </StyledRow>
            <StyledRow>
              <RefundTypeIcon
                type={VOUCHER}
                color={theme.palette.white}
                size={24}
                style={{ marginBottom: 5 }}
              />
              <StyledText>{t("Got voucher you don't need?")}</StyledText>
            </StyledRow>
            <StyledRow>
              <RefundTypeIcon type={TICKET} color={theme.palette.white} size={24} />
              <StyledText>{t("Got show tickets you want to sell?")}</StyledText>
            </StyledRow>
          </View>
          <StyledArrows>
            <AntDesign
              name="arrowdown"
              color={theme.palette.white}
              size={24}
              style={{ marginLeft: 8 }}
            />
            <AntDesign
              name="arrowdown"
              color={theme.palette.white}
              size={24}
              style={{ marginLeft: 8 }}
            />
            <AntDesign
              name="arrowdown"
              color={theme.palette.white}
              size={24}
              style={{ marginLeft: 8 }}
            />
          </StyledArrows>

          <StyledFooter>
            <AppLogo color={theme.palette.white} />
            <StyledFooterText>
              {t("Buy/Sell your refunds and Giftcard's here")}
            </StyledFooterText>
          </StyledFooter>
        </StyledBody>
      </StyledContent>
    </Modal>
  );
};
