import Svg, { Path, G } from "react-native-svg";
import React from "react";
import styled, { useTheme } from "styled-components/native";

export default function AppLogo({ color, size = 35, ...rest }) {
  const theme = useTheme();
  const widthHeight = `${size}px`;
  return (
    <Svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={widthHeight}
      height={widthHeight}
      viewBox="0 0 1957.000000 1957.000000"
      preserveAspectRatio="xMidYMid meet"
      {...rest}
    >
      <G
        transform="translate(0.000000,1957.000000) scale(0.100000,-0.100000)"
        fill={color || theme.palette.button.primary.color}
        stroke="none"
      >
        <Path
          d="M9345 17210 c-958 -47 -1916 -282 -2783 -682 -904 -416 -1733 -1023
-2403 -1758 -893 -979 -1509 -2171 -1790 -3465 -56 -254 -78 -388 -69 -406 54
-100 635 -144 955 -72 92 20 163 57 169 86 3 12 17 78 31 147 74 352 216 798
368 1155 307 722 721 1346 1283 1932 765 797 1722 1379 2783 1693 799 236
1650 316 2473 230 955 -98 1884 -411 2683 -903 908 -559 1650 -1314 2186
-2227 302 -513 538 -1092 684 -1680 14 -58 28 -113 31 -122 5 -17 -22 -18
-442 -20 l-447 -3 751 -683 c413 -376 754 -683 757 -683 3 0 326 305 717 678
391 373 724 689 740 703 l30 25 -284 -6 c-155 -4 -374 -7 -485 -8 l-202 -1 -6
38 c-4 20 -27 125 -52 232 -282 1214 -857 2332 -1678 3260 -427 484 -884 887
-1405 1242 -1355 922 -2967 1377 -4595 1298z"
        />
        <Path
          d="M9506 15439 c-157 -16 -294 -55 -325 -93 -9 -11 -12 -117 -11 -450
l1 -435 -913 -3 c-831 -3 -918 -5 -964 -21 -111 -37 -177 -106 -214 -226 -20
-63 -20 -104 -20 -4530 l0 -4467 25 -51 c44 -88 138 -141 295 -169 75 -13 138
-15 295 -11 228 5 304 20 399 77 65 40 101 86 115 150 7 30 11 700 11 2003 l0
1957 483 -2 482 -3 2 -2570 c2 -1413 5 -2576 8 -2583 8 -20 102 -59 185 -76
308 -63 831 -26 928 65 l22 20 0 2499 c0 1777 3 2500 11 2500 21 0 237 -108
300 -150 211 -142 419 -415 566 -745 58 -131 93 -223 609 -1610 337 -904 504
-1341 523 -1365 36 -45 107 -93 179 -120 96 -36 204 -50 392 -50 338 0 500 40
567 139 27 40 29 88 8 179 -8 34 -251 684 -541 1444 -289 759 -541 1423 -559
1475 -78 215 -268 634 -384 848 -182 336 -382 532 -653 640 -60 24 -68 30 -68
54 0 23 11 31 98 71 297 137 524 293 745 513 340 340 539 741 618 1252 27 176
38 600 20 793 -27 290 -85 535 -178 751 -207 480 -530 782 -1093 1019 -283
118 -736 227 -1082 258 l-78 7 0 453 c0 431 -1 455 -19 473 -27 27 -112 56
-221 76 -113 20 -433 28 -564 14z m-336 -3629 l0 -1620 -485 0 -485 0 0 1620
0 1620 485 0 485 0 0 -1620z m1275 1549 c294 -70 584 -227 763 -411 238 -245
362 -615 363 -1083 0 -296 -47 -498 -177 -756 -202 -403 -587 -729 -1004 -850
-36 -11 -68 -19 -72 -19 -5 0 -8 709 -8 1575 l0 1575 23 -4 c12 -3 63 -15 112
-27z"
        />
        <Path
          d="M2155 9017 l-751 -682 458 -6 c252 -3 460 -8 463 -11 3 -2 12 -42 21
-89 8 -46 37 -174 64 -284 375 -1552 1246 -2956 2465 -3975 1264 -1058 2768
-1651 4434 -1751 275 -16 818 -6 1091 20 1370 133 2636 613 3739 1416 791 575
1449 1288 1973 2140 446 724 773 1547 948 2383 22 106 40 204 40 217 0 73
-237 127 -555 127 -324 0 -546 -46 -573 -120 -5 -15 -31 -117 -57 -227 -138
-582 -413 -1243 -738 -1775 -559 -915 -1321 -1664 -2237 -2202 -1179 -691
-2575 -989 -3930 -837 -850 95 -1625 339 -2375 747 -1092 594 -2007 1521
-2582 2617 -211 401 -363 777 -482 1185 -61 206 -105 389 -98 397 4 3 210 1
459 -4 249 -6 454 -9 455 -8 5 3 -1457 1396 -1470 1400 -6 3 -350 -302 -762
-678z"
        />
      </G>
    </Svg>
  );
}
