import { Actions } from "react-native-gifted-chat";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components/native";

export default function ChatActions({ pickImage, sendImage, closeMenu, ...rest }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Actions
      {...rest}
      // options={{
      //   [t("Add Image")]: pickImage,
      //   [t("Cancel")]: () => {}
      // }}
      backgroundColor={"blue"}
      //     wrapperStyle?: StyleProp<ViewStyle>;
      // iconTextStyle?: StyleProp<TextStyle>;
      optionTintColor={theme.palette.icons.active}
      wrapperStyle={{
        color: theme.palette.font.primary
      }}
      containerStyle={{
        color: theme.palette.font.primary
      }}
      icon={() => (
        <MaterialCommunityIcons
          name="attachment"
          size={24}
          color={theme.palette.icons.active}
        />
      )}
      onSend={sendImage}
      onPressActionButton={pickImage}
    />
  );
}
