import { actionTypes } from "../actions/action-types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import jwtDecode from "jwt-decode";
import { SERVER_URL } from "../../config/config";
import { showError } from "../actions/core-actions";
import { getErrorMessage } from "../../utils/errors-utils";
import { restClient } from "./fetch-thunk";
import { SEND_FORGOT_PASSWORD_CODE } from "../../constants/apis-urls";

export const USERS_API = `${SERVER_URL}/api/users`;

export const registerUser = (data) => {
  return async (dispatch) => {
    try {
      const { firstName, lastName, email, password } = data;
      if (!firstName || !email || !password) {
        console.error(
          "registerUser missing params email ",
          email,
          " password ",
          password,
          " firstName ",
          firstName
        );
        dispatch(
          showError("registerUser missing params email  or password or firstname")
        );
        return;
      }
      console.log("register user ", data);
      const response = await fetch(`${USERS_API}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();

      if (!responseData.success) {
        console.error("failed to register ", responseData);
        dispatch({
          type: actionTypes.AUTH_API_FAIL,
          payload: { error: responseData.errors }
        });
      } else {
        dispatch({
          type: actionTypes.SET_USER_TOKEN,
          payload: { user: responseData.result, token: responseData.token }
        });
      }
      return responseData;
    } catch (e) {
      console.error("failed to register ", e);
      dispatch(showError("failed to register " + e.message));
    }
  };
};

export const loginUser = (data) => {
  return async (dispatch) => {
    try {
      const { email, password } = data;
      if (!email || !password) {
        console.error(
          "loginUser missing param email ",
          email,
          " password ",
          password
        );
        return;
      }
      const response = await fetch(`${USERS_API}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email: email.toLowerCase(), password })
      });
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      const responseData = await response.json();

      if (!responseData.success) {
        console.error("failed to login, ", responseData);
        dispatch({
          type: actionTypes.AUTH_API_FAIL,
          payload: { errors: responseData.errors }
        });
      } else {
        dispatch({
          type: actionTypes.SET_USER_TOKEN,
          payload: { user: responseData.result, token: responseData.token }
        });
      }
      return responseData;
    } catch (e) {
      console.error("failed to login ", e);
      dispatch(showError("failed to login " + e.message));
    }
  };
};

export const sendForgotPasswordLink = (data) => {
  return async (dispatch) => {
    try {
      const { email } = data;
      if (!email) {
        console.error("send forgot password link missing param email ", email);
        return;
      }
      const response = await fetch(`${USERS_API}/send-forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      console.log("sendForgotPasswordLink responseData ", responseData);

      let res = responseData;
      if (responseData.success) {
        console.log("send code ", responseData?.result);
        const result = await dispatch(
          restClient.postAPI({
            url: SEND_FORGOT_PASSWORD_CODE,
            body: responseData?.result
          })
        );
        res = result;
      }

      if (res && !res.success) {
        console.error("failed to send forgot password link, ", res);
        dispatch({
          type: actionTypes.AUTH_API_FAIL,
          payload: { errors: res.errors }
        });
      }
      return res;
    } catch (e) {
      console.error("failed to send forgot password link ", e);
      dispatch(showError("failed to send forgot password link " + e.message));
    }
  };
};

export const resetPassword = (data) => {
  return async (dispatch) => {
    try {
      const { code, password } = data;
      if (!code || !password) {
        console.error(
          "reset password missing param code ",
          code,
          " password ",
          password
        );
        return;
      }
      const response = await fetch(`${USERS_API}/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();

      if (!responseData.success) {
        console.error("failed to reset password link, ", responseData);
        dispatch({
          type: actionTypes.AUTH_API_FAIL,
          payload: { errors: responseData.errors }
        });
      }
      return responseData;
    } catch (e) {
      console.error("failed to reset password", data, e);
      dispatch(showError("failed to reset password " + e.message));
    }
  };
};

export const fetchUserById = ({ userId }) => {
  return async (dispatch) => {
    try {
      const user = await dispatch(fetchUserDataById({ userId }));
      if (user) {
        dispatch({
          type: actionTypes.GET_USER_SUCCESS,
          payload: { user }
        });
      }
    } catch (e) {
      console.error("failed fetchUserById ", userId, e);
      dispatch(showError("failed to fetchUserById " + e.message));
    }
  };
};

export const fetchUsersById = ({ userIds }) => {
  return async (dispatch) => {
    try {
      console.log("fetchUsersById", userIds);

      if (!userIds || userIds.length === 0) {
        console.error("failed fetchUsersById missing userIds ", userIds);
        return;
      }
      const url = `${USERS_API}/users-info`;
      const result = await dispatch(
        restClient.postAPI({
          url,
          body: {
            ids: userIds
          }
        })
      );

      dispatch({
        type: actionTypes.SET_USERS_DATA,
        payload: { users: result }
      });
    } catch (e) {
      console.error("failed fetchUsersById ", userIds, e);
      dispatch(showError("failed to fetch users By Id " + e.message));
    }
  };
};

export const fetchUserDataById = ({ userId }) => {
  return async (dispatch) => {
    try {
      if (!userId) {
        console.error("fetchUserById missing param userId ", userId);
        return;
      }
      const url = `${USERS_API}/${userId}`;
      const result = await dispatch(restClient.getAPI(url));
      return result;
    } catch (e) {
      console.error("failed fetchUserById ", userId, e);
      dispatch(showError("failed to fetchUserById " + e.message));
    }
  };
};

export const fetchUser = () => {
  return async (dispatch) => {
    try {
      const user1 = await dispatch(
        fetchUserDataById({ userId: "61e4799dcfa3a73d43b622ac" })
      );

      console.log("fetch user1 ", user1);
      const token = await AsyncStorage.getItem("token");
      console.log("fetch user ", token);
      let data;
      if (token) {
        data = jwtDecode(token);
        const user = await dispatch(fetchUserDataById({ userId: data.id }));
        console.log("user user ", user);
        if (user) {
          console.log("token data ", data);
          dispatch({
            type: actionTypes.SET_USER_TOKEN,
            payload: { user: data, token }
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_USER_TOKEN,
          payload: { user: null, token: null }
        });
      }
      dispatch({
        type: actionTypes.SET_USER_LOADED,
        payload: true
      });

      return data;
    } catch (e) {
      console.error("failed to fetch user ", e);
      dispatch(showError("failed to fetch user " + e.message));
    }
  };
};

//expoToken

export const updateUser = ({ userId, data }) => {
  return async (dispatch) => {
    try {
      console.log("update user userId ", userId, " data ", data);
      const response = await fetch(`${USERS_API}/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      console.log("update user userId ", userId, " responseData ", responseData);

      if (!responseData.success) {
        console.error("failed to update user ", responseData);
        const errorMessage = getErrorMessage(responseData);
        throw Error(errorMessage);
      } else {
        const token = responseData.token;
        dispatch({
          type: actionTypes.UPDATE_USER,
          payload: { user: responseData.result, token }
        });

        if (token) {
          await AsyncStorage.setItem("token", token);
        }
      }
      return responseData?.result;
    } catch (e) {
      console.error("failed to update user userId ", userId, " data ", data, e);
      dispatch(showError(e.message));
    }
  };
};

export const deleteUser = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      console.log("delete user userId ", userId);
      const response = await fetch(`${USERS_API}/delete/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        }
      });
      const responseData = await response.json();
      console.log("delete user userId ", userId, " responseData ", responseData);

      if (!responseData.success) {
        console.error("failed to delete user ", responseData);
        const errorMessage = getErrorMessage(responseData);
        throw Error(errorMessage);
      }
      return responseData;
    } catch (e) {
      console.error("failed to delete user userId ", userId, " data ", data, e);
      dispatch(showError(e.message));
    }
  };
};
