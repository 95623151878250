import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRefunds } from "../../redux/thunks/refunds-thunk";
import Search from "../../components/search/search";
import actionTypes from "../../redux/actions/action-types";
import styled from "styled-components/native";
import { TouchableOpacity } from "react-native";
import BackButton from "../../components/basic/buttons/back-button";
import { SearchInput } from "../../components/search/search-input";
import { useNavigation } from "@react-navigation/native";
import { isIos, isWeb } from "../../utils/core-utils";
import { DESKTOP_MAX_WIDTH } from "../../components/styles/constants";
import Header from "../../components/header";

const StyledSearch = styled.View`
  width: 100%;
  flex: 1;
`;

const StyledHeader = styled(Header)`
  padding-right: 0;
  padding-left: 0;
  margin-top: ${isWeb() ? 16 : isIos() ? 40 : 40}px;
`;

export default function RefundsSearch({ height, onPress, width }) {
  const dispatch = useDispatch();
  const searchText = useSelector((state) => state.search?.searchText);
  const navigation = useNavigation();

  const fetchItemsByQuery = useCallback(() => {
    dispatch(fetchRefunds({ searchText }));
  }, [searchText]);

  const onInputPress = (text) => {
    navigation.navigate("Search");
  };

  const onClear = () => {
    dispatch({ type: actionTypes.SET_SEARCH_QUERY, payload: "" });
    dispatch(fetchRefunds({ searchText: "" }));
  };

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 0,
        maxWidth: DESKTOP_MAX_WIDTH,
        marginRight: "auto",
        marginLeft: "auto"
      }}
    >
      <BackButton onPress={onClear} style={{ marginLeft: 0 }} />
      <StyledSearch>
        <SearchInput
          value={searchText}
          onSubmit={fetchItemsByQuery}
          onClear={onClear}
          height={height}
          disabled={true}
          onPress={onInputPress}
        />
      </StyledSearch>
    </TouchableOpacity>
  );
}
