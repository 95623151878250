import styled from "styled-components/native";
import { KeyboardAvoidingView } from "react-native";
import { registerWebWidth } from "../../components/styles/screen-layout";
import { flexDirection } from "../../components/styles/global-styles";

export const StyledAccountPage = styled(KeyboardAvoidingView)`
  background-color: ${(props) => props.theme.palette.background.primary};
  ${registerWebWidth}
`;

export const StyledButtonWithLabel = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 24px 0;
  ${flexDirection}
`;
