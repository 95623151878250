import { actionTypes } from "../actions/action-types";

const initialState = {
  ids: [],
  isLoading: false
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_USER_REQUEST: {
      const { refundId } = payload;

      return {
        ...state,
        isLoading: false,
        ids: { ...state.ids, [refundId]: true }
      };
    }
    case actionTypes.SET_SEND_REQUEST_LOADING: {
      return {
        ...state,
        isLoading: payload
      };
    }
    case actionTypes.FETCH_USER_REQUESTS: {
      return {
        ...state,
        ids: payload.items,
        isLoading: false
      };
    }
    case actionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default authReducer;
