import * as React from "react";
import { View, Text, Platform } from "react-native";
import styled, { useTheme } from "styled-components/native";
import Title from "../components/basic/title";
import { isWeb } from "../utils/core-utils";

const StyledContainer = styled.View`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.background.primary};
  width: 100%;
`;

const StyledText = styled(Title)`
  margin-right: ${() => (isWeb() ? "70px" : Platform.OS === "ios" ? 0 : "30px")};
`;

function DefaultHeader({ text, state, descriptors, navigation }) {
  return (
    <StyledContainer>
      <StyledText>{text}</StyledText>
    </StyledContainer>
  );
}

export default DefaultHeader;
