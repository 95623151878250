import colors from "./colors";

const lightPalette = {
  ...colors,
  separator: colors.gray[2],
  disabled: colors.gray[2],
  white: "#ffffff",
  font: {
    primary: "#233251",
    secondary: "#233251",
    tertiary: "#233251",
    error: "#782c61"
  },
  background: {
    primary: "#ffffff",
    secondary: "#f5f6fa",
    tertiary: "#233251"
  },
  button: {
    primary: {
      color: "#3e73f0",
      hover: "#3e73f0",
      font: "#ffffff",
      border: "#3e73f0",
      disabled: {
        color: colors.gray[2],
        font: colors.gray[5]
      }
    },
    secondary: {
      color: "#1266bd",
      hover: "#0d4885",
      text: "#ffffff"
    },
    tertiary: {
      color: "#233251",
      hover: "#233251",
      text: "#ffffff",
      border: "#ffffff"
    },
    menu: {
      color: "#6b98ff",
      hover: "#3e73f0",
      text: "#ffffff"
    },
    outlined: {
      color: "#ffffff",
      border: "#222222",
      backgroundHover: "#ffffff",
      font: "#222222",
      textHover: "#ffffff"
    },
    disabled: colors.gray[2],
    info: {
      color: "#c0c0c0",
      hover: "#c0c0c0",
      font: "#ffffff"
    }
  },
  link: {
    color: "#3e73f0",
    hover: "#3e73f0",
    disabled: colors.gray[2]
  },
  modal: {
    layout: "rgba(22, 22, 22, 0.5)",
    background: "#233251"
  },
  label: {
    color: "#c9d3ff",
    hover: "#a4b3f5"
  },
  social: {
    twitter: "#55acee",
    facebook: "#4267b2",
    linkedin: "#0077b5",
    whatsapp: "#25d366"
  },
  icons: {
    primary: "#717171",
    secondary: "#717171",
    tertiary: "#222222",
    active: "#3e73f0"
  },
  input: {
    placeholder: colors.gray[3],
    border: colors.gray[2],
    background: "#ffffff",
    font: "#484848",
    label: {
      color: "#aaa",
      focusedColor: "#000"
    }
  },
  error: {
    font: "#cf7094"
  },
  price: {
    red: "#cc4f4b",
    green: "#26b564"
  },
  addImage: {
    font: "#717171",
    background: colors.gray[1]
  },
  datePicker: {
    background: colors.gray[1]
  },
  tabs: {
    active: "#484848",
    inactive: colors.gray[2],
    indicator: "#484848"
  },
  color: {
    primary: "#3e73f0"
  },
  backdrop: {
    color: "#ffffff"
  },
  chat: {
    right: {
      backgroundColor: "#415178",
      color: "#fff"
    },
    left: {
      backgroundColor: "#575e6b",
      color: "#fff"
    }
  },
  menu: {
    backgroundColor: colors.gray[1],
    color: "#484848"
  },
  calendar: {
    selectedText: "#fff"
  }
};

export default lightPalette;
