import React, { useState } from "react";
import styled from "styled-components/native";
import { View, Platform, TouchableOpacity, Linking, Text } from "react-native";
import BaseText from "./base-text";

const StyledTitle = styled(BaseText)`
  font-weight: 600;
  color: ${(props) => props.theme.palette.link.color};
`;

export default function Link({ url, children, oneLine, ...rest }) {
  if (!url) {
    return null;
  }

  const onPress = () => {
    if (Platform.OS == "web") {
      window.open(url, "_blank");
    } else {
      Linking.openURL(url);
    }
  };
  return (
    <View {...rest}>
      <TouchableOpacity onPress={onPress}>
        <StyledTitle oneLine>{children}</StyledTitle>
      </TouchableOpacity>
    </View>
  );
}
