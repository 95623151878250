import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppNotification } from "./basic/app-notification/app-notification";
import { hideNotification } from "../redux/actions/core-actions";
import { InfoModal } from "../screens/home/menu-buttons/info-modal";
import { useTranslation } from "react-i18next";
import { AddedRefundModal } from "./modals/added-refund-modal";

export default function AppNotifications(props) {
  const notification = useSelector((state) => state.core?.notification);
  const { type, message } = notification || {};
  const dispatch = useDispatch();
  const open = !!notification;
  const { t } = useTranslation();

  const onClose = () => {
    dispatch(hideNotification());
  };

  return (
    <>
      <AppNotification open={open} message={message} onClose={onClose} type={type} />
      <InfoModal t={t} />
      <AddedRefundModal />
    </>
  );
}
