import React, { useState } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  View
} from "react-native";
import styled from "styled-components/native";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import useTheme from "../../components/styles/use-theme";
import { Button } from "react-native-elements";
import { StyledLayout } from "../../components/styles/screen-layout";
import Errors from "../../components/errors";
import { useTranslation } from "react-i18next";
import InputField from "../../components/basic/input-field";
import IconButton from "../../components/basic/buttons/icon-button";
import { sendContactUs } from "../../redux/thunks/core-thunk";
import { SOMETHING_WRONG } from "../../utils/errors-utils";
import { contactUsSchema } from "../../utils/validations";
import AppName from "../../components/basic/icons/app-name";
import { showSuccess } from "../../redux/actions/core-actions";

const StyledImageContainer = styled.View`
  display: flex;
  align-items: center;
`;

const StyledPage = styled(KeyboardAvoidingView)`
  background-color: ${(props) => props.theme.palette.background.primary};
`;

const StyledForm = styled.View`
  background-color: ${(props) => props.theme.palette.background.primary};
  padding: 20px;
  border-radius: 10px;
`;

const StyledInputRow = styled(InputField)``;

export default function ContactUs({ navigation }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);

  const theme = useTheme();

  const openHome = () => {
    navigation.navigate("Home");
  };

  return (
    <StyledLayout>
      <ScrollView>
        <StyledPage
          style={{
            flex: 1
          }}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
          <Formik
            initialValues={{
              name: user?.firstName || "",
              email: user?.email || "",
              subject: "Question",
              message: ""
            }}
            validationSchema={contactUsSchema}
            onSubmit={(values, { resetForm }) => {
              setIsLoading(true);
              if (errors) {
                setErrors(null);
              }
              dispatch(sendContactUs(values))
                .then(async (result) => {
                  dispatch(
                    showSuccess({
                      message: "Your message has been sent."
                    })
                  );
                  resetForm();
                })
                .catch((e) => {
                  console.error(e);
                  setErrors([e.message || SOMETHING_WRONG]);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            {(props) => (
              <View
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <StyledForm>
                  <IconButton onPress={openHome}>
                    <StyledImageContainer>
                      <AppName color={theme.palette.icons.active} size={50} />
                    </StyledImageContainer>
                  </IconButton>

                  <Errors errors={errors} />

                  <View style={styles.formGroup}>
                    <StyledInputRow
                      title={t("Name")}
                      name="name"
                      multiline={false}
                      props={props}
                      editable={!user?.firstName}
                    />
                  </View>
                  <View style={styles.formGroup}>
                    <StyledInputRow
                      title={t("Email")}
                      name="email"
                      multiline={false}
                      props={props}
                      keyboardType="email-address"
                      editable={!user?.email}
                    />
                  </View>
                  <View style={styles.formGroup}>
                    <StyledInputRow
                      title={t("Subject")}
                      name="subject"
                      multiline={false}
                      props={props}
                    />
                  </View>

                  <View style={{ width: "100%" }}>
                    <StyledInputRow
                      title={t("Message")}
                      name="message"
                      multiline={true}
                      props={props}
                      style={{ height: 250 }}
                    />
                  </View>
                  <View style={styles.buttonContainer}>
                    <Button
                      buttonStyle={{
                        backgroundColor: theme.palette.button.primary.color,
                        color: theme.palette.button.primary.text
                      }}
                      title={t("Send Message")}
                      onPress={props.handleSubmit}
                    />
                  </View>
                </StyledForm>
              </View>
            )}
          </Formik>
        </StyledPage>
      </ScrollView>
    </StyledLayout>
  );
}

const styles = StyleSheet.create({
  formGroup: {
    width: "100%"
  },
  label: {
    marginVertical: 10
  },
  buttonContainer: {
    marginTop: 20
  },
  button: {
    borderRadius: 100
  },
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
});
