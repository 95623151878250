import React from "react";
import { TouchableOpacity } from "react-native";
import { useTheme } from "styled-components/native";
import TabsIcon from "./tabs-icon";
import { useDispatch } from "react-redux";
import { refundsScrollToTopToggle } from "../../redux/actions/refunds-actions";
import { setActiveTab } from "../../redux/thunks/core-thunk";

export default function BottomButton({
  state,
  descriptors,
  navigation,
  route,
  index,
  onClick,
  routeName,
  isLoggedIn,
  totalMessagesCount,
  isAppLoaded
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { options } = descriptors[route.key];
  const label =
    options.tabBarLabel !== undefined
      ? options.tabBarLabel
      : options.title !== undefined
      ? options.title
      : route.name;
  const isFocused = state.index === index;

  const onPress = () => {
    console.log(
      "BottomButton isLoggedIn ",
      isLoggedIn,
      " route.name ",
      route.name,
      "currentRoute ",
      state.routeName
    );

    const event = navigation.emit({
      type: "tabPress",
      target: route.key
    });

    if (!isLoggedIn && route.name !== "Search" && route.name !== "Home") {
      navigation.navigate("Login");
      return;
    }

    if (!isFocused && !event.defaultPrevented) {
      navigation.navigate(route.name);
    }

    if (route.name === "Home") {
      navigation.reset({
        index: 0,
        routes: [{ name: "Home" }]
      });
      dispatch(refundsScrollToTopToggle(true));
    }

    onClick(route.name);
    dispatch(setActiveTab(route.name));
  };

  const onLongPress = () => {
    navigation.emit({
      type: "tabLongPress",
      target: route.key
    });
  };

  return (
    <TouchableOpacity
      accessibilityRole="button"
      accessibilityState={isFocused ? { selected: true } : {}}
      accessibilityLabel={options.tabBarAccessibilityLabel || route.name}
      testID={options.tabBarTestID}
      onPress={onPress}
      onLongPress={onLongPress}
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        height: "100%",
        justifyContent: "center"
      }}
      key={index}
      disabled={!isAppLoaded}
    >
      <TabsIcon
        routeName={route.name}
        active={isFocused}
        count={route.name === "Messages" ? totalMessagesCount : undefined}
      />
      {/* <Text style={{ color: isFocused ? "#673ab7" : "#222" }}>{label}</Text> */}
    </TouchableOpacity>
  );
}
