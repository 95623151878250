import React from "react";
import Button from "./button";
import { useTheme } from "styled-components/native";

export const TertiaryButton = ({
  title,
  onPress,
  disabled,
  children,
  buttonStyle = {},
  titleStyle = {},
  height,
  fontSize,
  checked,
  iconSize,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Button
      type="outline"
      title={title}
      onPress={onPress}
      disabled={disabled}
      buttonStyle={{
        borderColor: theme.palette.button.tertiary.border,
        backgroundColor: theme.palette.button.tertiary.color,
        borderWidth: 1,
        height,
        ...buttonStyle
      }}
      titleStyle={{
        color: theme.palette.button.tertiary.text,
        fontSize,
        ...titleStyle
      }}
      iconSize={iconSize}
      checked={checked}
      {...rest}
    >
      {children}
    </Button>
  );
};
