import { actionTypes } from "../actions/action-types";
import { NOTIFICATIONS_API } from "../../constants/apis-urls";
import { getUnreadIds } from "../../utils/core-utils";
import { showError } from "../actions/core-actions";
import { restClient } from "./fetch-thunk";

export const fetchUserNotifications = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      dispatch({
        type: actionTypes.SET_NOTIFICATIONS_LOADING,
        payload: true
      });

      console.log("fetching user notifications ", userId);
      const url = `${NOTIFICATIONS_API}/${userId}`;

      const result = await dispatch(restClient.getAPI(url));

      console.log("fetching user notifications res ", result);

      dispatch({
        type: actionTypes.SET_NOTIFICATIONS,
        payload: result
      });
    } catch (e) {
      console.error("failed to fetching user notifications userId ", userId, e);
      dispatch(showError("failed to fetch user notifications " + e.message));
    } finally {
      dispatch({
        type: actionTypes.SET_NOTIFICATIONS_LOADING,
        payload: false
      });
    }
  };
};

export const fetchUserNotificationsUnreadCount = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      console.log("fetching user notifications unread count ", userId);
      const url = `${NOTIFICATIONS_API}/${userId}/unread-count`;
      const notificationUnreadCount = getState().notifications.unreadCount;

      const result = await dispatch(restClient.getAPI(url));

      console.log("fetching user notifications unread count res ", result);

      dispatch({
        type: actionTypes.SET_NOTIFICATIONS_UNREAD_COUNT,
        payload: result
      });
      if (notificationUnreadCount !== result) {
        dispatch(fetchUserNotifications());
      }
    } catch (e) {
      console.error(
        "failed to fetching user notifications unread count userId ",
        userId,
        e
      );
      dispatch(
        showError("failed to fetch user notifications unread count ", e.message)
      );
    }
  };
};

export const updateReadNotifications = ({ items }) => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    if (!items || items.length === 0) {
      return;
    }
    try {
      const unreadIds = getUnreadIds(items);
      if (!unreadIds || unreadIds?.length === 0) {
        console.log("updateReadNotifications no unreadIds ", unreadIds);
        return;
      }
      console.log("updateReadNotifications ", userId, " unreadIds ", unreadIds);
      const url = `${NOTIFICATIONS_API}/${userId}/update-read-at`;
      const result = await dispatch(
        restClient.postAPI({
          url,
          body: {
            ids: unreadIds
          }
        })
      );

      console.log("updateReadNotifications res ", result);

      dispatch({
        type: actionTypes.SET_NOTIFICATIONS_UNREAD_COUNT,
        payload: 0
      });
    } catch (e) {
      console.error(
        "failed to update user notifications read at by userId ",
        userId,
        e
      );
      dispatch(
        showError(
          "failed to update user notifications read at by userId  " + userId,
          e.message
        )
      );
    }
  };
};

export const fetchUserNotificationRequest = ({ id }) => {
  return async (dispatch, getState) => {
    try {
      console.log("fetching user notification request ", id);
      const url = `${NOTIFICATIONS_API}/request/${id}`;

      const result = await dispatch(restClient.getAPI(url));

      console.log("fetching user notifications res ", result);

      dispatch({
        type: actionTypes.SET_CURRENT_NOTIFICATION,
        payload: result
      });
    } catch (e) {
      console.error("failed to fetching user notifications userId ", userId, e);
      dispatch(showError("failed to fetch user notifications " + e.message));
    }
  };
};
