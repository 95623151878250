import { actionTypes } from "../actions/action-types";
import { SERVER_URL } from "../../config/config";
import { showError } from "../actions/core-actions";
import { restClient } from "./fetch-thunk";

export const FAVORITES_API = `${SERVER_URL}/api/favorites`;

export const toggleFavorite = ({ item, isAdd }) => {
  return async (dispatch, getState) => {
    try {
      const userId = getState().auth?.user?.id;
      if (!userId) {
        throw new Error("No user id found");
      }
      if (isAdd) {
        dispatch(addFavorite({ userId, item }));
      } else {
        dispatch(removeFavorite({ userId, item }));
      }
    } catch (e) {
      console.error("failed to toggle favorite isAdd ", isAdd, " item ", item, e);
      dispatch(showError("failed to toggle favorite " + e.message));
    }
  };
};

export const fetchFavorites = ({ userId }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.FAVORITES_IS_LOADING,
        payload: true
      });
      console.log("fetching favorites for user ", userId);
      const url = `${FAVORITES_API}/${userId}`;

      const result = await dispatch(restClient.getAPI(url));
      console.log("fetching favorites res ", result);

      dispatch({
        type: actionTypes.FETCH_FAVORITES,
        payload: { items: result }
      });
    } catch (e) {
      console.error("failed to fetch favorites ", e);
      dispatch(showError("failed to fetch favorites " + e.message));
    } finally {
      dispatch({
        type: actionTypes.FAVORITES_IS_LOADING,
        payload: false
      });
    }
  };
};

export const addFavorite = ({ userId, item }) => {
  return async (dispatch) => {
    try {
      const result = await dispatch(
        restClient.postAPI({
          url: FAVORITES_API,
          body: { userId, refundId: item.id }
        })
      );
      console.log("addFavorite itemResult ", result);
      dispatch({
        type: actionTypes.TOGGLE_FOVORITE,
        payload: { item, isAdd: true }
      });
    } catch (e) {
      console.error("failed to create favorite ", e);
      dispatch(showError(e.message));
    }
  };
};

export const removeFavorite = ({ userId, item }) => {
  return async (dispatch) => {
    try {
      console.log("remove favorite item ", item, " userId ", userId);
      const url = `${FAVORITES_API}/${userId}/${item.id}`;
      const result = await dispatch(
        restClient.fetch({
          url,
          method: "DELETE"
        })
      );
      console.log("remove favorite result ", result);
      dispatch({
        type: actionTypes.TOGGLE_FOVORITE,
        payload: { item, isAdd: false }
      });
    } catch (e) {
      console.error("failed to remove favorite ", e);
      dispatch(showError(e.message));
    }
  };
};
