import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledLayout } from "../../../components/styles/screen-layout";
import { useTranslation } from "react-i18next";
import Title from "../../../components/basic/title";
import styled from "styled-components/native";
import NoItemsFound from "../../../components/no-items-found";
import GenericList from "../../../components/generic-list";
import { fetchUserRequestedRefunds } from "../../../redux/thunks/refunds-thunk";
import BuyingItem from "./buying-item";
import { useIsMounted } from "../../../hooks/use-is-mounted";

const StyledTitle = styled(Title)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 16px;
`;

const StyledHeader = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledView = styled(StyledLayout)`
  padding: 16px 16px 0 16px;
`;

export default function UserRequestedRefunds({ navigation }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const buyRequests = useSelector((state) => state.userRequests?.ids) || [];
  const userRequestedRefunds =
    useSelector((state) => state.refunds?.userRequestedRefunds) || [];
  const isLoadingUserRequestedRefunds = useSelector(
    (state) => state.refunds?.isLoadingUserRequestedRefunds
  );
  console.log("isLoadingUserRequestedRefunds ", isLoadingUserRequestedRefunds);

  useEffect(() => {
    const ids = buyRequests ? Object.keys(buyRequests) : [];
    if (ids.length > 0) {
      if (userRequestedRefunds) {
        const currentIds = userRequestedRefunds
          ? userRequestedRefunds.map((item) => item.id)
          : [];
        const diff = ids.filter((d) => !currentIds.includes(d));
        if (diff.length > 0) {
          console.log("fetchUserRequestedRefunds ", ids);

          dispatch(fetchUserRequestedRefunds({ ids }));
        }
      } else {
        console.log("fetchUserRequestedRefunds ", ids);

        dispatch(fetchUserRequestedRefunds({ ids }));
      }
    }
  }, []);

  const renderItem = ({ item }) => (
    <BuyingItem item={item} navigation={navigation} />
  );

  if (userRequestedRefunds.length === 0) {
    return <NoItemsFound text={t("You have no requested refunds")} />;
  }
  console.log("Your requested refunds ", userRequestedRefunds);

  return (
    <StyledView>
      <GenericList
        items={userRequestedRefunds}
        navigation={navigation}
        renderItem={renderItem}
      />
    </StyledView>
  );
}
