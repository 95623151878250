import React from "react";
import { useSelector } from "react-redux";
import GenericList from "../../components/generic-list";
import { useTranslation } from "react-i18next";
import EmptyFavorites from "./empty-favorites";
import { StyledLayout } from "../../components/styles/screen-layout";
import Card from "../../components/refund/refund-card";
import Loader from "../../components/loaders/loader";
import styled from "styled-components/native";
import HeaderWithBackButton from "../../navigation/header-with-back-button";
import useUser from "../../hooks/use-user";
import { isAndroid, isIos, isWeb } from "../../utils/core-utils";

const StyledView = styled(StyledLayout)`
  margin-top: ${isAndroid() ? 16 : 0}px;
  padding: 0 16px 0 16px;
  flex: 1;
`;

export default function RefundFavorites({ navigation }) {
  const favorites = useSelector((state) => state.favorites.refunds) || [];
  const isLoading = useSelector((state) => state.favorites.isLoading);
  const isLoaded = useSelector((state) => state.favorites.isLoaded);
  const [isLoggedIn] = useUser();

  const { t } = useTranslation();

  if (isLoading || !isLoaded) {
    return <Loader />;
  }

  const renderItem = ({ item }) => (
    <Card item={item} navigation={navigation} isLoggedIn={isLoggedIn} />
  );

  return (
    <StyledLayout style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <HeaderWithBackButton
        text={t("Favorites")}
        onPressBack={() => navigation.goBack(null)}
      />
      <StyledView>
        {favorites.length === 0 ? (
          <EmptyFavorites />
        ) : (
          <GenericList
            items={favorites}
            navigation={navigation}
            ListEmptyComponent={<EmptyFavorites />}
            renderItem={renderItem}
          />
        )}
      </StyledView>
    </StyledLayout>
  );
}
