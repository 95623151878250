import { actionTypes } from "../actions/action-types";
import {
  ACCOUNT_API,
  CONTACT_US_API,
  CONTACT_US_NEW_USER_API,
  CUSTOMER_SERVICE_API
} from "../../constants/apis-urls";
import { showError } from "../actions/core-actions";
import { fetchUserRefundRequests } from "./user-requests-thunk";
import { fetchBuyRequestsUnreadCount } from "./buy-requests-thunk";
import { fetchUserNotificationsUnreadCount } from "./notifications-thunk";
import { fetchMessagesUnreadCount } from "./messages-thunk";
import { fetchUserRefunds } from "./refunds-thunk";
import { fetchSettings } from "./settings-thunk";
import { fetchFavorites } from "./favorites-thunk";
import { fetchUserExpoPushTokens } from "./push-token-thunk";
import { restClient } from "./fetch-thunk";
import { deleteUser } from "./auth-thunk";
import { updateMetadata } from "./user-metadata-thunk";
import { getPlatform } from "../../utils/core-utils";

export const fetchCustomerService = () => {
  return async (dispatch, getState) => {
    try {
      console.log("fetching customer service");

      const result = await dispatch(restClient.getAPI(CUSTOMER_SERVICE_API));
      console.log("fetching customer service res ", result);

      dispatch({
        type: actionTypes.SET_CUSTOMER_SERVICE,
        payload: result
      });
    } catch (e) {
      console.error("failed to fetch customer service ", e);
      dispatch(showError(e.message));
    }
  };
};

export const sendContactUs = (data) => {
  return async (dispatch, getState) => {
    try {
      console.log("send contact us ", data);
      const userId = getState().auth?.user?.id;

      const result = await dispatch(
        restClient.postAPI({ url: CONTACT_US_API, body: { ...data, userId } })
      );

      console.log("fetching contact us res ", result);
      return result;
    } catch (e) {
      console.error("failed to contact us ", data, e);
      dispatch(showError(e.message));
    }
  };
};

export const setActiveTab = (tab) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_CURRENT_CHAT,
      payload: null
    });
    dispatch({
      type: actionTypes.SET_ACTIVE_TAB,
      payload: tab
    });
  };
};

export const onLoginSuccessActions = () => {
  return async (dispatch, getState) => {
    try {
      console.log("on login success");
      const userId = getState().auth?.user?.id;
      const favoritesIsLoaded = getState().favorites?.isLoaded;
      const settingsIsLoaded = getState().settings.isLoaded;
      if (userId) {
        dispatch(fetchUserRefundRequests());
        dispatch(fetchBuyRequestsUnreadCount());
        dispatch(fetchUserNotificationsUnreadCount());
        dispatch(fetchMessagesUnreadCount());
        dispatch(fetchUserRefunds());
        if (!settingsIsLoaded) {
          dispatch(fetchSettings({ userId }));
        }
        if (!favoritesIsLoaded) {
          dispatch(fetchFavorites({ userId }));
        }
        dispatch(fetchUserExpoPushTokens({ userId }));
        dispatch(updateMetadata({ userId, platform: getPlatform() }));
      }
    } catch (e) {
      console.error("failed on login success", e);
      dispatch(showError(e.message));
    }
  };
};

export const sendNewUserNotification = ({ user }) => {
  return async (dispatch, getState) => {
    try {
      console.log("send notification new user registered ", user);
      const subject = "New user registered";

      const result = await dispatch(
        restClient.postAPI({
          url: CONTACT_US_NEW_USER_API,
          body: { name: user.firstName, email: user.email, subject, message: "" }
        })
      );

      console.log("send notification new user res ", result);
      return result;
    } catch (e) {
      console.error("failed to send notification new user  ", user, e);
      dispatch(showError(e.message));
    }
  };
};

export const deleteAccount = () => {
  return async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    try {
      console.log("delete account userId ", userId);
      const result = await dispatch(
        restClient.postAPI({
          url: `${ACCOUNT_API}/delete`,
          body: {
            userId
          }
        })
      );
      console.log("delete account userId ", userId, " result ", result);
      await dispatch(deleteUser());

      return result;
    } catch (e) {
      console.error("failed to delete account userId ", userId, e);
      dispatch(showError(e.message));
      throw e;
    }
  };
};

export const clearUserData = () => {
  return async (dispatch, getState) => {
    try {
      console.log("clearUserData start ");
      dispatch({
        type: actionTypes.CLEAR_USER
      });
      dispatch({
        type: actionTypes.LOGOUT
      });
      console.log("clearUserData end ");
    } catch (e) {
      console.error("failed to clearUserData  ", e);
      dispatch(showError(e.message));
    }
  };
};
