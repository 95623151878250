import React from "react";
import {
  AntDesign,
  Feather,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons
} from "@expo/vector-icons";
import styled, { useTheme } from "styled-components/native";

const iconByType = {
  AntDesign: AntDesign,
  Feather: Feather,
  MaterialCommunityIcons: MaterialCommunityIcons,
  MaterialIcons: MaterialIcons,
  Octicons: Octicons
};

export default function IconByDesign({
  name,
  designType,
  size = 24,
  color,
  ...rest
}) {
  const theme = useTheme();
  const IconComp = iconByType[designType] || AntDesign;
  return <IconComp name={name} size={size} color={color} {...rest} />;
}
