import React from "react";
import styled from "styled-components/native";
import BaseText from "../basic/base-text";
import { useTranslation } from "react-i18next";
import { REFUND_TYPE_DISPLAY_NAME } from "../../utils/refund-types-utils";
import { baseFlexRow, marginRightLeft } from "../styles/global-styles";
import RefundTypeIcon from "./refund-type-icon";

const StyledContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${baseFlexRow}
`;

const StyledText = styled(BaseText)`
  font-size: 12px;
  align-self: flex-end;
`;

const StyledRefundTypeIcon = styled(RefundTypeIcon)`
  ${marginRightLeft(8)}
`;

export default function RefundType({ type, otherType, textStyle = {}, ...rest }) {
  const { t } = useTranslation();
  return (
    <StyledContainer {...rest}>
      <StyledRefundTypeIcon type={type} size={20} />
      <StyledText style={textStyle}>
        {otherType || t(REFUND_TYPE_DISPLAY_NAME[type])}
      </StyledText>
    </StyledContainer>
  );
}
