import React, { useState } from "react";
import { KeyboardAvoidingView, Platform, StyleSheet, View } from "react-native";
import styled from "styled-components/native";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import useTheme from "../../components/styles/use-theme";
import { Button } from "react-native-elements";
import Loader from "../../components/loaders/loader";
import * as authActions from "../../redux/thunks/auth-thunk";
import actionTypes from "../../redux/actions/action-types";
import Errors from "../../components/errors";
import { useTranslation } from "react-i18next";
import InputField from "../../components/basic/input-field";
import IconButton from "../../components/basic/buttons/icon-button";
import AppLogo from "../../components/basic/icons/app-logo";
import LinkButton from "../../components/basic/buttons/link-button";
import { resetPasswordSchema } from "../../utils/validations";
import Separator from "../../components/basic/separator";
import BaseText from "../../components/basic/base-text";
import { StyledAccountPage, StyledButtonWithLabel } from "./account-styles";
import {
  flexDirection,
  marginRightLeft
} from "../../components/styles/global-styles";
import AppName from "../../components/basic/icons/app-name";

const StyledImageContainer = styled.View`
  display: flex;
  align-items: center;
  margin: 20px auto;
`;

const StyledForm = styled.View`
  margin: 20px;
  background-color: ${(props) => props.theme.palette.background.primary};
  padding: 20px;
  border-radius: 10px;
`;

const StyledInputRow = styled(InputField)``;

const StyledForgotPasswordView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 24px 0;
  ${flexDirection}
`;

const StyledText = styled(BaseText)`
  ${marginRightLeft(8)}
`;

export default function ResetPassword({ route, navigation }) {
  const { code } = route.params;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.auth?.errors);
  const { t } = useTranslation();

  const theme = useTheme();
  if (isLoading) {
    return <Loader />;
  }

  const openLogin = () => {
    navigation.navigate("Login");
  };

  const openHome = () => {
    navigation.navigate("Home");
  };

  const openForgotPassword = () => {
    navigation.navigate("ForgotPassword");
  };

  return (
    <StyledAccountPage
      style={{
        flex: 1
      }}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <Formik
        initialValues={{
          code: code,
          password: ""
        }}
        validationSchema={resetPasswordSchema}
        onSubmit={(values) => {
          setIsLoading(true);
          if (errors) {
            dispatch({
              type: actionTypes.AUTH_API_FAIL,
              payload: { errors: null }
            });
          }

          dispatch(authActions.resetPassword(values))
            .then(async (result) => {
              if (result.success) {
                openLogin();
              } else {
                console.error(
                  "Something went wrong, please try again",
                  result.errors
                );
              }
            })
            .catch((e) => {
              console.error(e);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
      >
        {(props) => (
          <View
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <StyledForm>
              <IconButton onPress={openHome}>
                <StyledImageContainer>
                  <AppName color={theme.palette.icons.active} size={50} />
                </StyledImageContainer>
              </IconButton>

              <Errors errors={errors} />

              <View style={styles.formGroup}>
                <StyledInputRow
                  title={t("Code")}
                  name="code"
                  multiline={false}
                  props={props}
                  keyboardType={Platform.OS === "android" ? "numeric" : "number-pad"}
                />
              </View>
              <View style={styles.formGroup}>
                <StyledInputRow
                  title={t("Password")}
                  name="password"
                  multiline={false}
                  props={props}
                  secureTextEntry={true}
                />
              </View>

              <View style={styles.buttonContainer}>
                <Button
                  buttonStyle={{
                    backgroundColor: theme.palette.button.primary.color,
                    color: theme.palette.button.primary.text
                  }}
                  title={t("Reset password")}
                  onPress={props.handleSubmit}
                />
              </View>

              <StyledForgotPasswordView>
                <LinkButton
                  text={t("Send forgot password code again")}
                  onPress={openForgotPassword}
                />
              </StyledForgotPasswordView>
              <Separator />
              <StyledButtonWithLabel>
                <StyledText>{t("Back to")}</StyledText>
                <LinkButton text={t("Login")} onPress={openLogin} />
              </StyledButtonWithLabel>
            </StyledForm>
          </View>
        )}
      </Formik>
    </StyledAccountPage>
  );
}

const styles = StyleSheet.create({
  formGroup: {
    width: "100%"
  },
  label: {
    marginVertical: 10
  },
  buttonContainer: {
    marginTop: 20
  },
  button: {
    borderRadius: 100
  },
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
});
