import React, { useState, useCallback } from "react";
import styled from "styled-components/native";
import { useTranslation } from "react-i18next";
import BaseText from "../../components/basic/base-text";
import useUser from "../../hooks/use-user";
import { useNavigation } from "@react-navigation/native";
import BaseRow from "../../components/basic/rows/base-row";
import LinkButton from "../../components/basic/buttons/link-button";
import { marginRightLeft } from "../../components/styles/global-styles";

const StyledRow = styled(BaseRow)``;

const StyledTitle = styled(BaseText)`
  ${marginRightLeft(8)}
`;

export default function UserDetails({ item = {}, seller, className }) {
  const { t } = useTranslation();
  const sellerId = item.userId;
  const [userId, user] = useUser();
  const isUserRefund = userId && sellerId && userId === sellerId;
  const navigation = useNavigation();
  const [showUser, setShowUser] = useState(false);
  const { sellerName, sellerPhone, sellerEmail } = item;
  const [isLoggedIn] = useUser();

  if (isUserRefund) {
    return null;
  }

  const isExternalSeller = !!(sellerName && sellerEmail);
  const refundSeller = isExternalSeller
    ? { firstName: sellerName, phone: sellerPhone, email: sellerEmail }
    : seller;

  const showUserDetails = useCallback(() => {
    if (isLoggedIn) {
      setShowUser(!showUser);
    } else {
      navigation.navigate("Login");
    }
  }, [seller, setShowUser, isLoggedIn, showUser]);

  return (
    <>
      <StyledRow className={className}>
        <LinkButton
          text={t("Show user details")}
          onPress={showUserDetails}
        ></LinkButton>
      </StyledRow>

      {showUser && refundSeller && (
        <>
          <StyledRow>
            <StyledTitle>{t("Seller")}</StyledTitle>
            <BaseText>{`${refundSeller.firstName}${
              refundSeller.lastName ? ` ${refundSeller.lastName}` : ""
            }`}</BaseText>
          </StyledRow>
          {isExternalSeller && (
            <>
              {refundSeller.email && (
                <StyledRow>
                  <StyledTitle>{t("User Email")}</StyledTitle>
                  <BaseText>{refundSeller.email}</BaseText>
                </StyledRow>
              )}
              {refundSeller.phone && (
                <StyledRow>
                  <StyledTitle>{t("User Phone")}</StyledTitle>
                  <BaseText>{refundSeller.phone}</BaseText>
                </StyledRow>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
