import React from "react";
import styled, { useTheme } from "styled-components/native";
import BaseText from "../../components/basic/base-text";
import { TouchableOpacity } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { isWeb } from "../../utils/core-utils";

const StyledView = styled.View`
  background-color: ${(props) => props.theme.palette.background.primary};
  padding: 8px 16px;
`;

const StyledText = styled(BaseText)`
  color: ${(props) => props.theme.palette.font.primary};
  margin-left: 24px;
`;

export default function ResultRow({
  item,
  onPress,
  isBorder = true,
  icon = "clockcircleo",
  style = {}
}) {
  const theme = useTheme();
  const rowStyle = {
    borderBottomWidth: isBorder ? 1 : null,
    borderBottomColor: isBorder ? theme.palette.input.border : null,
    height: 50,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
    ...style
  };
  return (
    <TouchableOpacity onPress={onPress}>
      <StyledView style={rowStyle}>
        <AntDesign name={icon} size={20} color={theme.palette.font.primary} />

        <StyledText>{item.text}</StyledText>
      </StyledView>
    </TouchableOpacity>
  );
}
