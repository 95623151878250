import * as React from "react";
import { Platform, useWindowDimensions } from "react-native";
import { TabBar, TabView } from "react-native-tab-view";
import { useTheme } from "styled-components/native";
import TabsLabel from "./tab-label";
import { isWeb } from "../../utils/core-utils";

export default function Tabs({
  routes,
  renderScene,
  onTabChange,
  activeTab = 0,
  ...rest
}) {
  const layout = useWindowDimensions();
  const theme = useTheme();
  console.log("tabs activeTab ", activeTab);
  const marginTop = isWeb() ? 8 : Platform.OS === "ios" ? 20 : 0;

  const renderTabBar = (props) => {
    console.log("tabs renderTabBar ", props);
    return (
      <TabBar
        {...props}
        activeColor={theme.palette.tabs.active}
        inactiveColor={theme.palette.tabs.inactive}
        indicatorStyle={{ backgroundColor: theme.palette.tabs.active }}
        style={{
          marginTop,
          backgroundColor: theme.palette.background.primary
        }}
        labelStyle={{ textTransform: "capitalize" }}
        renderLabel={(props) => <TabsLabel {...props} />}
      />
    );
  };

  return (
    <TabView
      navigationState={{ index: activeTab, routes }}
      renderScene={renderScene}
      renderTabBar={renderTabBar}
      onIndexChange={onTabChange}
      initialLayout={{ width: layout.width }}
      // lazy
    />
  );
}
