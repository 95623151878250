import React, { useState } from "react";
import { Image, View, SafeAreaView, Modal } from "react-native";
import { MOCK_GALLERY_IMAGES } from "../../utils/images-utils";
import ImageViewer from "react-native-image-zoom-viewer";
import { isWeb } from "../../utils/core-utils";
import { WebZoomImage } from "../lightbox/web-zoom-image";

export default function ImagesGallery({ images, ...rest }) {
  const [open, setOpen] = useState(false);
  const isDefaultImage = images.length === 0;
  const items = images.length === 0 ? MOCK_GALLERY_IMAGES : [...images];

  const imageUrls = items.map((item) => ({ url: item }));
  const displayItems = [...imageUrls];
  const onClick = () => setOpen(!open);
  return (
    <>
      <Gallery
        items={displayItems}
        onClick={onClick}
        isDefaultImage={isDefaultImage}
      />
      {open && (
        <GalleryZoomView
          urls={items}
          items={displayItems}
          onClick={onClick}
          isDefaultImage={isDefaultImage}
          open={open}
        />
      )}
    </>
  );
}

const Gallery = ({ items, isDefaultImage, onClick }) => (
  <SafeAreaView style={{ flex: 1 }}>
    <View style={{ flex: 1 }}>
      <ImageViewer
        imageUrls={items}
        renderIndicator={() => null}
        enableImageZoom
        // height={200}
        style={{ minHeight: 300, width: "100%" }}
        onClick={onClick}
      />
    </View>
  </SafeAreaView>
);

const GalleryZoomView = ({ items, urls, isDefaultImage, onClick, open }) => {
  if (isWeb()) {
    return <WebZoomImage images={urls} open={open} onClose={onClick} />;
  } else {
    return (
      <Modal visible={true} transparent={true}>
        <Gallery items={items} onClick={onClick} isDefaultImage={isDefaultImage} />
      </Modal>
    );
  }
};
