import React, { useState, useEffect } from "react";
import { Platform, Text, View, StyleSheet } from "react-native";
import Constants from "expo-constants";
import * as Location from "expo-location";
import { useSelector, useDispatch } from "react-redux";
import actionTypes from "../redux/actions/action-types";

export default function useLocationPermissions() {
  const [errorMsg, setErrorMsg] = useState(null);
  const location = useSelector((state) => state.settings?.location);
  const userId = useSelector((state) => state.auth?.user?.id);
  const settingsIsLoaded = useSelector((state) => state.settings.isLoaded);

  const dispatch = useDispatch();

  useEffect(() => {
    if (settingsIsLoaded && !location) {
      (async () => {
        try {
          if (Platform.OS === "android" && !Constants.isDevice) {
            setErrorMsg(
              "Oops, this will not work on Snack in an Android emulator. Try it on your device!"
            );
            return;
          }
          let { status } = await Location.requestForegroundPermissionsAsync();
          if (status !== "granted") {
            setErrorMsg("Permission to access location was denied");
            return;
          }

          let location = await Location.getCurrentPositionAsync({});
          dispatch({
            type: actionTypes.SET_SETTINGS_LOCATION,
            payload: { location }
          });
        } catch (e) {
          console.error("failed to get location permissions", e);
        }
      })();
    }
  }, []);

  let text = "Waiting..";
  if (errorMsg) {
    text = errorMsg;
  } else if (location) {
    text = JSON.stringify(location);
  }

  // console.log("location ", location);
  // console.log("errorMsg ", errorMsg);

  return [location, errorMsg];
}
