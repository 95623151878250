import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledLayout } from "../../../components/styles/screen-layout";
import { useTranslation } from "react-i18next";
import { fetchUserRefunds } from "../../../redux/thunks/refunds-thunk";
import Title from "../../../components/basic/title";
import styled from "styled-components/native";
import NoItemsFound from "../../../components/no-items-found";
import GenericList from "../../../components/generic-list";
import ActivityItem from "./activity-item";

const StyledView = styled(StyledLayout)`
  padding: 16px 16px 0 16px;
`;

const StyledTitle = styled(Title)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledHeader = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function MyRefunds({ navigation }) {
  const dispatch = useDispatch();
  const userRefunds = useSelector((state) => state.refunds?.userRefunds) || [];
  const isLoadingUserRefunds = useSelector(
    (state) => state.refunds?.isLoadingUserRefunds
  );
  const { t } = useTranslation();
  console.log("isUserRefundsLoaded ", isLoadingUserRefunds);

  useEffect(() => {
    console.log("fetchUserRefunds ");

    dispatch(fetchUserRefunds());
  }, []);

  const renderItem = ({ item }) => (
    <ActivityItem item={item} navigation={navigation} />
  );

  if (userRefunds.length === 0) {
    return <NoItemsFound text={t("You have no refunds for sale")} />;
  }
  return (
    <StyledView>
      <GenericList
        items={userRefunds}
        navigation={navigation}
        renderItem={renderItem}
      />
    </StyledView>
  );
}
