import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import MessagesScreen from "../screens/messages";
import { useTheme } from "styled-components/native";
import { getHeaderStyle } from "./nav-style";
import RefundDetails from "../screens/refund-details/refund-details";
import BackButton from "../components/basic/buttons/back-button";
import DefaultHeader from "./default-header";
import { headerHeight } from "./nav-style";
import { useTranslation } from "react-i18next";
import RequestScreen from "../screens/messages/request-screen/request-screen";
import ChatScreen from "../screens/chat/chat-screen";
import UserRequestStackNavigator from "./user-request-stack-navigator";
import RefundDetailsStackNavigator from "./refund-details-stack-navigator";

const Stack = createStackNavigator();

export default function MessagesStackNavigator() {
  const theme = useTheme();
  const headerStyle = getHeaderStyle(theme);
  const { t } = useTranslation();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Messages"
        component={MessagesScreen}
        options={{ headerShown: false, ...headerStyle }}
      />
      <Stack.Screen
        name="RefundDetails"
        component={RefundDetailsStackNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="RequestScreen"
        component={UserRequestStackNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ChatScreen"
        component={ChatScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
}
