export const DEFAULT_ERROR = "System error";
export const SOMETHING_WRONG = "Something went wrong, please try again";

export const getErrorMessage = (response) => {
  let errorMessage = DEFAULT_ERROR;
  if (!response) {
    return errorMessage;
  }
  if (response.error) {
    errorMessage = response.error;
  } else if (response.errors) {
    errorMessage = response.errors[0]?.msg || response.errors[0];
  }
  return errorMessage;
};
