import React, { useEffect, useMemo } from "react";
import BaseText from "../../../components/basic/base-text";
import styled from "styled-components/native";
import Date from "../../../components/basic/date";
import Separator from "../../../components/basic/separator";
import { TouchableOpacity, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { fetchRefundById } from "../../../redux/thunks/refunds-thunk";
import { getChatRefundTitle, getChatUserTitle } from "../../../utils/chat-utils";
import PhotoMessage from "./photo-message";
import BadgedLabelIcon from "../../../components/basic/badged-label-icon";
import { actionTypes } from "../../../redux/actions/action-types";
import { useTranslation } from "react-i18next";

const StyledContainer = styled.View`
  width: 100%;
  position: relative;
  margin-bottom: 16px;
`;

const StyledHeader = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const StyledTitle = styled(BadgedLabelIcon)`
  font-weight: 600;
  font-size: 14px;
  margin-right: auto;
`;

const StyledDate = styled(Date)`
  margin-left: auto;
  font-size: 12px;
`;

export default function MessageCard({ item, isLast }) {
  const {
    threadId,
    fromUserId,
    toUserId,
    type,
    refundId,
    text,
    image,
    createdAt
  } = item;
  const navigation = useNavigation();
  const { t } = useTranslation();
  const userId = useSelector((state) => state.auth?.user?.id);
  const senderId = fromUserId === userId ? toUserId : fromUserId;
  const sender = useSelector((state) => state.auth?.users[senderId]);
  const refund = useSelector((state) => state.refunds.itemsById[refundId]);
  const dispatch = useDispatch();
  const threadIdToUnreadCount =
    useSelector((state) => state.messages.threadIdToUnreadCount) || {};
  const count = threadIdToUnreadCount[threadId] || 0;

  useEffect(() => {
    if (refundId && !refund) {
      dispatch(fetchRefundById({ id: refundId }));
    }
  }, [refund, refundId]);

  const title = useMemo(() => {
    let res = "";
    if (sender && refund?.title) {
      const title = getChatRefundTitle({ user: sender, refundTitle: refund.title });
      res = title;
    } else if (sender) {
      const title = getChatUserTitle(sender);
      res = title;
    }
    return res;
  }, [sender, refund]);

  const handleClickOnChat = () => {
    dispatch({
      type: actionTypes.SET_CURRENT_CHAT,
      payload: { toUser: sender, refundId, threadId }
    });
    navigation.navigate("ChatScreen", { id: threadId });
  };

  return (
    <TouchableOpacity onPress={handleClickOnChat}>
      <StyledContainer>
        <StyledHeader>
          <StyledDate isTime>{createdAt}</StyledDate>
          <StyledTitle
            label={title}
            badge={count}
            oneLine
            styles={{ marginRight: "auto" }}
          />
        </StyledHeader>
        <View>
          {text && <BaseText>{text}</BaseText>}
          {image && <PhotoMessage />}
        </View>
        {!isLast ? (
          <Separator marginTop={16} />
        ) : (
          <View style={{ marginBottom: 16, marginTop: 16 }} />
        )}
      </StyledContainer>
    </TouchableOpacity>
  );
}
