import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { ALERT_TYPE, Toast } from "react-native-alert-notification";
import { NOTIFICATION_TYPE } from "../redux/reducers/core-reducer";

const notificationTypeToAlert = {
  [NOTIFICATION_TYPE.ERROR]: ALERT_TYPE.DANGER,
  [NOTIFICATION_TYPE.WARNING]: ALERT_TYPE.WARNING,
  [NOTIFICATION_TYPE.INFO]: ALERT_TYPE.SUCCESS,
  [NOTIFICATION_TYPE.SUCCESS]: ALERT_TYPE.SUCCESS
};

export function useNotificationAlert() {
  const notification = useSelector((state) => state.core?.notification);

  useEffect(() => {
    if (notification) {
      const { type, message } = notification;
      // Toast.show({
      //   type: notificationTypeToAlert[type],
      //   title: type,
      //   textBody: message
      // });

      Toast.show({
        type: ALERT_TYPE.SUCCESS,
        title: "Success",
        textBody: "Congrats! this is toast notification success"
      });
    } else {
      Toast.hide();
    }
  }, [notification]);
}
