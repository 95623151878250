import React, { useCallback, useEffect } from "react";
import styled from "styled-components/native";
import { layoutWeb, StyledLayout } from "../../components/styles/screen-layout";
import { useDispatch, useSelector } from "react-redux";
import Link from "../../components/basic/link";
import { useTranslation } from "react-i18next";
import BaseText from "../../components/basic/base-text";
import Date from "../../components/basic/date";
import CardPriceTitle from "../../components/refund/refund-card-price-title";
import OutlineButton from "../../components/basic/buttons/outline-button";
import Button from "../../components/basic/buttons/button";
import { toggleFavorite } from "../../redux/actions/favorites-actions";
import Title from "../../components/basic/title";
import ImagesGallery from "../../components/basic/images-gallery";
import { sendRequestForItem } from "../../redux/thunks/user-requests-thunk";
import RefundType from "../../components/refund/refund-type";
import { fetchUserById } from "../../redux/thunks/auth-thunk";
import { fetchRefundById } from "../../redux/thunks/refunds-thunk";
import ShareButton from "../../components/share/share-button";
import Loader from "../../components/loaders/loader";
import useCardPriceTitle from "../../hooks/use-card-price-title";
import UserDetails from "./user-details";
import RefundStatus from "./refund-status";
import DisplayLocation from "./display-location";
import ExpirationDate from "./expiration-date";
import useRefundStatus from "../../hooks/use-refund-status";
import { spaceBetweenFlexRow } from "../../components/styles/global-styles";
import SpaceBetweenRow from "../../components/basic/rows/space-between-row";
import { SavePercentage } from "./save-percentage";
import useUser from "../../hooks/use-user";
import HeaderWithBackButton from "../../navigation/header-with-back-button";
import { actionTypes } from "../../redux/actions/action-types";
import { createThreadId } from "../../utils/chat-utils";

const StyledImageSlider = styled(ImagesGallery)`
  height: 200px;
  width: 100%;
  border-radius: 7px;
`;

const StyledTitle = styled(Title)`
  font-weight: 600;
  font-size: 16px;
`;

const StyledTitleStatus = styled(SpaceBetweenRow)`
  margin-bottom: 12px;
`;

const StyledDescription = styled(Title)`
  max-height: 100px;
  border: 1px solid ${(props) => props.theme.palette.input.border};
  border-radius: 4px;
  padding: 4px;
  width: 100%;
`;

const StyledContent = styled.ScrollView`
  padding: 16px;
  padding-bottom: 100px;
  flex: 1;
  ${layoutWeb};
  overflow: auto;
`;

const StyledFooterFix = styled.View`
  height: 60px;
`;

const StyledTitleContainer = styled.View`
  display: flex;
  margin-bottom: 8px;
`;

const StyledButtonContainer = styled.View`
  display: flex;
  width: 100%;
`;

const StyledButton = styled(Button)``;

const StyledOutlinedButton = styled(OutlineButton)``;

const StyledShareButton = styled(ShareButton)`
  margin-bottom: 16px;
`;

const StyledRow = styled.View`
  margin-bottom: 8px;
  align-items: center;
  ${spaceBetweenFlexRow()}
`;

const StyledName = styled(Title)`
  font-weight: 600;
  font-size: 16px;
`;

const StyledExpirationDate = styled(ExpirationDate)`
  margin-bottom: 8px;
  align-items: center;
`;

const StyledRefundType = styled(RefundType)``;

const StyledCardPriceTitle = styled(CardPriceTitle)`
  margin-bottom: 8px;
`;

export default function RefundDetails({ route, navigation }) {
  const { id } = route.params;
  console.log("RefundDetails route ", route);

  const item = useSelector((state) => state.refunds.itemsById[id]);
  console.log("RefundDetails ", item);
  const sellerId = item?.userId;
  const isFavorite = useSelector((state) => state.favorites.refundIds[id]);
  const isRequestSent = useSelector((state) => state?.userRequests?.ids[id]);
  const isRequestLoading = useSelector((state) => state?.userRequests?.isLoading);
  const userId = useSelector((state) => state.auth?.user?.id);
  const isUserRefund = userId === sellerId;
  const seller = useSelector((state) => state.auth.users[sellerId]);
  const {
    title,
    description,
    categoryId,
    expirationDate,
    images = [],
    storeName,
    storeWebsite,
    createdAt,
    updatedAt,
    type,
    status,
    otherType,
    ticketLocation,
    storeLocation
  } = item || {};
  const category = useSelector((state) => state.categories.categoryById[categoryId]);
  const [isLoggedIn] = useUser();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const calculatedStatus = useRefundStatus({ status, expirationDate });
  const [
    formattedPrice,
    formattedRefundAmount,
    discountPercentage
  ] = useCardPriceTitle({ item });
  const threadId = createThreadId({
    fromUserId: userId,
    toUserId: seller?.id,
    refundId: id
  });

  const shareMessage = `${title}\n${t("Total amount")} ${formattedRefundAmount} ${t(
    "selling for"
  )} ${formattedPrice}\n${storeName}`;

  useEffect(() => {
    if (!item && id) {
      dispatch(fetchRefundById({ id }));
    }
    if (!seller && sellerId) {
      dispatch(fetchUserById({ userId: sellerId }));
    }
  }, [id, item, dispatch, sellerId, seller]);

  const onPressFavorite = useCallback(() => {
    if (isLoggedIn) {
      dispatch(toggleFavorite({ item, isAdd: !isFavorite }));
    } else {
      navigation.navigate("Login");
    }
  }, [item, isFavorite, isLoggedIn]);

  const contactSeller = useCallback(() => {
    if (isLoggedIn) {
      dispatch({
        type: actionTypes.SET_CURRENT_CHAT,
        payload: {
          toUser: seller,
          refundId: id,
          backScreen: "RefundDetails",
          tab: 0,
          backParams: { id },
          threadId
        }
      });
      navigation.navigate("ChatScreen", {
        id: threadId
      });
    } else {
      navigation.navigate("Login");
    }
  }, [seller, isLoggedIn]);

  const onSendRequestForItem = useCallback(() => {
    if (isLoggedIn) {
      dispatch(
        sendRequestForItem({
          refundId: id,
          sellerId: sellerId,
          refundTitle: item.title
        })
      );
    } else {
      navigation.navigate("Login");
    }
  }, [dispatch, sendRequestForItem, id, sellerId, item, isLoggedIn]);

  if (!item) {
    return (
      <StyledLayout>
        <Loader />
      </StyledLayout>
    );
  }

  const onPressBack = () => {
    navigation.canGoBack() ? navigation.goBack(null) : navigation.replace("Home");
  };

  return (
    <StyledLayout>
      <HeaderWithBackButton text={t("Details")} onPressBack={onPressBack} />
      <StyledContent>
        <StyledTitleContainer>
          <StyledTitleStatus>
            <StyledTitle>{title}</StyledTitle>
            {!!calculatedStatus && <RefundStatus status={calculatedStatus} />}
          </StyledTitleStatus>
          <StyledImageSlider images={images} />
        </StyledTitleContainer>
        <StyledCardPriceTitle item={item} isFavorite />
        <StyledRow>
          <StyledRefundType type={type} otherType={otherType} />
        </StyledRow>
        {!!storeName && (
          <>
            <StyledRow>
              <StyledName oneLine>{storeName}</StyledName>
              {storeWebsite && storeWebsite.startsWith("https://") ? (
                <Link url={storeWebsite}>{t("Visit Website")}</Link>
              ) : (
                <BaseText> {storeWebsite || ""}</BaseText>
              )}
            </StyledRow>
            {!!storeLocation?.description && (
              <StyledRow>
                <BaseText>{t("Store Location")}</BaseText>
                <BaseText>{storeLocation?.description}</BaseText>
              </StyledRow>
            )}
          </>
        )}
        <StyledRow>
          <SavePercentage percentage={discountPercentage} />
        </StyledRow>
        <StyledRow>
          <BaseText>{t("Category")}</BaseText>
          <BaseText>{t(category)}</BaseText>
        </StyledRow>
        {description ? (
          <StyledRow>
            <StyledDescription>{description}</StyledDescription>
          </StyledRow>
        ) : null}
        {!isUserRefund && (
          <StyledButtonContainer>
            <StyledButton
              title={
                isRequestSent
                  ? t("Your request has been sent")
                  : t("I want this item")
              }
              onPress={onSendRequestForItem}
              disabled={isRequestSent || isRequestLoading}
              buttonStyle={{ marginBottom: 16 }}
            />
            {/* {allowBids && (
           <StyledOutlinedButton title={t("Make an offer")} onPress={() => {}} />
         )} */}
            <StyledOutlinedButton
              title={isFavorite ? t("Remove from Watchlist") : t("Add to Watchlist")}
              onPress={onPressFavorite}
              isFavorite={isFavorite}
              buttonStyle={{ marginBottom: 16 }}
            />
            <StyledOutlinedButton
              title={t("Contact seller")}
              onPress={contactSeller}
              buttonStyle={{ marginBottom: 16 }}
            />
          </StyledButtonContainer>
        )}
        <StyledShareButton
          title={t("Share")}
          id={id}
          message={shareMessage}
          images={images}
        />

        <StyledRow>
          <BaseText>{t("Created at")}</BaseText>
          <Date>{createdAt}</Date>
        </StyledRow>
        <StyledRow>
          <BaseText>{t("Updated at")}</BaseText>
          <Date>{updatedAt}</Date>
        </StyledRow>
        <StyledRow>
          <StyledExpirationDate
            status={calculatedStatus}
            expirationDate={expirationDate}
          />
        </StyledRow>
        <UserDetails item={item} seller={seller} />
        {!!ticketLocation?.description && (
          <StyledRow style={{ marginTop: 8 }}>
            <DisplayLocation label={t("Ticket Location")} data={ticketLocation} />
          </StyledRow>
        )}

        {/*{storeLocation?.description && (*/}
        {/*  <StyledRow>*/}
        {/*    <DisplayLocation label={t("Store Location")} data={storeLocation} />*/}
        {/*  </StyledRow>*/}
        {/*)}*/}
        <StyledFooterFix />
      </StyledContent>
    </StyledLayout>
  );
}
