import React from "react";
import AddEditRefund from "../screens/add-refund/add-edit-refund";
import Home from "../screens/home/home";
import { createStackNavigator } from "@react-navigation/stack";
import Register from "../screens/account/register";
import Login from "../screens/account/login";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components/native";
import History from "../screens/history/history";
import { getHeaderStyle } from "./nav-style";
import ChatScreen from "../screens/chat/chat-screen";
import AboutStackNavigator from "./about-stack-navigator";
import RefundDetailsStackNavigator from "./refund-details-stack-navigator";
import ForgotPassword from "../screens/account/forgot-password";
import ResetPassword from "../screens/account/reset-password";
import AdminScreen from "../screens/account/admin/admin-screen";
import { headerOptions } from "./header-options";
import { APP_NAME } from "../constants/app-constants";

const Stack = createStackNavigator();

export default function HomeStackNavigator() {
  const { t } = useTranslation();
  const theme = useTheme();
  const headerStyle = getHeaderStyle(theme);

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: theme.palette.background.primary,
          borderBottomWidth: 0,
          height: 50,
          width: "100%"
        },
        headerTintColor: theme.palette.font.primary
      }}
    >
      <Stack.Screen name="Home" component={Home} options={{ headerShown: false }} />
      <Stack.Screen
        name="RefundDetails"
        component={RefundDetailsStackNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="AddEditRefund"
        component={AddEditRefund}
        options={{ headerShown: false }}

        // options={headerOptions(t("Add Item"))}
      />
      <Stack.Screen
        name="ChatScreen"
        component={ChatScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Register"
        component={Register}
        options={{ headerShown: false, ...headerStyle }}
      />
      <Stack.Screen
        name="Login"
        component={Login}
        options={{ headerShown: false, ...headerStyle }}
      />
      <Stack.Screen
        name="ForgotPassword"
        component={ForgotPassword}
        options={{ headerShown: false, ...headerStyle }}
      />
      <Stack.Screen
        name="ResetPassword"
        component={ResetPassword}
        options={{ headerShown: false, ...headerStyle }}
      />

      <Stack.Screen
        name="History"
        component={History}
        options={{ title: t("History"), ...headerStyle }}
      />
      {/* <Stack.Screen
        name="MyRefunds"
        component={MyRefunds}
        options={{ title: t("My Refunds"), ...headerStyle }}
      /> */}
      {/* <Stack.Screen
        name="UserRequestedRefunds"
        component={UserRequestedRefunds}
        options={{ title: t("User Requests"), ...headerStyle }}
      /> */}
      <Stack.Screen
        name="About"
        component={AboutStackNavigator}
        options={{
          title: `${t("About")} ${APP_NAME}`,
          headerShown: false,
          ...headerStyle
        }}
      />
      <Stack.Screen
        name="AdminScreen"
        component={AdminScreen}
        options={{ headerShown: false, ...headerStyle }}
      />
    </Stack.Navigator>
  );
}
