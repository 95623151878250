import styled, { css } from "styled-components/native";
import { DESKTOP_MAX_WIDTH, DESKTOP_REGISTER_MAX_WIDTH } from "./constants";
import { isIos, isWeb } from "../../utils/core-utils";

export const marginTop = isWeb() ? 0 : isIos() ? 16 : 42;

export const layoutWeb = (props) => {
  if (isWeb() && props.theme.media.isAboveLg) {
    return css`
      max-width: ${DESKTOP_MAX_WIDTH}px;
      margin: 0 auto;
      width: 100%;
    `;
  }
};

export const layoutWebWidth = (props) => {
  if (isWeb() && props.theme.media.isAboveLg) {
    return css`
      max-width: ${DESKTOP_MAX_WIDTH}px;
      width: 100%;
    `;
  }
};

export const registerWebWidth = (props) => {
  if (isWeb() && props.theme.media.isAboveLg) {
    return css`
      max-width: ${DESKTOP_REGISTER_MAX_WIDTH}px;
      width: 100%;
      margin: 0 auto;
    `;
  }
};

export const StyledLayout = styled.View`
  display: flex;
  height: 100%;
  width: 100%;
  font-size: 14px;
  background-color: ${(props) => props.theme.palette.background.primary};
  margin: 0;
  font-family: ${(props) => props.theme.typography.mainFont};
  ${layoutWeb};
`;

export const StyledScrollLayout = styled.ScrollView`
  display: flex;
  height: 100%;
  width: 100%;
  font-size: 14px;
  background-color: ${(props) => props.theme.palette.background.primary};
  margin: 0;
  font-family: ${(props) => props.theme.typography.mainFont};
  ${layoutWeb};
`;
