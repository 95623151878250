import React from "react";
import Chat from "./chat";
import { useSelector } from "react-redux";

export default function ChatScreen({ route, navigation }) {
  const { id } = route.params;
  console.log("ChatScreen id ", id);
  console.log("ChatScreen route ", route);
  const currentChat = useSelector((state) => state.chats?.currentChat);
  console.log("currentChat ", currentChat);
  const { toUser, refundId, backScreen, tab, backParams } = currentChat || {};
  if (!currentChat) {
    navigation.navigate("Home");
    return null;
  }
  return (
    <Chat
      id={id}
      toUser={toUser}
      refundId={refundId}
      backScreen={backScreen}
      tab={tab}
      backParams={backParams}
    />
  );
}
