import { actionTypes } from "../actions/action-types";
import { useSelector } from "react-redux";

export const REFUNDS_REQUEST_SIZE = 10;

const initialState = {
  offset: 0,
  size: null,
  hasMore: true,
  filters: {
    sort: {
      name: "createdAt",
      order: "desc"
    }
  },
  isLoading: false,
  items: [],
  itemsById: {},
  userRefunds: [],
  userRefundsLoaded: false,
  isLoadingUserRefunds: false,
  isScrollTop: false,
  isFetchingRefund: false,
  userRequestedRefunds: [],
  isLoadingUserRequestedRefunds: false,
  isProcessing: false,
  isLoaded: false
};

const refundsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.FETCH_REFUNDS: {
      const { refunds = [], offset } = payload;
      const itemsById = {};

      if (refunds) {
        refunds.forEach((item) => {
          itemsById[item.id] = item;
        });
      }
      if (offset > 0) {
        return {
          ...state,
          items: [...state.items, ...refunds],
          size:
            refunds.length === 0
              ? state.items.length
              : state.items.length + refunds.length,
          hasMore: refunds.length < REFUNDS_REQUEST_SIZE ? false : true,
          offset: offset,
          itemsById: { ...state.itemsById, ...itemsById },
          isLoaded: true
        };
      } else {
        return {
          ...state,
          items: refunds,
          size: refunds.length,
          offset: 0,
          hasMore: refunds.length < REFUNDS_REQUEST_SIZE ? false : true,
          itemsById: { ...state.itemsById, ...itemsById },
          isLoaded: true
        };
      }
    }
    case actionTypes.FETCH_USER_REFUNDS: {
      const { refunds = [] } = payload;
      const userRefundsById = {};

      if (refunds) {
        refunds.forEach((item) => {
          userRefundsById[item.id] = item;
        });
      }

      return {
        ...state,
        userRefunds: refunds,
        itemsById: { ...state.itemsById, ...userRefundsById },
        userRefundsLoaded: true
      };
    }
    case actionTypes.ADD_REFUND: {
      const { item } = payload;
      const id = item.id;
      return {
        ...state,
        itemsById: { [id]: item, ...state.itemsById },
        items: [item, ...state.items],
        userRefunds: [item, ...state.userRefunds]
      };
    }
    case actionTypes.FETCH_REFUND_BY_ID: {
      const { item } = payload;
      const id = item.id;
      return {
        ...state,
        itemsById: { [id]: item, ...state.itemsById }
      };
    }
    case actionTypes.TOGGLE_REFUNDS_LOADING: {
      return {
        ...state,
        isLoading: payload
      };
    }
    case actionTypes.REFUNDS_LIST_SCROLL_TOP_TOGLLE: {
      return {
        ...state,
        isScrollTop: payload
      };
    }
    case actionTypes.TOGGLE_FETCH_REFUND_BY_ID_LOADING: {
      return {
        ...state,
        isFetchingRefund: payload
      };
    }
    case actionTypes.TOGGLE_USER_REFUNDS_LOADING: {
      return {
        ...state,
        isLoadingUserRefunds: payload
      };
    }
    case actionTypes.TOGGLE_USER_REQUESTED_REFUNDS_LOADING: {
      return {
        ...state,
        isLoadingUserRequestedRefunds: payload
      };
    }
    case actionTypes.FETCH_USER_REQUESTED_REFUNDS: {
      return {
        ...state,
        userRequestedRefunds: payload
      };
    }
    case actionTypes.TOGGLE_IS_PROCESSING: {
      return {
        ...state,
        isProcessing: payload
      };
    }
    case actionTypes.UPDATE_REFUND_STATUS: {
      const { id, status } = payload;
      const item = state.itemsById[id];
      item.status = status;

      const foundItem = state.items.find((item) => item.id === id);
      if (foundItem) {
        const i = state.items.indexOf(foundItem);
        foundItem.status = status;
        state.items[i] = foundItem;
      }

      const foundUserItem = state.userRefunds.find((item) => item.id === id);
      if (foundUserItem) {
        const i = state.userRefunds.indexOf(foundUserItem);
        foundUserItem.status = status;
        state.userRefunds[i] = foundUserItem;
      }

      return {
        ...state,
        itemsById: state.itemsById,
        items: state.items,
        userRefunds: state.userRefunds
      };
    }
    case actionTypes.DELETE_REFUND: {
      const { id } = payload;

      state.itemsById[id] = null;
      const items = state.items.filter((item) => item.id !== id);
      const userRefunds = state.userRefunds.filter((item) => item.id !== id);

      return {
        ...state,
        itemsById: state.itemsById,
        items: items,
        userRefunds: userRefunds
      };
    }
    case actionTypes.UPDATE_REFUND: {
      const { id, item } = payload;
      if (!id || !item) {
        console.log("UPDATE_REFUND id ", id, " item ", item);
        return state;
      }
      const foundItem = state.items.find((item) => item.id === id);
      if (foundItem) {
        const i = state.items.indexOf(foundItem);
        if (i !== -1) {
          state.items[i] = item;
        }
      }

      const foundUserItem = state.userRefunds.find((item) => item.id === id);
      if (foundUserItem) {
        const i = state.userRefunds.indexOf(foundUserItem);
        if (i !== -1) {
          state.userRefunds[i] = item;
        }
      }

      return {
        ...state,
        itemsById: { ...state.itemsById, [id]: item },
        items: [...state.items],
        userRefunds: [...state.userRefunds]
      };
    }
    case actionTypes.SET_REFUNDS_SORT: {
      return {
        ...state,
        filters: {
          ...state.filters,
          sort: payload
        }
      };
    }
    case actionTypes.LOGOUT: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default refundsReducer;
