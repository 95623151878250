import colors from "./colors";

const darkPalette = {
  ...colors,
  separator: "#717171",
  disabled: colors.gray[5],
  white: "#ffffff",
  font: {
    primary: "#b9bfcb",
    secondary: "#aaaaaa",
    tertiary: "#f0f0f0",
    forth: "#d5d5d5",
    error: "#782c61"
  },
  background: {
    primary: "#1e1e1e",
    secondary: "#2d2e30",
    tertiary: ""
  },
  backgroundOld: {
    primary: "#151a30",
    secondary: "#222b45",
    tertiary: ""
  },
  button: {
    primary: {
      color: "#3e73f0",
      hover: "#3e73f0",
      font: "#ffffff",
      border: "#1f3b80",
      disabled: {
        color: colors.gray[5],
        font: colors.gray[3]
      }
    },
    secondary: {
      color: "#1266bd",
      hover: "#0d4885",
      font: "#ffffff"
    },
    tertiary: {
      color: "#233251",
      hover: "#233251",
      text: "#ffffff",
      border: "#484848"
    },
    menu: {
      color: "#6b98ff",
      hover: "#3e73f0",
      font: "#ffffff"
    },
    outlined: {
      color: "#1e1e1e",
      border: colors.gray[1],
      backgroundHover: "#ffffff",
      font: colors.gray[1],
      textHover: "#1e1e1e"
    },
    disabled: "rgba(255, 255, 255, 0.12)",
    info: {
      color: "#35363a",
      hover: "#35363a",
      font: "#ffffff"
    }
  },
  link: {
    color: "#ffffff",
    hover: "#ffffff",
    disabled: colors.gray[5]
  },
  modal: {
    layout: "rgba(22, 22, 22, 0.5)",
    background: "#252529"
  },
  label: {
    color: "#263c85"
  },
  social: {
    twitter: "#55acee",
    facebook: "#4267b2",
    linkedin: "#0077b5",
    whatsapp: "#25d366"
  },
  error: {
    font: "#cf7094"
  },
  icons: {
    primary: "#c8c8c8",
    secondary: "#c8c8c8",
    tertiary: "#c8c8c8",
    active: "#3e73f0"
  },
  input: {
    placeholder: "#717171",
    border: "#717171",
    background: "#1e1e1e",
    font: "#b9bfcb",
    label: {
      color: "#aaa",
      focusedColor: "#000"
    }
  },
  price: {
    red: "#ffffff",
    green: "#26b564"
  },
  addImage: {
    font: "#717171",
    background: colors.gray[8]
  },
  datePicker: {
    background: colors.gray[8]
  },
  tabs: {
    active: "#b9bfcb",
    inactive: "#737373",
    indicator: "#b9bfcb"
  },
  color: {
    primary: "#3e73f0"
  },
  backdrop: {
    color: "#2d2e30"
  },
  chat: {
    right: {
      backgroundColor: "#1f3a78",
      color: "#fff"
    },
    left: {
      backgroundColor: "#2A2F3A",
      color: "#fff"
    }
  },
  menu: {
    backgroundColor: colors.gray[8],
    color: "#b9bfcb"
  },
  calendar: {
    selectedText: "#fff"
  }
};

export default darkPalette;
