import { combineReducers } from "redux";
import refundsReducer from "./reducers/refunds-reducer";
import authReducer from "./reducers/auth-reducer";
import settingsReducer from "./reducers/settings-reducer";
import categoriesReducer from "./reducers/categories-reducer";
import favoritesReducer from "./reducers/favorites-reducer";
import coreReducer from "./reducers/core-reducer";
import searchReducer from "./reducers/search-reducer";
import userRequestsReducer from "./reducers/user-requests-reducer";
import messagesReducer from "./reducers/messages-reducer";
import notificationsReducer from "./reducers/notifications-reducer";
import buyRequestsReducer from "./reducers/buy-requests-reducer";
import chatsReducer from "./reducers/chats-reducer";
import activityReducer from "./reducers/activity-reducer";

const rootReducer = combineReducers({
  core: coreReducer,
  refunds: refundsReducer,
  auth: authReducer,
  settings: settingsReducer,
  categories: categoriesReducer,
  favorites: favoritesReducer,
  search: searchReducer,
  userRequests: userRequestsReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
  buyRequests: buyRequestsReducer,
  chats: chatsReducer,
  activity: activityReducer
});

export default rootReducer;
