import React from "react";
import { AddButton } from "./add-button";
import { InfoButton } from "./info-button";

export const MenuButtons = () => {
  return (
    <>
      <InfoButton />
      <AddButton />
    </>
  );
};
