import React from "react";
import styled from "styled-components/native";
import { baseFlexRow } from "../../styles/global-styles";

const Container = styled.View`
  ${baseFlexRow}
`;

export default function BaseRow({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}
