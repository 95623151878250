import React from "react";
import { spaceBetweenFlexRow } from "../../components/styles/global-styles";
import BaseText from "../../components/basic/base-text";
import { useTranslation } from "react-i18next";
import { MaterialIcons } from "@expo/vector-icons";
import styled, { useTheme } from "styled-components/native";
import { StyledLayout } from "../../components/styles/screen-layout";

const StyledContainerLayout = styled(StyledLayout)`
  padding: 20px 0;
  display: flex;
  align-items: center;
`;

const StyledContainer = styled.View`
  ${spaceBetweenFlexRow}
`;

const StyledText = styled(BaseText)`
  margin-right: 8px;
`;

export default function EmptyFavorites() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledContainerLayout>
      <StyledContainer>
        <StyledText>{t("You have no favorites yet")}</StyledText>
        <MaterialIcons
          name={"favorite-outline"}
          size={20}
          color={theme.palette.font.primary}
        />
      </StyledContainer>
    </StyledContainerLayout>
  );
}
