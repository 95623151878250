import lightPalette from "./light-palette";
import darkPalette from "./dark-palette";
import { breakpoints } from "./breakpoints";
import { typography } from "./typography";

export const DEFAULT_THEME = "dark";

export const LIGHT_THEME = "light";
export const DARK_THEME = "dark";

const themes = {
  [LIGHT_THEME]: {
    palette: lightPalette,
    type: LIGHT_THEME,
    breakpoints,
    typography
  },
  [DARK_THEME]: {
    palette: {
      ...lightPalette,
      ...darkPalette,
      type: DARK_THEME
    },
    breakpoints,
    typography
  }
};

export default themes;
