import React from "react";
import styled from "styled-components/native";

const Container = styled.Text`
  color: ${(props) => props.theme.palette.font.primary};
  font-size: 14px;
  font-family: ${(props) => props.theme.typography.mainFont};
  ${(props) =>
    props.theme.language !== "ru"
      ? `font-family: ${props.theme.typography.mainFont};`
      : ""};
`;

export default function BaseText({ children, oneLine, className, ...rest }) {
  return (
    <Container
      numberOfLines={oneLine ? 1 : undefined}
      className={className}
      {...rest}
    >
      {children}
    </Container>
  );
}
