import React, { useEffect, useRef, useState } from "react";
import { KeyboardAvoidingView, Platform, ScrollView } from "react-native";
import styled, { useTheme } from "styled-components/native";
import { Formik } from "formik";
import {
  createRefund,
  fetchRefundById,
  updateRefund
} from "../../redux/thunks/refunds-thunk";
import BaseText from "../../components/basic/base-text";
import Error from "../../components/error";
import Loader from "../../components/loaders/loader";
import { useTranslation } from "react-i18next";
import Button from "../../components/basic/buttons/button";
import SelectCategories from "./select-categories";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../components/basic/input-field";
import ExpirationDate from "./expiration-date";
import LocationSearch from "../../components/location/location-search";
import { SINGLE_ITEM } from "./select-availability";
import Checkbox from "../../components/basic/checkbox";
import { NONE, OTHER, storeTitles, TICKET } from "../../utils/refund-types-utils";
import SelectType from "./select-type";
import ImagesInputContainer from "../../components/images-picker/images-input-container";
import { deleteImages } from "../../services/upload-image-service";
import { formSchema } from "./refund-form-validation";
import { fetchUserById } from "../../redux/thunks/auth-thunk";
import { changeActivityTab } from "../../redux/actions/activity-actions";
import {
  showError,
  showSuccess,
  toggleModal
} from "../../redux/actions/core-actions";
import { getErrorMessage } from "../../utils/errors-utils";
import {
  layoutWeb,
  StyledScrollLayout
} from "../../components/styles/screen-layout";
import HeaderWithBackButton from "../../navigation/header-with-back-button";
import { ADDED_REFUND } from "../../components/modals/modal-types";

const StyledLabel = styled(BaseText)`
  color: ${(props) => props.theme.palette.input.font};
`;

const StyledForm = styled.View`
  background-color: ${(props) => props.theme.palette.background.primary};
  padding: 20px;
  border-radius: 10px;
  ${layoutWeb};
`;

const StyledError = styled(Error)`
  margin-top: 8px;
`;

const StyledButtonContainer = styled.View`
  margin-top: 20px;
`;

const StyledPage = styled(KeyboardAvoidingView)`
  background-color: ${(props) => props.theme.palette.background.primary};
  width: 100%;
`;

const StyledRow = styled.View`
  margin-bottom: 16px;
`;

const RowSeparator = styled.View`
  margin-bottom: 16px;
`;

const StyledInputRow = styled(InputField)``;

export default function AddEditRefund({ route, navigation }) {
  const { id } = route?.params || {};
  const item = useSelector((state) => state.refunds.itemsById[id]);
  const sellerId = item?.userId;
  const seller = useSelector((state) => state.auth.users[sellerId]);
  const user = useSelector((state) => state.auth?.user);
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.settings?.currency);
  const theme = useTheme();
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  console.log("AddEditRefund ", item);

  useEffect(() => {
    if (!item && id) {
      dispatch(fetchRefundById({ id }));
    }
    if (!seller && sellerId) {
      dispatch(fetchUserById({ userId: sellerId }));
    }
  }, [id, item, dispatch, sellerId, seller]);

  const isEditMode = id && item;

  const initialValues = isEditMode
    ? {
        ...item,
        hasExpirationDate: !!item.expirationDate,
        currentUserSeller: !(item.sellerEmail || item.sellerPhone)
      }
    : {
        title: "",
        description: "",
        price: "",
        refundAmount: "",
        storeName: "",
        storeWebsite: "",
        storeLocation: undefined,
        priceCurrencyCode: currency,
        refundAmountCurrencyCode: currency,
        hasExpirationDate: false,
        expirationDate: undefined,
        categoryId: "",
        numberOfItems: 1,
        ticketLocation: undefined,
        availability: SINGLE_ITEM,
        allowBids: false,
        boostCampaign: false,
        type: NONE,
        otherType: "",
        currentUserSeller: true,
        sellerName: "",
        sellerPhone: "",
        sellerEmail: "",
        images: []
      };

  if (id && !item) {
    return <Loader />;
  }

  const onSubmit = (values) => {
    console.log("values ", values);
    const { hasExpirationDate, currentUserSeller, ...data } = values;
    setIsSubmitting(true);
    if (isEditMode) {
      onEditSubmit(data);
    } else {
      onAddSubmit(data);
    }
  };

  const onAddSubmit = (values) => {
    dispatch(
      createRefund({
        userId: user.id,
        ...values
      })
    )
      .then((result) => {
        console.log("create refund success ", result);

        setIsSubmitting(false);
        dispatch(
          toggleModal({
            open: true,
            modal: ADDED_REFUND,
            data: {
              refund: result
            }
          })
        );
        navigation.navigate("Home");
      })
      .catch((res) => {
        setIsSubmitting(false);
        console.error("create refund failed ", res);
        const errorMessage = getErrorMessage(res);
        dispatch(showError("An error occurred. Please try again.\n" + errorMessage));
        const images = values["images"];
        if (images && images.length > 0) {
          deleteImages(images);
        }
      });
  };

  const onEditSubmit = (values) => {
    dispatch(
      updateRefund({
        ...values
      })
    )
      .then((result) => {
        console.log("edit refund success ", result);

        setIsSubmitting(false);
        dispatch(
          showSuccess({
            message: "Refund updated successfully"
          })
        );
        navigation.navigate("Activity");
        dispatch(changeActivityTab(1));
      })
      .catch((res) => {
        setIsSubmitting(false);
        console.error("update refund failed ", res);
        const errorMessage = getErrorMessage(res);

        dispatch(showError("An error occurred. Please try again.\n" + errorMessage));
        const images = values["images"];
        if (images && images.length > 0) {
          const originalItems = item.images;
          const diffImages = images.filter((item) => !originalItems.includes(item));
          if (diffImages?.length > 0) {
            deleteImages(diffImages);
          }
        }
      });
  };

  const isAddSubmitDisabled = (props) => {
    return !props.dirty || Object.keys(props.errors).length > 0;
  };

  const isEditSubmitDisabled = (props) => {
    return !props.dirty || Object.keys(props.errors).length > 0;
  };

  const isSubmitDisabled = (props) => {
    return isEditMode ? isEditSubmitDisabled(props) : isAddSubmitDisabled(props);
  };

  const handleBackButton = () => {
    if (!isEditMode) {
      const images = ref?.current?.values["images"];
      if (images?.length > 0) {
        deleteImages(images);
      }
    }
    return true;
  };

  return (
    <ScrollView style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <HeaderWithBackButton
        text={isEditMode ? t("Edit Item") : t("Add Item")}
        onBack={handleBackButton}
      />
      <StyledPage behavior={Platform.OS === "ios" ? "padding" : undefined}>
        <ScrollView keyboardShouldPersistTaps="handled">
          <Formik
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={onSubmit}
            innerRef={ref}
          >
            {(props) => (
              <StyledForm>
                <StyledRow>
                  <ImagesInputContainer
                    items={props.values["images"]}
                    onAdd={(image) => {
                      const images = props.values["images"];
                      props.setFieldValue("images", [image, ...images]);
                    }}
                    onDelete={(image) => {
                      const images = props.values["images"];
                      const remainingImages = images.filter(
                        (item) => item !== image
                      );
                      props.setFieldValue("images", remainingImages);
                    }}
                  />
                  <StyledError>
                    {props.touched["images"] && props.errors["images"]}
                  </StyledError>
                </StyledRow>
                <StyledInputRow
                  title={t("Title")}
                  name="title"
                  multiline={false}
                  props={props}
                />
                <StyledInputRow
                  title={t("Refund Amount")}
                  name="refundAmount"
                  keyboardType="numeric"
                  type="number"
                  props={props}
                  isPrice
                  currencyName={"refundAmountCurrencyCode"}
                />
                <StyledInputRow
                  title={t("Selling Price")}
                  name="price"
                  keyboardType="numeric"
                  props={props}
                  isPrice
                  currencyName={"refundAmountCurrencyCode"}
                />

                <StyledRow>
                  <SelectType
                    name="type"
                    value={props.values["type"]}
                    onChange={(value) => {
                      props.setFieldValue("type", value);
                      if (value === TICKET) {
                        props.setFieldValue("hasExpirationDate", true);
                      } else {
                        if (props.values["hasExpirationDate"]) {
                          props.setFieldValue("hasExpirationDate", false);
                        }
                      }
                    }}
                  />
                  <StyledError>
                    {props.touched["type"] && props.errors["type"]}
                  </StyledError>
                </StyledRow>

                {props.values["type"] !== NONE && (
                  <>
                    <StyledInputRow
                      title={t(storeTitles[props.values["type"]]?.name)}
                      name="storeName"
                      props={props}
                    />
                    <StyledInputRow
                      title={t(storeTitles[props.values["type"]]?.website)}
                      name="storeWebsite"
                      keyboardType={"url"}
                      props={props}
                    />
                    <LocationSearch
                      value={props.values["storeLocation"]}
                      selectProps={{
                        name: "storeLocation"
                      }}
                      onChange={(value) => {
                        props.setFieldValue("storeLocation", value);
                        console.log();
                      }}
                      placeholder={`${t(
                        storeTitles[props.values["type"]]?.location
                      )}...`}
                    />
                    <RowSeparator />
                  </>
                )}
                {props.values["type"] === OTHER && (
                  <StyledInputRow
                    title={t("Add info about card type")}
                    name="otherType"
                    props={props}
                  />
                )}

                <StyledRow>
                  <ExpirationDate props={props} />
                </StyledRow>

                <StyledRow>
                  <SelectCategories
                    name="categoryId"
                    value={props.values["categoryId"]}
                    onChange={(value) => {
                      props.setFieldValue("categoryId", value === null ? "" : value);
                    }}
                  />
                  <StyledError>
                    {props.touched["categoryId"] && props.errors["categoryId"]}
                  </StyledError>
                </StyledRow>

                <StyledInputRow
                  title={t("Description")}
                  name="description"
                  multiline={true}
                  props={props}
                  numberOfLines={3}
                  minHeight={80}
                  variant="outline"
                />

                {/* <StyledRow>
                <StyledRow>
                  <SelectAvailability
                    name="availability"
                    value={props.values["availability"]}
                    onChange={(value) => {
                      props.setFieldValue("availability", value);
                    }}
                    // type="underlined"
                  />
                </StyledRow>
                {props.values["availability"] === "in-stock" && (
                  <StyledInputRow
                    title={t("Number of items")}
                    name="numberOfItems"
                    keyboardType="numeric"
                    props={props}
                  />
                )}
                <StyledError>
                  {props.touched["availability"] && props.errors["availability"]}
                </StyledError>
              </StyledRow> */}
                <LocationSearch
                  value={props.values["ticketLocation"]}
                  selectProps={{
                    name: "ticketLocation"
                  }}
                  onChange={(value) => {
                    props.setFieldValue("ticketLocation", value);
                  }}
                  placeholder={`${t("Where is ticket located")}`}
                />
                <StyledError>
                  {props.touched["ticketLocation"] && props.errors["ticketLocation"]}
                </StyledError>
                <RowSeparator />
                {/* <StyledRow>
                <Checkbox
                  name={"allowBids"}
                  value={props.values["allowBids"]}
                  isChecked={props.values["allowBids"]}
                  onChange={(nextValue) => {
                    props.setFieldValue("allowBids", nextValue);
                  }}
                  label={t("Allow user to bid price")}
                />
              </StyledRow>
              <StyledRow>
                <Checkbox
                  name={"boostCampaign"}
                  value={props.values["boostCampaign"]}
                  isChecked={props.values["boostCampaign"]}
                  onChange={(nextValue) => {
                    props.setFieldValue("boostCampaign", nextValue);
                  }}
                  label={t("Boost your refund views after publish")}
                />
              </StyledRow> */}
                <StyledRow>
                  <Checkbox
                    name={"currentUserSeller"}
                    value={props.values["currentUserSeller"]}
                    isChecked={props.values["currentUserSeller"]}
                    onChange={(nextValue) => {
                      props.setFieldValue("currentUserSeller", nextValue);
                    }}
                    label={t("I am the seller")}
                  />
                </StyledRow>
                {props.values["currentUserSeller"] === false && (
                  <>
                    <StyledInputRow
                      title={t("Seller name")}
                      name="sellerName"
                      props={props}
                    />
                    <StyledInputRow
                      title={t("Seller phone")}
                      name="sellerPhone"
                      keyboardType={"phone-pad"}
                      props={props}
                    />
                    <StyledLabel style={{ marginBottom: 16 }}>{t("Or")}</StyledLabel>
                    <StyledInputRow
                      title={t("Seller email")}
                      name="sellerEmail"
                      keyboardType={"email-address"}
                      props={props}
                    />
                  </>
                )}
                <StyledButtonContainer>
                  <Button
                    title={isEditMode ? t("Save") : t("Add")}
                    onPress={props.handleSubmit}
                    // disabled={isSubmitting}
                  />
                </StyledButtonContainer>
              </StyledForm>
            )}
          </Formik>
        </ScrollView>
      </StyledPage>
    </ScrollView>
  );
}
