import { Platform } from "react-native";

import { isWeb } from "../utils/core-utils";

export const getHeaderStyle = (theme) => {
  const headerStyle = {
    headerStyle: {
      backgroundColor: theme.palette.background.primary,
      borderBottomColor: theme.palette.separator
    },
    headerTintColor: theme.palette.font.primary
  };
  return headerStyle;
};

export const getHomeHeaderStyle = (theme) => {
  const headerStyle = {
    headerStyle: {
      backgroundColor: theme.palette.background.primary,
      borderBottomColor: theme.palette.separator
      // height: isWeb() ? 60 : 100
    },
    headerTintColor: theme.palette.font.primary
  };
  return headerStyle;
};
export const headerHeight = isWeb() ? 60 : Platform.OS === "ios" ? 60 : 60;
export const marginTop = isWeb() ? 0 : Platform.OS === "ios" ? 40 : 30;
