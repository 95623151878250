import React, { useCallback, useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import styled from "styled-components/native";
import Title from "../../components/basic/title";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CardPriceTitle from "../../components/refund/refund-card-price-title";
import Date from "../../components/basic/date";
import Separator from "../../components/basic/separator";
import OutlineButton from "../../components/basic/buttons/outline-button";
import { fetchUserById } from "../../redux/thunks/auth-thunk";
import { AVAILABLE, NOT_AVAILABLE } from "../../utils/user-requests-utils";
import { updateUserRequestStatus } from "../../redux/thunks/user-requests-thunk";
import { useNavigation } from "@react-navigation/native";
import {
  REQUEST_CREATED,
  REQUEST_STATUS_UPDATED,
  statusToDisplay
} from "../../constants/user-request-types";
import { REFUND_DELETED } from "../../components/refund/refund-status";
import BaseText from "../../components/basic/base-text";
import RefundItemMenu from "./refund-item-menu";
import BaseRow from "../../components/basic/rows/base-row";
import ItemImage from "../../components/refund/item-image";
import { useUserRefund } from "../../hooks/use-user-refund";
import Loader from "../../components/loaders/loader";
import {
  flexDirection,
  marginRightLeft
} from "../../components/styles/global-styles";
import { actionTypes } from "../../redux/actions/action-types";
import { createThreadId } from "../../utils/chat-utils";

const StyledContainer = styled.View`
  display: flex;
`;

const StyledCard = styled.View`
  display: flex;
`;

const StyledHeader = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const StyledContent = styled.View`
  margin-left: 16px;
`;

const StyledMessage = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  ${flexDirection}
`;

const StyledImage = styled(ItemImage)`
  height: 80px;
  width: 80px;
  border-radius: 7px;
  ${marginRightLeft(8)}
`;

const StyledTitle = styled(Title)`
  font-weight: 600;
  font-size: 14px;
  margin-right: auto;
`;

const StyledName = styled(Title)`
  font-weight: 600;
  font-size: 14px;
`;

const StyledDate = styled(Date)`
  margin-left: auto;
  font-size: 12px;
`;

const StyledFooter = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${flexDirection()}
`;

const StyledOutlinedButton = styled(OutlineButton)`
  margin-top: 8px;
  width: 100%;
  font-size: 12px;
`;

const StyledRefundItemMenu = styled(RefundItemMenu)`
  margin-left: auto;
`;

const StyledBaseText = styled(BaseText)`
  ${marginRightLeft(8)}
`;

export default function RefundRequestMessage({ item, isLast, ...rest }) {
  const { t } = useTranslation();
  const { sellerId, refundId, status, deleted } = item;
  const user = useSelector((state) => state.auth?.user);
  const buyerId = item.userId;
  const buyer = useSelector((state) => state.auth.users[buyerId]);
  const [refund, isLoading] = useUserRefund({ id: refundId });
  const [disabled, setDisabled] = useState(false);
  const threadId = createThreadId({
    fromUserId: user?.id,
    toUserId: buyer?.id,
    refundId
  });
  const isDeleted = deleted === 1;
  const dispatch = useDispatch();
  const navigation = useNavigation();

  useEffect(() => {
    if (!buyer) {
      dispatch(fetchUserById({ userId: buyerId }));
    }
  }, [buyer, buyerId]);

  const getTitleByName = (item) => {
    if (item.type === REQUEST_CREATED) {
      return t("Is this item available?");
    } else if (item.type === REQUEST_STATUS_UPDATED) {
      return (
        t("Your requested item status was updated to ") +
        t(statusToDisplay(item.status))
      );
    }
  };

  const { title, images, createdAt } = refund || {};
  // const uri = images.length > 0 ? images[0] : DEFAULT_CARD_PLACEHOLDER;

  const clickOnAvailable = async () => {
    await changeStatus({ status: AVAILABLE });
  };

  const clickOnNotAvailable = async () => {
    await changeStatus({ status: NOT_AVAILABLE });
  };

  const changeStatus = async ({ status }) => {
    setDisabled(true);

    await dispatch(
      updateUserRequestStatus({
        id: item.id,
        status,
        read: true,
        refundTitle: refund?.title
      })
    );
    setDisabled(false);
  };

  const contactBuyer = useCallback(() => {
    if (user?.id) {
      dispatch({
        type: actionTypes.SET_CURRENT_CHAT,
        payload: {
          threadId,
          toUser: buyer,
          refundId,
          backScreen: "Messages",
          tab: 0
        }
      });
      navigation.navigate("ChatScreen", {
        id: threadId
      });
    } else {
      navigation.navigate("Login");
    }
  }, [buyer, user]);

  const viewRefund = () => {
    if (!isDeleted) {
      navigation.navigate("RefundDetails", {
        id: refundId
      });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (
    refund.status === REFUND_DELETED ||
    refund.deleted === 1 ||
    (!isLoading && !refund)
  ) {
    return null;
  }

  return (
    <StyledContainer {...rest}>
      <StyledHeader>
        <StyledDate isTime>{createdAt}</StyledDate>
        <StyledTitle oneLine>{t("Is this item available?")}</StyledTitle>
      </StyledHeader>
      <TouchableOpacity onPress={viewRefund}>
        <StyledCard>
          <StyledMessage>
            <StyledImage images={images} />
            <StyledContent>
              <CardPriceTitle item={refund} />
              <StyledName oneLine>{title}</StyledName>
            </StyledContent>
            {/*<View style={{ marginLeft: "auto" }}>*/}
            {/*  <StyledRefundItemMenu refundId={refund.id} />*/}
            {/*</View>*/}
          </StyledMessage>
        </StyledCard>
      </TouchableOpacity>
      {buyer && (
        <BaseRow>
          <StyledBaseText>{t("Buyer")}</StyledBaseText>
          <BaseText>{`${buyer.firstName} ${buyer.lastName}`}</BaseText>
        </BaseRow>
      )}
      {!isDeleted ? (
        <StyledFooter>
          <StyledOutlinedButton
            title={t("Contact buyer")}
            onPress={contactBuyer}
            fontSize={12}
            height={30}
            iconSize={16}
            disabled={disabled}
          />
          <StyledOutlinedButton
            title={t("Not Available")}
            onPress={clickOnNotAvailable}
            fontSize={12}
            height={30}
            iconSize={16}
            disabled={disabled || item.status === NOT_AVAILABLE}
            checked={item.status === NOT_AVAILABLE}
          />
          <StyledOutlinedButton
            title={t("Available")}
            onPress={clickOnAvailable}
            fontSize={12}
            height={30}
            iconSize={16}
            disabled={disabled || item.status === AVAILABLE}
            checked={item.status === AVAILABLE}
          />
        </StyledFooter>
      ) : (
        <StyledFooter>
          <BaseText>{t("Item is not available anymore")}</BaseText>
        </StyledFooter>
      )}
      {!isLast ? (
        <Separator marginTop={16} marginBottom={16} />
      ) : (
        <View style={{ marginBottom: 16, marginTop: 16 }} />
      )}
    </StyledContainer>
  );
}
