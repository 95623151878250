import { Platform } from "react-native";
import * as Font from "expo-font";
import * as Device from "expo-device";

export const isAndroid = () => Platform.OS === "android";
export const isIos = () => Platform.OS === "ios";

export const isWeb = () => !isIos() && !isAndroid();
export const isMobile = () => isIos() || isAndroid();
export const isDevice = () => !isWeb() && Device.isDevice;

export const getPlatform = () => {
  let res = Platform.OS;
  if (isWeb()) {
    res = "web";
  }
  return res;
};

export const loadFonts = async () => {
  return await Font.loadAsync({
    "Nunito Sans": require("../../assets/fonts/Nunito_Sans/NunitoSans-Regular.ttf")
  });
};

export const getUserFullName = (user) => {
  return `${user?.firstName}${user?.lastName ? ` ${user?.lastName}` : ""}`;
};

export function getUnreadIds(items) {
  let ids = [];
  if (!items || items.length == 0) {
    return ids;
  }
  items.forEach((item) => {
    if (!item.readAt) {
      ids.push(item.id);
    }
  });
  return ids;
}
