import { actionTypes } from "../../redux/actions/action-types";
import { ENV } from "../../config/config";
import * as coreActions from "../../redux/actions/core-actions";
import { showError } from "../../redux/actions/core-actions";
import { createUrl } from "../../utils/fetch-utls";
import { getAPI, restClient } from "./fetch-thunk";

export const REFUNDS_API = `${ENV.SERVER_URL}/api/refunds`;
export const REFUNDS_BATCH_SIZE = 10;

export const fetchRefunds = ({
  searchText,
  userId,
  refundIds,
  offset,
  isLoading = true
} = {}) => {
  return async (dispatch, getState) => {
    try {
      const id = getState().auth?.user?.id;
      console.log("fetchRefunds thunk ", searchText, userId);
      dispatch({
        type: actionTypes.TOGGLE_REFUNDS_LOADING,
        payload: true
      });
      const text = searchText ? searchText.trim() : searchText;
      const url = `${REFUNDS_API}/${id}`;

      const urlWithParams = createUrl(url, {
        searchText: text,
        userId,
        limit: REFUNDS_BATCH_SIZE,
        offset: offset !== undefined ? offset : 0
      });
      const result = await dispatch(getAPI(urlWithParams));

      dispatch({
        type: actionTypes.FETCH_REFUNDS,
        payload: { refunds: result, offset }
      });
    } catch (e) {
      console.error("failed to fetchRefunds ", e);
      dispatch(showError(e.message));
    } finally {
      dispatch({
        type: actionTypes.TOGGLE_REFUNDS_LOADING,
        payload: false
      });
    }
  };
};

export const createRefund = (data) => {
  return async (dispatch) => {
    try {
      dispatch(coreActions.toggleLoader({ loading: true }));

      const result = await dispatch(
        restClient.postAPI({ url: REFUNDS_API, body: data })
      );

      dispatch({
        type: actionTypes.ADD_REFUND,
        payload: { item: result }
      });

      return result;
    } catch (e) {
      console.error("failed to create refund ", e);
      throw e;
    } finally {
      dispatch(coreActions.toggleLoader({ loading: false }));
    }
  };
};

export const fetchRefundById = ({ id } = {}) => {
  return async (dispatch, getState) => {
    try {
      if (!id) {
        console.error("refund id is missing");
        return;
      }
      console.log("fetching refund by id ", id);
      dispatch({
        type: actionTypes.TOGGLE_FETCH_REFUND_BY_ID_LOADING,
        payload: true
      });
      const url = `${REFUNDS_API}/refund/${id}`;
      const result = await dispatch(restClient.getAPI(url));

      dispatch({
        type: actionTypes.FETCH_REFUND_BY_ID,
        payload: { item: result }
      });
    } catch (e) {
      console.error("failed to fetch refund by id ".id, e);
      dispatch(showError(e.message));
    } finally {
      dispatch({
        type: actionTypes.TOGGLE_FETCH_REFUND_BY_ID_LOADING,
        payload: false
      });
    }
  };
};
//
// export const fetchRefundsByIds = ({ ids } = {}) => {
//   return async (dispatch, getState) => {
//     try {
//       if (ids?.length === 0) {
//         console.error("refund ids is missing");
//         return;
//       }
//       console.log("fetching refunds by ids ", ids);
//       dispatch({
//         type: actionTypes.TOGGLE_FETCH_REFUND_BY_ID_LOADING,
//         payload: true
//       });
//       const url = `${REFUNDS_API}/refunds-by-ids`;
//       const result = await dispatch(restClient.postAPI({ url, body: { ids } }));
//
//       dispatch({
//         type: actionTypes.FETCH_REFUNDS_BY_IDS,
//         payload: result
//       });
//     } catch (e) {
//       console.error("failed to fetch refund by id ".id, e);
//       dispatch(showError(e.message));
//     } finally {
//       dispatch({
//         type: actionTypes.TOGGLE_FETCH_REFUND_BY_ID_LOADING,
//         payload: false
//       });
//     }
//   };
// };

export const fetchUserRefunds = () => {
  return async (dispatch, getState) => {
    try {
      const userId = getState().auth?.user?.id;
      const userRefundsLoaded = getState().refunds?.userRefundsLoaded;

      dispatch({
        type: actionTypes.TOGGLE_USER_REFUNDS_LOADING,
        payload: true
      });

      if (!userId) {
        console.log("userId not passed ", userId);
        return;
      }
      if (userRefundsLoaded) {
        console.log("user refunds already loaded ", userId);
        return;
      }
      console.log("fetching user created refunds userId ", userId);

      const url = `${REFUNDS_API}/${userId}`;
      const fullUrl = `${url}/?userId=${userId}`;

      const result = await dispatch(restClient.getAPI(fullUrl));

      dispatch({
        type: actionTypes.FETCH_USER_REFUNDS,
        payload: { refunds: result }
      });
    } catch (e) {
      console.error("failed to fetchUserRefunds ", e);
      dispatch(showError(e.message));
    } finally {
      dispatch({
        type: actionTypes.TOGGLE_USER_REFUNDS_LOADING,
        payload: false
      });
    }
  };
};

export const fetchUserRequestedRefunds = ({ ids }) => {
  return async (dispatch, getState) => {
    try {
      const userId = getState().auth?.user?.id;
      dispatch(coreActions.toggleLoader({ loading: true }));

      if (!userId) {
        console.log("userId not passed ", userId);
        return;
      }
      if (ids.length === 0) {
        console.log("ids are empty");
        return;
      }
      console.log("fetching user requested refunds userId ", userId, " ids ", ids);

      const url = `${REFUNDS_API}/${userId}`;
      const fullUrl = `${url}/?ids=${ids.join(",")}`;
      const result = await dispatch(restClient.getAPI(fullUrl));

      dispatch({
        type: actionTypes.FETCH_USER_REQUESTED_REFUNDS,
        payload: result
      });
    } catch (e) {
      console.error("failed to fetchUserRequestedRefunds ", e);
      dispatch(showError(e.message));
    } finally {
      dispatch(coreActions.toggleLoader({ loading: false }));
    }
  };
};

export const updateRefundStatus = ({ id, status }) => {
  return async (dispatch) => {
    try {
      dispatch(coreActions.toggleLoader({ loading: true }));

      const data = { status };
      const url = `${REFUNDS_API}/status/${id}`;

      const result = await dispatch(restClient.putAPI({ url, body: data }));

      dispatch({
        type: actionTypes.UPDATE_REFUND_STATUS,
        payload: { id, status }
      });
    } catch (e) {
      console.error("failed to update refund ", e);
      dispatch(showError(e.message));
    } finally {
      dispatch(coreActions.toggleLoader({ loading: false }));
    }
  };
};

export const deleteRefund = ({ id }) => {
  return async (dispatch) => {
    try {
      dispatch(coreActions.toggleLoader({ loading: true }));

      const url = `${REFUNDS_API}/${id}`;

      const result = await dispatch(restClient.deleteAPI({ url }));

      dispatch({
        type: actionTypes.DELETE_REFUND,
        payload: { id }
      });

      return result;
    } catch (e) {
      console.error("failed to update refund ", e);
      dispatch(showError(e.message));
    } finally {
      dispatch(coreActions.toggleLoader({ loading: false }));
    }
  };
};

export const updateRefund = (data) => {
  return async (dispatch) => {
    try {
      dispatch(coreActions.toggleLoader({ loading: true }));

      console.log("updateRefund data ", data);

      const result = await dispatch(
        restClient.putAPI({ url: REFUNDS_API, body: data })
      );

      console.log("updateRefund result ", result);

      dispatch({
        type: actionTypes.UPDATE_REFUND,
        payload: { id: data.id, item: result }
      });

      return result;
    } catch (e) {
      console.error("failed to update refund ", e);
      throw e;
    } finally {
      dispatch(coreActions.toggleLoader({ loading: false }));
    }
  };
};
