import React from "react";
import { useTranslation } from "react-i18next";
import { ItemNotAvailable } from "../item-not-available";
import { Modal } from "../basic/modals/modal";

export const ItemNotAvailableModal = ({ onClose, open }) => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={onClose} title={t("This item no longer available")}>
      <ItemNotAvailable />
    </Modal>
  );
};
