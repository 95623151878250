import { actionTypes } from "./action-types";

import { SERVER_URL } from "../../config/config";

export const FAVORITES_API = `${SERVER_URL}/api/favorites`;

export const toggleFavorite = ({ item, isAdd }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TOGGLE_FOVORITE,
      payload: { item, isAdd }
    });
  };
};
