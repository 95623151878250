import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { useTheme } from "styled-components/native";
import { getHeaderStyle } from "./nav-style";
import { useTranslation } from "react-i18next";
import UserRequestedRefunds from "../screens/activity/buying/user-requested-refunds";
import RefundDetailsStackNavigator from "./refund-details-stack-navigator";

const Stack = createStackNavigator();

export default function MyRefundRequestsStackNavigator() {
  const theme = useTheme();
  const headerStyle = getHeaderStyle(theme);
  const { t } = useTranslation();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="UserRequestedRefunds"
        component={UserRequestedRefunds}
        options={{ title: t("User Requests"), ...headerStyle }}
      />
      <Stack.Screen
        name="RefundDetails"
        component={RefundDetailsStackNavigator}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
}
