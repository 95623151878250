import { actionTypes } from "../actions/action-types";
import { SERVER_URL } from "../../config/config";
import { DEFAULT_SETTINGS } from "../../utils/settings-utils";
import { showError } from "../actions/core-actions";
import { getAPI, postAPI, putAPI } from "./fetch-thunk";

export const SETTINGS_API = `${SERVER_URL}/api/settings`;

export const fetchSettings = ({ userId }) => {
  return async (dispatch, getState) => {
    try {
      console.log("fetchSettings userId ", userId);
      const url = `${SETTINGS_API}/${userId}`;
      const result = await dispatch(getAPI(url));

      console.log("fetchSettings responseData ", result);

      if (result) {
        dispatch({
          type: actionTypes.FETCH_SETTINGS,
          payload: result
        });
      } else {
        dispatch(createNewUserSettings({ userId }));
      }
    } catch (e) {
      console.error("failed to fetch settings, userId ", userId, e);
      dispatch(showError("failed to fetch settings " + e.message));
    }
  };
};

export const createNewUserSettings = ({ userId }) => {
  return async (dispatch, getState) => {
    const language = getState().settings.language;
    const currency = getState().settings.currency;
    try {
      dispatch(createSettings({ userId, ...DEFAULT_SETTINGS, language, currency }));
    } catch (e) {
      console.error("failed to create new user settings, userId ", userId, e);
      dispatch(showError("failed to create new user settings " + e.message));
    }
  };
};

export const createSettings = ({ userId, language, theme, currency, location }) => {
  return async (dispatch) => {
    try {
      const result = await dispatch(
        postAPI({
          url: SETTINGS_API,
          body: {
            userId,
            language,
            theme,
            currency,
            location
          }
        })
      );

      if (result && Object.keys(result).length > 0) {
        dispatch({
          type: actionTypes.UPDATE_SETTINGS,
          payload: { settings: result }
        });
      }
    } catch (e) {
      console.error(
        "failed to create settings, ",
        userId,
        language,
        theme,
        currency,
        e
      );
      dispatch(showError("failed to create settings " + e.message));
    }
  };
};

export const updateSettings = ({ id, language, theme, currency, location }) => {
  return async (dispatch) => {
    try {
      console.log("updateSettings ", id, language, theme, currency, location);

      const url = `${SETTINGS_API}/${id}`;
      const result = await dispatch(
        putAPI({
          url,
          body: {
            language,
            theme,
            currency,
            location
          }
        })
      );

      console.log("updated settings, ", result);

      if (Object.keys(result).length > 0) {
        // dispatch({
        //   type: actionTypes.UPDATE_SETTINGS,
        //   payload: { settings: responseData.result }
        // });
      }
      dispatch({
        type: actionTypes.SET_IS_DIRTY,
        payload: false
      });
    } catch (e) {
      console.error("failed to update settings, ", id, language, theme, currency, e);
      dispatch(showError("failed to update settings " + e.message));
    }
  };
};
