import React, { useEffect, useState } from "react";
import styled from "styled-components/native";
import BaseText from "../../components/basic/base-text";
import BackButton from "../../components/basic/buttons/back-button";
import { useNavigation } from "@react-navigation/native";
import Header from "../../components/header";
import { useSelector, useDispatch } from "react-redux";
import { changeMessagesTab } from "../../redux/actions/message-actions";
import { MessagesTab } from "../../redux/reducers/messages-reducer";
import { getChatRefundName, getChatUserTitle } from "../../utils/chat-utils";
import LinkButton from "../../components/basic/buttons/link-button";
import { marginTop } from "../../components/styles/screen-layout";
import { isWeb } from "../../utils/core-utils";

const StyledTitle = styled(BaseText)`
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: auto;
  margin-left: 8px;
  position: relative;
`;

const StyledContainer = styled.View`
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: 0;
`;

const StyledLinkButton = styled(LinkButton)``;

const StyledHeader = styled(Header)`
  margin-top: ${marginTop}px;
`;

const StyledText = styled(BaseText)``;

function ChatHeader({
  user = {},
  handleBack,
  refund,
  backScreen,
  tab,
  backParams = {}
}) {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [refundTitle, setRefundTitle] = useState("");

  useEffect(() => {
    if (user) {
      const title = getChatUserTitle(user);
      setUserName(title);
    }
  }, [user]);

  useEffect(() => {
    if (refund) {
      const title = getChatRefundName(refund.title);
      setRefundTitle(title);
    }
  }, [refund]);

  const onBack = () => {
    console.log("backScreen ", backScreen, " backParams ", backParams);
    if (isWeb()) {
      navigation.canGoBack()
        ? navigation.goBack(null)
        : navigation.navigate("Messages");
    } else {
      if (backScreen) {
        navigation.navigate(backScreen, backParams);
      } else {
        navigation.goBack(null);
      }
    }

    // if (handleBack) {
    //   handleBack();
    // }
  };

  const viewRefund = () => {
    navigation.navigate("RefundDetails", {
      id: refund?.id
    });
  };

  return (
    <StyledHeader isSeparator>
      <StyledContainer>
        <StyledBackButton onPress={onBack} style={{ marginRight: 0 }} />
        <StyledTitle>
          {refundTitle ? (
            <StyledLinkButton
              text={userName ? `${refundTitle} - ${userName}` : refundTitle}
              onPress={viewRefund}
              fontSize={14}
            />
          ) : (
            <StyledText>{userName}</StyledText>
          )}
        </StyledTitle>
      </StyledContainer>
    </StyledHeader>
  );
}

export default ChatHeader;
