import React, { useMemo } from "react";
import { useTheme } from "styled-components/native";
import { View, Picker, StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import { HE_LANG } from "../../../../i18n";

const pickerSelectStyles = (theme) =>
  StyleSheet.create({
    inputIOS: {
      fontSize: 14,
      paddingTop: 8,
      paddingHorizontal: 10,
      paddingBottom: 8,
      borderWidth: 1,
      borderColor: theme.palette.input.border,
      borderRadius: 4,
      backgroundColor: theme.palette.input.background,
      color: theme.palette.input.font,
      paddingRight: 10,
      textAlign: theme.language === HE_LANG ? "right" : "left"
    },
    inputWeb: {
      fontSize: 14,
      paddingTop: 8,
      paddingHorizontal: 10,
      paddingBottom: 8,
      borderWidth: 1,
      borderColor: theme.palette.input.border,
      borderRadius: 4,
      backgroundColor: theme.palette.input.background,
      color: theme.palette.input.font,
      paddingRight: 10,
      textAlign: theme.language === HE_LANG ? "right" : "left"
    },
    inputAndroid: {
      fontSize: 14,
      paddingHorizontal: 10,
      paddingVertical: 8,
      borderWidth: 0.5,
      borderColor: theme.palette.input.border,
      borderRadius: 8,
      width: "auto",
      backgroundColor: theme.palette.input.background,
      color: theme.palette.input.font,
      paddingRight: 10,
      textAlign: theme.language === HE_LANG ? "right" : "left"
    },
    modalViewBottom: {
      backgroundColor: theme.palette.input.background,
      borderColor: "transparent",
      padding: 0,
      margin: 0,
      color: theme.palette.input.font
    },
    modalViewMiddle: {
      backgroundColor: theme.palette.input.background,
      borderColor: "transparent",
      padding: 0,
      margin: 0,
      color: theme.palette.input.font
    },
    viewContainer: {
      color: theme.palette.input.font,
      backgroundColor: theme.palette.input.background
    },
    done: {
      color: theme.palette.link.color
    }
  });

export const RnPickerSelect = ({
  items,
  onValueChange,
  value,
  textInputProps,
  maxWidth,
  placeholder,
  ...rest
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const displayItems = useMemo(() => {
    const result = items.map((item) => ({
      ...item,
      color: theme.palette.input.font
    }));
    return result;
  }, [items]);

  const displayPlaceholder = placeholder
    ? {
        label: placeholder,
        value: null,
        color: theme.palette.input.font
      }
    : {};

  const styles = pickerSelectStyles(theme);
  // if (isWeb()) {
  //   styles.inputWeb["appearance"] = "none";
  // }
  return (
    <Picker
      iosHeader="Select one"
      mode="dropdown"
      textStyle={{ fontSize: 12, color: "yellow" }}
      selectedValue={value || ""}
      onValueChange={onValueChange}
    >
      {displayItems.map((item) => (
        <Picker.Item {...item} />
      ))}
    </Picker>
  );
};
