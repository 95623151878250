import { SERVER_URL } from "../config/config";

export const USER_REQUESTS_API = `${SERVER_URL}/api/user-requests`;

export const NOTIFICATIONS_API = `${SERVER_URL}/api/notifications`;

export const CHATS_API = `${SERVER_URL}/api/chats`;

export const CUSTOMER_SERVICE_API = `${SERVER_URL}/api/customer-service`;

export const CONTACT_US_API = `${SERVER_URL}/api/contact-us`;

export const CONTACT_US_NEW_USER_API = `${SERVER_URL}/api/contact-us/new-user`;

export const ACCOUNT_API = `${SERVER_URL}/api/account`;

export const SEND_FORGOT_PASSWORD_CODE = `${SERVER_URL}/api/emails/send-forgot-password-code`;
