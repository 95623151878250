import { useRef, useEffect } from "react";

export function useIsMounted(prefix) {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    console.log("mounted " + prefix);

    return () => {
      console.log("unmounted " + prefix);
      isMounted.current = false;
    };
  }, []);

  return isMounted?.current;
}
