import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./root-reducer";
import actionTypes from "./actions/action-types";
//
// const appReducer = (state, action) =>
//   rootReducer(
//     action.type === actionTypes.LOGOUT
//       ? {
//           ...rootReducer({}, {}),
//           router: (state && state.router) || {}
//         }
//       : state,
//     action
//   );

const middleware = composeWithDevTools(applyMiddleware(thunk));

const store = createStore(rootReducer, middleware);

export const initialRootState = {
  ...store.getState()
};

export default store;
