import React from "react";
import { useSelector } from "react-redux";
import { useTheme } from "styled-components/native";
import { useNavigation } from "@react-navigation/native";
import { FAB } from "react-native-elements";
import { Plus } from "../../../components/basic/icons/plus";
import { isWeb } from "../../../utils/core-utils";

export const AddButton = () => {
  const user = useSelector((state) => state.auth?.user);
  const theme = useTheme();
  const navigation = useNavigation();

  const handleAddButton = () => {
    if (user?.id) {
      navigation.navigate("AddEditRefund");
    } else {
      navigation.navigate("Login");
    }
  };

  return (
    <FAB
      icon={<Plus color={theme.palette.button.primary.font} />}
      color={theme.palette.button.primary.color}
      onPress={handleAddButton}
      iconPosition={"right"}
      placement={"right"}
      style={{ bottom: isWeb() ? 0 : 16 }}
    />
  );
};
