import React from "react";
import styled from "styled-components/native";
import BaseText from "../../components/basic/base-text";
import Error from "../../components/error";
import { Checkbox } from "native-base";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../components/basic/date-picker/custom-date-picker";
import { flexDirection } from "../../components/styles/global-styles";
import { TICKET } from "../../utils/refund-types-utils";

const StyledGroup = styled.View`
  width: 100%;
  display: flex;
`;

const StyledError = styled(Error)`
  margin-top: 4px;
`;

const StyledDateGroup = styled(StyledGroup)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${flexDirection}
`;

const StyledDateLabel = styled(BaseText)`
  margin: 0 8px;
`;

const StyledHasExpiration = styled.View`
  display: flex;
  flex-direction: row;
`;

export default function ExpirationDate({ props }) {
  const { t } = useTranslation();
  const selectedDate = props.values["expirationDate"];
  const type = props.values["type"];

  return (
    <StyledGroup>
      <StyledDateGroup>
        <StyledHasExpiration>
          <Checkbox
            name={"hasExpirationDate"}
            value={props.values["hasExpirationDate"]}
            isChecked={props.values["hasExpirationDate"]}
            size="sm"
            colorScheme={"blue"}
            onChange={(nextValue) => {
              if (type !== TICKET) {
                props.setFieldValue("hasExpirationDate", nextValue);
                if (nextValue === false) {
                  props.setFieldValue("expirationDate", undefined);
                }
              }
            }}
          />
          <StyledDateLabel>{t("Expiration date")}</StyledDateLabel>
        </StyledHasExpiration>
        {props.values["hasExpirationDate"] && (
          <CustomDatePicker
            value={selectedDate}
            onChange={(nextValue) => {
              props.setFieldValue("expirationDate", nextValue);
            }}
          />
        )}
      </StyledDateGroup>

      {props.values["hasExpirationDate"] &&
        props.touched["expirationDate"] &&
        props.errors["expirationDate"] && (
          <StyledError>
            {props.touched["expirationDate"] && props.errors["expirationDate"]}
          </StyledError>
        )}
    </StyledGroup>
  );
}
