import React from "react";
import styled, { useTheme } from "styled-components/native";
import AppLogo from "./app-logo";
import BaseText from "../base-text";

const StyledContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledText = styled(BaseText)`
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.size}px`};
`;

export default function AppName({ color, size = 35, ...rest }) {
  const theme = useTheme();
  const nameColor = color || theme.palette.button.primary.color;
  const textSize = size - size / 3;
  return (
    <StyledContainer>
      <AppLogo color={nameColor} size={size} />
      <StyledText color={nameColor} size={textSize}>
        efunderr
      </StyledText>
    </StyledContainer>
  );
}
