import "@expo/match-media";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../components/styles/breakpoints";

export function useBreakpoints() {
  const isAboveSm =
    useMediaQuery({
      query: `(min-width: ${breakpoints.sm}px)`
    }) || false;
  const isAboveMd =
    useMediaQuery({
      query: `(min-width: ${breakpoints.md}px)`
    }) || false;
  const isAboveLg =
    useMediaQuery({
      query: `(min-width: ${breakpoints.lg}px)`
    }) || false;
  const isAboveXl =
    useMediaQuery({
      query: `(min-width: ${breakpoints.xl}px)`
    }) || false;

  return {
    isAboveSm,
    isAboveMd,
    isAboveLg,
    isAboveXl
  };
}
