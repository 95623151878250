import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import SearchScreen from "../screens/search-screen/search-screen";
import { useTheme } from "styled-components/native";
import { getHeaderStyle } from "./nav-style";

const Stack = createStackNavigator();

export default function SearchStackNavigator() {
  const theme = useTheme();
  const headerStyle = getHeaderStyle(theme);
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="Search"
        component={SearchScreen}
        options={{ headerShown: false, ...headerStyle }}
      />
    </Stack.Navigator>
  );
}
