import React from "react";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
import { ReactS3Client } from "../../services/upload-image-service";
import { options } from "../../services/aws-config";
import { isWeb } from "../../utils/core-utils";
import { RNS3 } from "react-native-aws3";

export const fetchImageFromUri = async (uri) => {
  const response = await fetch(uri);
  const blob = await response.blob();
  return blob;
};

export const handleUploadMobile = async ({ image, setLoading, user }) => {
  try {
    if (setLoading) {
      setLoading(true);
    }
    console.log("image ", image);
    const uuidFile = uuidv4();
    const uri = image.uri;
    // var file = new File([blob], "filename");
    var suffix = uri.substring(uri.lastIndexOf(".") + 1);

    const fileName = `${user.id}_${uuidFile}.${suffix}`;
    var type = uri.substring(uri.lastIndexOf(":") + 1, uri.lastIndexOf(";"));
    // const blobToFile = new File([file], "name", { type });
    // const blob = await fetchImageFromUri(uri);
    // var file = new File([blob], fileName);
    const data = { name: fileName, uri: uri, type: image.type };
    console.log("image data ", data);

    const result = await RNS3.put(data, options);
    console.log("image result ", result);

    const path = result?.body?.postResponse?.location;
    return path;
    //   .then((result) => {
    //     console.log("uploadFile result ", result);
    //     const path = result?.body?.postResponse?.location;
    //     if (path) {
    //       setLoading(false);
    //       return data.location;
    //     }
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     setUrl(null);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  } catch (e) {
    console.error("failed to handleUploadMobile ", e);
    throw e;
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
};

export const handleUploadWeb = async ({ image, setLoading, user }) => {
  try {
    if (setLoading) {
      setLoading(true);
    }
    console.log("image ", image);
    const uuidFile = uuidv4();
    const uri = image.uri;
    // var file = new File([blob], "filename");
    const fileName = `${user.id}_${uuidFile}`;
    var type = uri.substring(uri.lastIndexOf(":") + 1, uri.lastIndexOf(";"));
    // const blobToFile = new File([file], "name", { type });
    const blob = await fetchImageFromUri(uri);

    const data = await ReactS3Client.uploadFile(blob, fileName);
    return data.location;
  } catch (e) {
    console.error("handleUploadWeb error ", e);
    throw e;
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
};

export const handleUpload = async ({ image, setLoading, user }) => {
  if (image) {
    if (isWeb()) {
      return await handleUploadWeb({ image, setLoading, user });
    } else {
      return await handleUploadMobile({ image, setLoading, user });
    }
  }
};
