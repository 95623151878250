import React from "react";
import styled, { useTheme } from "styled-components/native";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../../components/basic/dropdown";

export const SINGLE_ITEM = "single-item";
export const IN_STOCK = "in-stock";

const displayAvailability = [
  {
    label: "Single Item",
    value: SINGLE_ITEM
  },
  {
    label: "In Stock",
    value: IN_STOCK
  }
];

export default function SelectAvailability({
  onChange,
  value = "",
  name,
  type,
  ...rest
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Dropdown
      items={displayAvailability}
      onValueChange={onChange}
      value={value}
      textInputProps={{ name }}
      {...rest}
    />
  );
}
