import React, { useEffect, useMemo } from "react";
import styled, { useTheme } from "styled-components/native";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../../components/basic/dropdown";
import { NONE, REFUND_DISPLAY_TYPES } from "../../utils/refund-types-utils";

export default function SelectType({
  onChange,
  value = NONE,
  name,
  variant,
  ...rest
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const items = useMemo(() => {
    const res = REFUND_DISPLAY_TYPES.map((item) => ({
      ...item,
      label: t(item.label)
    }));
    res.unshift({ value: NONE, label: t("Select Card Type") });
    return res;
  }, [t]);

  return (
    <Dropdown
      items={items}
      onValueChange={onChange}
      value={value || ""}
      textInputProps={{ name }}
      // placeholder={t("Select Card Type")}
      {...rest}
    />
  );
}
