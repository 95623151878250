import { ENV } from "../config/config";

export const config = {
  bucketName: ENV.S3_BUCKET,
  region: ENV.REGION,
  accessKeyId: ENV.ACCESS_KEY,
  secretAccessKey: ENV.SECRET_ACCESS_KEY,
  //   dirName: FOLDER_NAME,
  s3Url: ENV.S3_URL
};

export const options = {
  //   keyPrefix: `${FOLDER_NAME}/`,
  bucket: ENV.S3_BUCKET,
  region: ENV.REGION,
  accessKey: ENV.ACCESS_KEY,
  secretKey: ENV.SECRET_ACCESS_KEY,
  successActionStatus: 201
};
