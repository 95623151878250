import React, { useMemo } from "react";
import { Dimensions, View } from "react-native";
import MapView, { Circle } from "react-native-maps";
import useTheme from "../../../components/styles/use-theme";

export default function MobileMap({ location, height = 300, style = {}, ...rest }) {
  const width = Dimensions.get("window").width;
  const theme = useTheme();

  const region = useMemo(() => {
    return {
      latitude: location.lat,
      longitude: location.lng,
      latitudeDelta: 0.015 * 5,
      longitudeDelta: 0.0121 * 5
    };
  }, [location]);

  console.log("MobileMap ", region);

  return (
    <View
      {...rest}
      style={{
        flex: 1,
        backgroundColor: "#fff",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
        width: "100%",
        ...style
      }}
    >
      <MapView style={{ width, height }} region={region}>
        <>
          {/*<Marker coordinate={center} pinColor="red" />*/}
          <Circle
            center={region}
            radius={1000}
            strokeColor={theme.palette.icons.active}
            strokeWidth={4}
          />
        </>
      </MapView>
    </View>
  );
}
