import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { useTheme } from "styled-components/native";
import { getHeaderStyle } from "./nav-style";
import RequestScreen from "../screens/messages/request-screen/request-screen";
import ChatScreen from "../screens/chat/chat-screen";
import { useTranslation } from "react-i18next";
import RefundDetailsStackNavigator from "./refund-details-stack-navigator";

const Stack = createStackNavigator();

export default function UserRequestStackNavigator({ route: { params } }) {
  const theme = useTheme();
  const headerStyle = getHeaderStyle(theme);
  const { t } = useTranslation();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="RequestScreen"
        component={RequestScreen}
        options={{ headerShown: false }}
        initialParams={params}
      />
      <Stack.Screen
        name="ChatScreen"
        component={ChatScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="RefundDetails"
        component={RefundDetailsStackNavigator}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
}
