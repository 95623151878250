import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEng from "./translations/en.json";
import translationHe from "./translations/he.json";
import translationFr from "./translations/fr.json";
import translationRu from "./translations/ru.json";

export const DEFAULT_LANG = "en";

export const HE_LANG = "he";
export const RU_LANG = "ru";
export const FR_LANG = "fr";

export const isRightToLeft = (lng) => lng === HE_LANG;

export const LANGUAGES = [DEFAULT_LANG, HE_LANG, RU_LANG, FR_LANG];

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: translationEng
  },
  he: {
    translation: translationHe
  },
  ru: {
    translation: translationRu
  },
  fr: {
    translation: translationFr
  }
};

// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     resources,
//     lng: DEFAULT_LANG, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
//     // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
//     // if you're using a language detector, do not define the lng option

//     interpolation: {
//       escapeValue: false // react already safes from xss
//     }
//   });

export const initLanguage = (lang = DEFAULT_LANG) => {
  console.log("initLanguage");
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: lang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option

      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
};

// export default i18n;
