import { actionTypes } from "./action-types";
import { CHATS_API } from "../../constants/apis-urls";

export const setThreadId = ({ userId, threadId }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_THREAD_ID,
      payload: { userId, threadId }
    });
  };
};
