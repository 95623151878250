import React from "react";
import { View } from "react-native";
import styled, { useTheme } from "styled-components/native";
import { useTranslation } from "react-i18next";
import LinkButton from "../../../components/basic/buttons/link-button";
import { HE_LANG } from "../../../../i18n";
import BaseText from "../../../components/basic/base-text";
import { spaceBetweenFlexRow } from "../../../components/styles/global-styles";
import { useSelector, useDispatch } from "react-redux";
import { AntDesign } from "@expo/vector-icons";
import Separator from "../../../components/basic/separator";

const StyledView = styled.ScrollView`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 16px;
  background-color: ${(props) => props.theme.palette.background.primary};
  padding-top: 48px;
`;

const StyledTitle = styled(BaseText)`
  font-size: 16px;
  font-weight: 700;
`;

const StyledRow = styled.View`
  margin-bottom: 12px;
  width: 100%;
  color: ${(props) => props.theme.palette.font.primary};

  ${spaceBetweenFlexRow}
`;

const StyledHeader = styled.View`
  width: 100%;
  margin-bottom: 32px;
  margin-top: 32px;
  ${spaceBetweenFlexRow}
`;

export default function AdminScreen(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.user);
  const theme = useTheme();
  const { lastName, firstName, role, createdAt } = user;

  if (role !== "admin") {
    return null;
  }

  return (
    <StyledView
      contentContainerStyle={{
        alignItems: theme.language === HE_LANG ? "flex-end" : "flex-start"
      }}
    >
      <StyledHeader>
        <StyledTitle>{t("Admin")}</StyledTitle>
      </StyledHeader>
      <Separator marginBottom={24} />

      <StyledRow>
        <LinkButton
          onPress={() => props.navigation.navigate("Home")}
          text={t("Customer Service")}
        />
        <LinkButton onPress={() => props.navigation.navigate("Home")}>
          <AntDesign
            name="customerservice"
            size={24}
            color={theme.palette.link.color}
          />
        </LinkButton>
      </StyledRow>

      <StyledRow>
        <LinkButton
          onPress={() => props.navigation.navigate("Home")}
          text={t("User requests")}
        />
        <LinkButton onPress={() => props.navigation.navigate("Home")}>
          <AntDesign
            name="customerservice"
            size={24}
            color={theme.palette.link.color}
          />
        </LinkButton>
      </StyledRow>

      <Separator marginTop={16} marginBottom={16} />

      <View style={{ height: 60 }} />
    </StyledView>
  );
}
