import React, { useState } from "react";
import { Share, View, Button } from "react-native";
import OutlineButton from "../basic/buttons/outline-button";
import { useTranslation } from "react-i18next";
import { isWeb } from "../../utils/core-utils";
import SocialShareButtons from "./social-share-buttons";
import { ENV } from "../../config/config";
import styled from "styled-components/native";
import { APP_NAME } from "../../constants/app-constants";

const APP_URL = ENV.APP_URL;

const StyledSocialShareButtons = styled(SocialShareButtons)`
  margin-top: 16px;
`;

const StyledContainer = styled.View`
  width: 100%;
`;

const ShareButton = ({
  title,
  onPress,
  id,
  message,
  images,
  buttonStyle,
  size,
  ...rest
}) => {
  const { t } = useTranslation();
  const open = isWeb();
  const [showWebShareOptions, setShowWebShareOptions] = useState(open);
  const massageTitle = APP_NAME;
  const shareUrl = `${APP_URL}/refund/${id}`;

  const onShare = async () => {
    try {
      const result = await Share.share({
        title: massageTitle,
        message: message,
        url: shareUrl
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          console.log("shared with activity type of result.activityType");
          // shared with activity type of result.activityType
        } else {
          // shared
          console.log("shared");
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
        console.log("dismissed");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const handleOnPress = () => {
    onShare();
    if (onPress) {
      onPress();
    }
  };

  const handleWebOnPress = () => {
    setShowWebShareOptions(!showWebShareOptions);
  };

  return (
    <StyledContainer {...rest}>
      <OutlineButton
        title={t(title || "Share")}
        onPress={isWeb() ? handleWebOnPress : handleOnPress}
        buttonStyle={buttonStyle}
      />
      {showWebShareOptions && (
        <StyledSocialShareButtons
          url={shareUrl}
          images={images}
          title={message}
          size={size}
        />
      )}
    </StyledContainer>
  );
};

export default ShareButton;
