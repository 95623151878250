import React from "react";
import styled from "styled-components/native";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TelegramShareButton,
  TelegramIcon
} from "react-share";
import { useTranslation } from "react-i18next";

const StyledShareList = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export default function SocialShareButtons(props) {
  const { t } = useTranslation();

  const {
    url,
    title = t("Buy/Sell your refunds and giftcards"),
    images,
    size = "2.5rem",
    hashtag = "giftcard",
    hashtags = ["giftcard", "refund", "coupon", "voucher", "coupon-high"],
    description = t("Refund yourself"),
    ...rest
  } = props;

  return (
    <StyledShareList {...rest}>
      <LinkedinShareButton
        title={title}
        summary={title}
        windowWidth={750}
        windowHeight={600}
        url={url}
      >
        <LinkedinIcon size={size} round={true} />
      </LinkedinShareButton>
      <FacebookShareButton
        quote={t("Buy/Sell your refunds and giftcards")}
        url={url}
        hashtag={hashtag}
        description={description}
      >
        <FacebookIcon size={size} round={true} />
      </FacebookShareButton>

      <TwitterShareButton title={title} url={url} hashtags={hashtags}>
        <TwitterIcon size={size} round={true} />
      </TwitterShareButton>

      <TelegramShareButton title={title} url={url}>
        <TelegramIcon size={size} round={true} />
      </TelegramShareButton>

      <WhatsappShareButton title={title} url={url}>
        <WhatsappIcon size={size} round={true} />
      </WhatsappShareButton>

      {/*
      <PinterestShareButton
        url={url}
        media={getShareImage(images)}
        windowWidth={1000}
        windowHeight={730}
        title={title}
      >
        <PinterestIcon size={size} round={true} />
      </PinterestShareButton> */}
    </StyledShareList>
  );
}
