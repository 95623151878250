import { actionTypes } from "../actions/action-types";

const initialState = {
  items: [],
  isLoading: true,
  isLoaded: false,
  unreadCount: null,
  currentItem: null
};

const notificationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_NOTIFICATIONS: {
      return {
        ...state,
        items: payload,
        isLoading: false,
        isLoaded: true
      };
    }
    case actionTypes.SET_NOTIFICATIONS_LOADING: {
      return {
        ...state,
        isLoading: payload
      };
    }
    case actionTypes.SET_NOTIFICATIONS_UNREAD_COUNT: {
      return {
        ...state,
        unreadCount: payload
      };
    }
    case actionTypes.SET_CURRENT_NOTIFICATION: {
      return {
        ...state,
        currentItem: payload
      };
    }
    case actionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default notificationsReducer;
