import React from "react";
import BaseText from "../../../components/basic/base-text";
import styled from "styled-components/native";
import Date from "../../../components/basic/date";
import Title from "../../../components/basic/title";
import { useTranslation } from "react-i18next";
import Separator from "../../../components/basic/separator";
import { TouchableOpacity, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { actionTypes } from "../../../redux/actions/action-types";
import { NOTIFICATION_TYPE } from "../../../redux/reducers/core-reducer";
import { useDispatch } from "react-redux";

const StyledContainer = styled.View``;

const StyledHeader = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const StyledTitle = styled(Title)`
  font-weight: 600;
  font-size: 14px;
  margin-right: auto;
`;

const StyledDate = styled(Date)`
  margin-left: auto;
  font-size: 12px;
`;

export default function NotificationCard({ item, isLast }) {
  const { t } = useTranslation();
  const { title, createdAt, message, type, data, id } = item;
  const { refundId } = data || {};
  const navigation = useNavigation();
  const dispatch = useDispatch();

  if (!data) {
    return null;
  }
  console.log("NotificationCard ", item);
  //
  // if (!data) {
  //   console.log("no data item ", item);
  // }

  const handleClickOnCard = () => {
    if (type === "user_request_created") {
      console.log("RequestScreen nav data ", data);
      dispatch({
        type: actionTypes.SET_CURRENT_NOTIFICATION,
        payload: item
      });
      navigation.navigate("RequestScreen", {
        id
      });
    } else {
      if (refundId) {
        navigation.navigate("RefundDetails", {
          id: refundId
        });
      }
    }
  };

  return (
    <TouchableOpacity onPress={handleClickOnCard}>
      <StyledContainer>
        <StyledHeader>
          <StyledDate isTime>{createdAt}</StyledDate>
          <StyledTitle oneLine>{t(title)}</StyledTitle>
        </StyledHeader>
        <BaseText>{message}</BaseText>
        {!isLast ? (
          <Separator marginTop={16} marginBottom={16} />
        ) : (
          <View style={{ marginBottom: 16, marginTop: 16 }} />
        )}
      </StyledContainer>
    </TouchableOpacity>
  );
}
