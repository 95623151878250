import React from "react";
import { TouchableOpacity } from "react-native";
import styled from "styled-components/native";
import Link from "../basic/link";
import { useTranslation } from "react-i18next";
import CardPriceTitle from "./refund-card-price-title";
import Title from "../basic/title";
import SubTitle from "../basic/sub-title";
import RequestSentIcon from "./request-sent-icon";
import RefundType from "./refund-type";
import ItemImage from "./item-image";
import useRefundStatus from "../../hooks/use-refund-status";
import {
  baseFlexRow,
  flexDirection,
  marginRightLeft
} from "../styles/global-styles";
import BaseRow from "../basic/rows/base-row";
import { useNavigation } from "@react-navigation/native";

const StyledView = styled.View`
  background-color: ${(props) => props.theme.palette.background.primary};
  color: ${(props) => props.theme.palette.font.primary};
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  border-radius: 4px;
  ${flexDirection}
`;

const StyledImage = styled(ItemImage)`
  height: 100%;
  min-height: 130px;
  width: 130px;
  height: 130px;
  border-radius: 7px;
  ${marginRightLeft(16)}
`;

const StyledTitle = styled(Title)`
  font-weight: 600;
  font-size: 14px;
  ${baseFlexRow}
`;

const StyledDescription = styled(SubTitle)`
  margin-bottom: 4px;
`;

const StyledContent = styled.View`
  margin-top: 0;
  width: 100%;
  flex-shrink: 1;
`;

const StyledFooter = styled.View`
  margin-top: auto;
`;

export default function Card({ item, isLoggedIn }) {
  const {
    id,
    title,
    description,
    price,
    refundAmount,
    priceCurrencyCode,
    refundAmountCurrencyCode,
    categoryId,
    expirationDate,
    userId,
    images = [],
    sku,
    location,
    storeName,
    storeWebsite,
    boostCampaign,
    createdAt,
    updatedAt,
    allowBids,
    type,
    status,
    otherType
  } = item;
  const { t } = useTranslation();
  const calculatedStatus = useRefundStatus({ status, expirationDate });
  const navigation = useNavigation();

  const onPress = () => {
    if (isLoggedIn) {
      navigation.navigate("RefundDetails", {
        id: item.id
      });
    } else {
      navigation.navigate("Login");
    }
  };

  return (
    <TouchableOpacity onPress={onPress}>
      <StyledView>
        <StyledImage images={images} status={calculatedStatus} />
        <StyledContent>
          <CardPriceTitle item={item} isFavorite />
          <BaseRow>
            {storeName ? <StyledTitle oneLine>{storeName}</StyledTitle> : null}
          </BaseRow>
          <BaseRow>
            <StyledTitle oneLine>{title}</StyledTitle>
          </BaseRow>

          {/* <StyledDescription oneLine>{description}</StyledDescription> */}
          <BaseRow>
            {storeWebsite && storeWebsite.startsWith("https://") ? (
              <Link url={storeWebsite} oneLine>
                {t("Visit Website")}
              </Link>
            ) : (
              storeWebsite || ""
            )}
          </BaseRow>
          <StyledFooter>
            <RefundType type={type} otherType={otherType} />
            <RequestSentIcon refundId={id} />
          </StyledFooter>
        </StyledContent>
      </StyledView>
    </TouchableOpacity>
  );
}
