import "intl";
import "intl/locale-data/jsonp/en";

export const DEFAULT_CURRENCY = "USD";

export const currencies = ["USD", "ILS", "RUB", "EUR", "AUD"];

export const currenciesItems = currencies.map((item) => ({
  label: item,
  value: item
}));

export const countryToCurrencies = {
  Israel: "ILS",
  USA: "USD",
  Russia: "RUB",
  France: "EUR",
  Australia: "AUD"
};

export const currencyToFormat = {
  ILS: "he",
  USD: "en",
  RUB: "ru",
  EUR: "fr",
  AUD: "au"
};

export const LANGUAGE_TO_CURRENCY = {
  he: "ILS",
  en: "USD",
  ru: "RUB",
  fr: "EUR",
  au: "AUD"
};

export const currencyFormat = (value, currency) =>
  new Intl.NumberFormat(currencyToFormat[currency], {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(Math.floor(value));
