import * as React from "react";
import { Text } from "react-native";
import styled, { useTheme } from "styled-components/native";
import IconButton from "./icon-button";
import { MaterialIcons } from "@expo/vector-icons";

const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
`;

function BackButton({ onPress, ...rest }) {
  const theme = useTheme();
  return (
    <StyledIconButton onPress={onPress} {...rest}>
      <MaterialIcons
        name="arrow-back-ios"
        size={24}
        color={theme.palette.link.color}
      />
    </StyledIconButton>
  );
}

export default BackButton;
