import * as React from "react";
import { View, Text, Platform } from "react-native";
import styled from "styled-components/native";
import { isWeb } from "../utils/core-utils";
import Separator from "./basic/separator";

const paddingTop = isWeb() ? 0 : 16;

const StyledWrapper = styled.View`
  display: flex;
  background-color: ${(props) => props.theme.palette.background.primary};
  width: 100%;
  height: auto;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: ${`${paddingTop}px`};
`;

const StyledContent = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

function Header({ children, isSeparator, className, ...rest }) {
  return (
    <StyledWrapper className={className} {...rest}>
      <StyledContent>{children}</StyledContent>
      {isSeparator && <Separator marginTop={0} marginBottom={0} />}
    </StyledWrapper>
  );
}

export default Header;
