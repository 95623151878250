import React, { useCallback, useState, useMemo, useRef, useEffect } from "react";
import { FlatList, RefreshControl, Text, StyleSheet } from "react-native";
import styled from "styled-components/native";
import Card from "../../components/refund/refund-card";
import { fetchRefunds } from "../../redux/thunks/refunds-thunk";
import { useDispatch, useSelector } from "react-redux";
import { refundsScrollToTopToggle } from "../../redux/actions/refunds-actions";
import Loader from "../../components/loaders/loader";
import { useIsMounted } from "../../hooks/use-is-mounted";
import { useIsActive } from "../../hooks/use-is-active";
import useUser from "../../hooks/use-user";
import NoItemsFound from "../../components/no-items-found";
import { layoutWeb } from "../../components/styles/screen-layout";
import { DESKTOP_MAX_WIDTH } from "../../components/styles/constants";

const StyledCardsContainer = styled.View`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 16px;
`;

export default function RefundsCardsList({ navigation, ListEmptyComponent }) {
  const items = useSelector((state) => state.refunds.items);
  const [refreshing, setRefreshing] = useState(false);
  const dispatch = useDispatch();
  const flatListRef = useRef();
  const isScrollTop = useSelector((state) => state.refunds.isScrollTop);
  const isLoading = useSelector((state) => state.refunds.isLoading);
  const hasMore = useSelector((state) => state.refunds.hasMore);
  const isLoaded = useSelector((state) => state.refunds.isLoaded);

  const isActive = useIsActive();
  console.log("CardsList isActive ", isActive);
  // const onEndReachedCalledDuringMomentum = useRef(true);
  const [isLoggedIn] = useUser();
  useIsMounted("CardsList");

  const onScrollToTop = () => {
    flatListRef?.current?.scrollToOffset({ animated: true, offset: 0 });
  };

  useEffect(() => {
    if (!isLoaded) {
      console.log("CardsList init fetchRefunds");
      dispatch(fetchRefunds());
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isScrollTop) {
      onScrollToTop();
      dispatch(refundsScrollToTopToggle(false));
    }
  }, [dispatch, isScrollTop]);

  const onRefresh = useCallback(async () => {
    if (!refreshing && !isLoading && isActive) {
      try {
        setRefreshing(true);
        console.log("CardsList fetchRefunds");
        dispatch(fetchRefunds())
          .then(() => {
            setRefreshing(false);
          })
          .catch(() => setRefreshing(false));
      } catch (error) {
        console.error(error);
      }
    }
  }, [refreshing, isLoading, isActive]);

  const onEndReached = useCallback(
    ({ distanceFromEnd }) => {
      console.log(
        "CardsList onEndReached refreshing ",
        refreshing,
        " isLoading ",
        isLoading,
        " hasMore ",
        hasMore,
        " distanceFromEnd ",
        distanceFromEnd
      );
      if (distanceFromEnd < 0) {
        return;
      }

      if (
        !refreshing &&
        !isLoading &&
        hasMore &&
        isActive //&&
        //!onEndReachedCalledDuringMomentum.current
      ) {
        console.log("CardsList onEndReached fetchRefunds");
        setRefreshing(true);
        dispatch(fetchRefunds({ offset: items.length, isLoading: false }))
          .then(() => {
            setRefreshing(false);
          })
          .catch(() => setRefreshing(false))
          .finally(() => {
            //onEndReachedCalledDuringMomentum.current = true;
          });
      }
    },
    [refreshing, isLoading, hasMore, items, isActive]
  );

  const renderItem = ({ item }) => (
    <Card item={item} navigation={navigation} isLoggedIn={isLoggedIn} />
  );

  const displayItems = useMemo(() => {
    const res = items.filter(
      (item) =>
        item !== undefined &&
        item !== null &&
        item.id &&
        item.title &&
        item.price &&
        item.refundAmount
    );
    return res;
  }, [items]);
  console.log("CardsList isLoading  ", isLoading, " refreshing ", refreshing);

  return (
    <StyledCardsContainer>
      {!items?.length && !isLoading ? (
        <NoItemsFound />
      ) : (
        <FlatList
          contentContainerStyle={{
            maxWidth: DESKTOP_MAX_WIDTH,
            width: "100%",
            marginRight: "auto",
            marginLeft: "auto"
          }}
          ref={flatListRef}
          data={displayItems}
          renderItem={renderItem}
          onEndReached={onEndReached}
          onEndReachedThreshold={0.5}
          keyExtractor={(item) => {
            if (item) {
              if (item.id) {
                return item.id;
              } else {
                return item;
              }
            } else {
              return item;
            }
          }}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          // onMomentumScrollBegin={() => {
          //   onEndReachedCalledDuringMomentum.current = false;
          // }}
        />
      )}
      {isLoading && <Loader />}
    </StyledCardsContainer>
  );
}
