import React from "react";
import styled from "styled-components/native";
import { useTranslation } from "react-i18next";
import BaseText from "../../components/basic/base-text";
import Date from "../../components/basic/date";
import BaseRow from "../../components/basic/rows/base-row";
import {
  ALERT_STATUSES,
  SOLD,
  EXPIRED
} from "../../components/refund/refund-status";
import { marginRightLeft } from "../../components/styles/global-styles";

const StyledBaseText = styled(BaseText)`
  ${marginRightLeft(4)}
  ${(props) =>
    ALERT_STATUSES.includes(props.status) &&
    `color:${props.theme.palette.price.red}`};
`;

const StyledDate = styled(Date)`
  ${(props) =>
    ALERT_STATUSES.includes(props.status) &&
    `color:${props.theme.palette.price.red}`}
`;

export default function ExpirationDate({ status, expirationDate }) {
  const { t } = useTranslation();

  if (!expirationDate) {
    return null;
  }

  return (
    <BaseRow>
      <StyledBaseText status={status}>{t("Expiration date")}</StyledBaseText>
      <StyledDate status={status}>{expirationDate}</StyledDate>
    </BaseRow>
  );
}
